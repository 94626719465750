import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PostType } from "src/types/post";
import PostCreateComponent from "src/components/post/PostCreateComponent";
import api from "src/utils/api2";
import CommunityPostListPage from "./list";

interface CommunityPost extends PostType { }
type CommunityTypes = "free" | "humor" | "video" | "picture" | "sports";

async function CommunityApiErrorHandler(res: Response) {
  switch (res.status) {
    case 413: // Request Entity Too Large 에러는 첨부용량 초과일때 발생한다.
      alert("파일 최대 첨부 용량은 총 10MB 입니다.");
      break;
    case 403: // Forbidden 에러는 권한이 없을 때 발생한다.
      const err = await res.json();
      alert(err.detail);
      break;
    default:
      if (res.headers.get('Content-Type') == 'application/json') {
        const errorMessage = await res.json()
        alert(errorMessage)
      } else {
        alert("에러가 발생하였습니다.");
      }
  }
}

export default function CommunityPostCreatePage() {
  const { type }: { type?: CommunityTypes } = useParams();
  const navigate = useNavigate();

  let createAfterUrl = `/community/${type}`;
  let apiUrl = `/api/community/posts`;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  function createPost(newPost: {}) {
    api(`${apiUrl}/`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ ...newPost, type }),
    })
      .then((res) => res.json())
      .then((data: CommunityPost) => navigate(`${createAfterUrl}/${data.id}`))
      .catch((res: Response) => CommunityApiErrorHandler(res));
  }

  return (
    <>
      <PostCreateComponent createPost={createPost} />
      <CommunityPostListPage />
    </>
  );
}
