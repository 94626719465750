import React, {useEffect, useState} from 'react';

function PickBar({className, count}: {className: string; count: number}) {
    const [perCount, setAddCount] = useState(0)
    // useEffect(()=> {
    //     const endPoint = count
    //     setTimeout(()=> {
    //         if(perCount < endPoint){
    //             setAddCount(perCount + 1)
    //         }
    //     }, 5)
    // })
    return (
        <div className={className} style={{ width: `${ count + "%" }` }}></div>
    );
}

export default PickBar;