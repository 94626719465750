import React, {useEffect, useState} from 'react';
import SportsIcon from "../../../components/icon/sports_icon";
import Icon from "../../../components/icon/icon";
import SportsMore from "./sports_more";
import { useNavigate, useParams } from 'react-router-dom';
import moment from "moment";
import {sports_tab_type} from "../_layout";

interface SportsTabData {
    sports: string,
    name: string,
    count: number,
}

const tabData_dummy = [
    { sports: "soccer",     name: "축구",       count: 0 },
    { sports: "baseball",   name: "야구",       count: 0 },
    { sports: "basketball", name: "농구",       count: 0 },
    { sports: "volleyball", name: "배구",       count: 0 },
    { sports: "hockey",     name: "아이스 하키", count: 0 },
    { sports: "football",   name: "미식축구",    count: 0 },
]

function SportsTab({sports, setSports}: {sports: sports_tab_type; setSports: any}) {
    const [sportsInfoData, setData] = useState<SportsTabData[]>()
    const [selectTab , setSelectTab] = useState<string>("")
    const {sports_name} = useParams()

    function defaultTab() {
        if(sports_name){
            return sports_name
        }else{
            return "soccer"
        }
    }
    function defSelectTab(sports: string) {
        setSelectTab(sports)
        setSports(sports)
    }
    useEffect(()=>{
        tabData_dummy.forEach((obj)=>{
            const fetchUrl = `https://sports-api.named.com/v1.0/sports/${obj.sports}/games?date=${moment().format('YYYY-MM-DD')}&status=ALL`
            fetch(fetchUrl)
                .then(res => res.json())
                .then((data) => {
                    obj.count = data.length
                })
        })
        setData(tabData_dummy)
    }, [])
    useEffect(()=>{
        setSelectTab(defaultTab)
    }, [sports_name])

    return (
        <div id={"sports-tab"}>
            <ul>
                {
                    sportsInfoData?.map((items, key)=>(
                        <li key={key}
                            className={ `${ "--pointer " + (selectTab === items.sports ? "select" : "") }` }
                            onClick={()=>defSelectTab(items.sports)}>
                            <SportsIcon icon={items.sports} size={19} />
                            <strong>{items.name}</strong>
                            { items.count ? <span>({items.count})</span> : <></> }
                        </li>
                    ))
                }
            </ul>

            {/*<SportsMore action={moreSports}/>*/}
        </div>
    );
}

export default SportsTab;