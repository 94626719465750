import React, {useContext, useState} from 'react';
import {GameResult, LABELS, VIEW_TYPE} from "../interface";
import {IframeContext} from "./iframe_provider";
import {DhballsResultListItem, LadderResultListItem, PowerballResultListItem} from "./game_board_result";

function MobileResultBoard({state, list, viewType}: { state: string, list: GameResult[], viewType: VIEW_TYPE }) {
    const [openState, setOpenState] = useState<boolean>(false)
    const [openText, setOpenText] = useState<string>("추첨결과")
    const [modalState, setModalState] = useState<boolean>(false)
    const {mobileModalState, toggleModalState} = useContext(IframeContext)

    function toggleState(){
        setOpenState(!openState)
        setModalState(!modalState)
        if(openState) {
            toggleModalState(0)
            setOpenText('추첨결과')
        }else{
            toggleModalState(2)
            setOpenText('닫기')
        }
    }

    let rightStyle = {}
    if( mobileModalState == 1 ){
        rightStyle = { zIndex: 6 }
    }else{
        rightStyle = { zIndex: 8 }
    }
    return (
        <>
            <div className={"mobile-open result"} style={rightStyle} onClick={toggleState}>{openText}</div>
            <div className={"result-board right mobile"} aria-expanded={openState}>
                <div className="rotate-board">
                    <header className={"title"}>추첨결과</header>
                    <main className={"contents"}>
                        <div className={"scroll"}>
                            <ul>
                                {
                                    list.map((obj, index)=>{
                                        if(viewType == 'ladder') {
                                            return <LadderResultListItem obj={obj} index={index} state={state} key={index}/>
                                        }else if(viewType == 'dh'){
                                            return <DhballsResultListItem obj={obj} index={index} state={state} key={index}/>
                                        }else{
                                            return <PowerballResultListItem obj={obj} index={index} state={state} key={index}/>
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </main>
                </div>
            </div>
            {
                modalState ? <div className={"result-board-modal"}></div>
                           : ""
            }
        </>
    );
}

export default MobileResultBoard;