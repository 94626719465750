import React from 'react';
import {Outlet} from "react-router-dom";
import BoardTab, {tab_interface} from "../../components/board/board_tab";

const board_tab_list: tab_interface[] = [
    {eventKey:"1", text: "이용약관"},
    {eventKey:"2", text: "개인정보취급방침"},
    {eventKey:"3", text: "청소년보호정책"},
    {eventKey:"4", text: "이메일수집거부"},
]
function _Layout() {
    return (
        <>
            <BoardTab tab_list={board_tab_list} page={"policy"}/>
            <Outlet />
        </>
    );
}

export default _Layout;