import React, {useEffect, useState} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import PaginationComponent from "../../../../components/pagination";
import useUser from "../../../../hooks/useUser";
import {with_comma} from "../../../../utils/number_comma";
import Btn from "../../../../components/form/btn";
import ExpChangePoint from "../popup/exp-change-point";
import api from "../../../../utils/api";
import {isMobile} from "react-device-detect";
import moment from "moment";

import HistorySearchFilter from '../history/HistorySearchFilterComponent';
import { useLocation } from 'react-router-dom';
interface expHistory {
    amount: number
    explanation: string
    created_at: string
}
export default function ExpHistory(props: {}) {
    const [expHistories, setExpHistories] = useState<expHistory[]>()
    const [expChangeModal, setExpChangeModal] = useState<boolean>(false)
    const {user} = useUser()
    const location = useLocation()
    function toggleExpModal(){
        alert('준비중 입니다.')
        // setExpChangeModal(!expChangeModal)
    }
    useEffect(() => {
        api(`/api/user/exp-history/${window.location.search}`)
            .then(res => res.json())
            .then((data) => {
                setExpHistories(data.results)
            })
            .catch(err => alert(JSON.stringify(err)))
    }, [location])
    return (
        <div className={"board-table board-user exp-history"}>
            <header>
                <div className={"my-cash"}>
                    <strong>보유 경험치</strong>
                    <p>{user?.exp && with_comma(user.exp)}</p>
                    <Btn type={"dark"} onClick={toggleExpModal}>포인트 경험치 전환</Btn>
                </div>
                <HistorySearchFilter isMobile={isMobile}/>
            </header>

            {
                isMobile ? <TableTemplateMobile expHistories={expHistories}/>
                    : <TableTemplateDesktop expHistories={expHistories}/>
            }
            <PaginationComponent totalCount={10} />
            <ExpChangePoint show={expChangeModal} setShow={setExpChangeModal}/>
        </div>
    );
}
function TableTemplateMobile({expHistories}: {expHistories: expHistory[] | undefined}){
    return (
        <table className={"history-board-mo"}>
            <thead>
            <tr>
                <th>번호</th>
                <th>변경 경험치</th>
                <th>변경 사유</th>
            </tr>
            </thead>
            <tbody>
            {
                expHistories?.map((exp, index) => (
                    <tr key={index}>
                        <td>{expHistories.length - index}</td>
                        <td className={`change ${ exp.amount > 0 ? "add" : "sub" }`}>{with_comma(exp.amount)}</td>
                        <td>
                            <p>{exp.explanation}</p>
                            <p>{moment(exp.created_at).format("YY/MM/DD HH:mm:ss")}</p>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    )
}
function TableTemplateDesktop({expHistories}: {expHistories: expHistory[] | undefined}){
    return (
        <table className={"history-board"}>
            <thead>
            <tr>
                <th>번호</th>
                <th>변경 내역</th>
                <th>변경 경험치</th>
                <th>날짜</th>
            </tr>
            </thead>
            <tbody>
            {
                expHistories ? expHistories.map((exp, index) => (
                        <tr key={index}>
                            <td>{expHistories.length - index}</td>
                            <td>{exp.explanation}</td>
                            <td className={`change ${ exp.amount > 0 ? "add" : "sub" }`}>{with_comma(exp.amount)}</td>
                            <td>{moment(exp.created_at).format("YY/MM/DD HH:mm:ss")}</td>
                        </tr>
                    ))
                    : <></>
            }
            </tbody>
        </table>
    )
}

