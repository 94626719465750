import { RouteObject } from "react-router-dom";
import Layout from "./_layout"
import Home from "./list"
import PickRanking from "./pick_list";

const rankingRoute: RouteObject = {
    path: "ranking",
    element: <Layout />,
    children: [
        {
            path: ":type", children: [
                { path: "", element: <Home /> },
                { path: ":id", element: <Home /> }
            ],
        },
        { path: "gamepick", element: <PickRanking /> }
    ]
}

export default rankingRoute