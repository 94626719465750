import { useEffect, useRef, useState, createContext } from "react";
import { faFaceLaugh, faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import { useCustomAlert, useCustomConfirm } from "src/hooks/useDialog";
import useUser, { PrivateUser } from "src/hooks/useUser";
import { AttendantType, PickRoomApi, PickRoomType } from "src/types/room";
import api from "src/utils/api";
import getSocket from "src/utils/socket";
import Logo from "../../asset/images/common/peeks_logo.svg";
import Flex from "../../components/layout/flex";
import { userInterface } from "../../components/user/interface";
import UserLevelNamecard from "../../components/user/user_level_namecard";
import { default as PickRoomGamePicker } from "./pickRoom_gamePick";
import PopupTemplate from "./popup_template";
import { GamePickType } from "src/types/gamepick";

type SocketMessage = ChatMessage | ContentMessage | CloseMessage | EmptyMessage | RestoreMessage | PickMessage;

interface ContentMessage {
    type: "join" | "leave" | "info" | "alert";
    content: string;
}

interface EmptyMessage {
    type: "kicked";
}

interface CloseMessage {
    type: "close";
    reason: string;
}

interface ChatMessage {
    type: "chat";
    content: string;
    sender: userInterface;
}

interface RestoreMessage {
    type: "restore";
    messages: ChatMessage[];
}

interface PickMessage {
    type: "pick";
    pick: GamePickType
}


export const RoomSocketContext = createContext<WebSocket | undefined>(undefined)

export default function PickRoomPage() {
    const { user, isLoading: isUserLoading } = useUser();
    const { roomId } = useParams();
    const [roomSocket, setRoomSocket] = useState<WebSocket>();
    const [room, setRoom] = useState<PickRoomType>();
    const [messages, setMessages] = useState<SocketMessage[]>([]);
    const [picks, setPicks] = useState<GamePickType[]>([]);
    const [textInput, setTextInput] = useState("");
    // 현재 유저가 방장인가?
    const isHost = user && room && user.uuid === room.host.uuid;

    const chatAreaRef = useRef<HTMLDivElement>(null);

    // window.alert, confirm 의 커스텀 버전
    const { customAlert, AlertModal } = useCustomAlert();
    const { customConfirm, ConfirmModal } = useCustomConfirm();
    const isKeyboardOpen = useDetectKeyboardOpen();

    function appendMessage(message: ContentMessage) {
        setMessages((messages) => [...messages, message]);
    }
    // 소켓 초기화
    function initialSocket(roomId: string) {
        const chatSocket = getSocket(`/ws/room/${roomId}/`);

        // 소켓에 접속 시 참여자 정보를 가져온다.
        chatSocket.onopen = () => {
            mutateAttendants();
        };
        chatSocket.onmessage = function (e: any) {
            const data: SocketMessage = JSON.parse(e.data);
            switch (data.type) {
                case "chat":
                case "info":
                    setMessages((messages) => [...messages, data]);
                    break;
                case "join":
                case "leave":
                    mutateAttendants();
                    setMessages((messages) => [...messages, data]);
                    break;
                case "alert":
                    customAlert(data.content);
                    break;
                case "close":
                    customAlert(data.reason, () => window.close());
                    break;
                // 이전 메세지 복구
                case "restore":
                    setMessages((messages) => [...data.messages, ...messages]);
                    break;
                case "pick":
                    setPicks((picks) => [data.pick, ...picks])
                    break;
            }
        };

        chatSocket.onclose = function (e: any) {
            if (e.code === 1006) {
                customAlert("입장이 거부되었습니다.");
            } else {
                appendMessage({type: "info", content: "채팅이 종료되었습니다."});
                window.opener.location.reload()
            }
        };

        return chatSocket;
    }

    // 방 정보 가져온 후 소켓 연결
    useEffect(() => {
        if (isUserLoading) {
            return;
        }

        api(`/api/chat/pick-rooms/${roomId}/with_attendants/`)
            .then((res) => res.json())
            .then((data: PickRoomApi) => {
                setRoom(data.room);
                const socket = initialSocket(data.room.id);
                setRoomSocket(socket);
            })
            .catch((err) => {
                customAlert(err.message, () => window.close());
            });
        return () => roomSocket?.close();
    }, [roomId, user]);

    // 메세지 창 자동스크롤
    useEffect(() => {
        if (chatAreaRef.current) {
            const scrollHeight = chatAreaRef.current.scrollHeight;
            // console.log(scrollHeight)
            chatAreaRef.current.scrollTo(0, scrollHeight);
        }
    }, [messages]);

    // 참여자 정보 swr
    const { data: attendants, mutate: mutateAttendants } = useSWR<AttendantType[]>(
        `/api/chat/pick-rooms/${roomId}/with_attendants/`,
        (url) => {
            if (roomSocket?.readyState !== 1) {
                return [];
            }
            return api(url)
                .then((res) => res.json())
                .then((data: PickRoomApi) => data.attendants)
                .catch((err) => {
                    customAlert("참여자 정보를 가져오는데 실패했습니다.");
                    return [];
                });
        }
    );

    // 채팅 메세지 전송
    function sendChat() {
        if (textInput.length === 0) {
            return;
        }
        roomSocket?.send(JSON.stringify({ type: "chat", content: textInput }));
        setTextInput("");
    }

    // 유저 강퇴 (방장 권한)
    function kickAttendant(attendant: AttendantType) {
        if (!isHost) {
            customAlert("유저 강퇴 권한이 없습니다.");
        }
        customConfirm(`${attendant.user.nickname}님을 강퇴하시겠습니까?`, () =>
            roomSocket?.send(
                JSON.stringify({
                    type: "kick",
                    target_channel: attendant.channel,
                    target_uuid: attendant.user.uuid,
                })
            )
        );
    }

    // 채팅방 삭제 (방장 권한)
    function deleteRoom() {
        // 방장이 아닌 유저가 삭제 시도
        if (!isHost) {
            customAlert("채팅방 삭제 권한이 없습니다.");
        }
        customConfirm("채팅방을 삭제하시겠습니까?", () =>
            api(`/api/chat/pick-rooms/${roomId}/`, { method: "DELETE" })
                .then((res) => customAlert("채팅방이 종료되었습니다.", () => window.close()))
                .catch((err) => customAlert("채팅방 삭제 중 오류가 발생하였습니다."))
        );
    }

    if (!room) {
        return <AlertModal />;
    }

    return (
        <RoomSocketContext.Provider value={roomSocket}>
            <PopupTemplate title={room.name} popupType={"room"}>
                <header>
                    <a className="logo">
                        <img src={Logo} alt="" />
                    </a>

                    <div className={"host"}>
                        <UserLevelNamecard user={user as PrivateUser} />
                        {isHost ? (
                            <div onClick={() => deleteRoom()}>
                                <i className="--icon close"></i>
                                <p>채팅방 종료</p>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </header>
                <main>
                    <PickRoomGamePicker room={room} isHost={isHost} host={room.host} picks={picks} />
                    <div className="room-chat">
                        <header className="layout-header">
                            <p>단톡방</p>
                        </header>
                        <div className={"chat-room-body"}>
                            <div className={"chat-area"} ref={chatAreaRef}>
                                <ul>
                                    {messages.map((msg, idx) => {
                                        switch (msg.type) {
                                            case "info":
                                            case "join":
                                            case "leave":
                                                return (
                                                    <li className="info" key={idx}>
                                                        {msg.content}
                                                    </li>
                                                );
                                            case "chat":
                                                return (
                                                    <li className="chat" key={idx}>
                                                        <span>
                                                            <i className={`--rank level-${msg.sender.level}`}></i>
                                                            {msg.sender.nickname}
                                                        </span>
                                                        <span>{msg.content}</span>
                                                    </li>
                                                );
                                        }
                                    })}
                                </ul>
                            </div>
                            <footer className={isKeyboardOpen ? "--mb-20" : ""}>
                                <p>{user?.nickname}</p>
                                <input
                                    type="text"
                                    placeholder={"메시지 입력하기..."}
                                    max={200}
                                    value={textInput}
                                    onInput={(e) => {
                                        e.currentTarget.value.length <= 200 && setTextInput(e.currentTarget.value);
                                    }}
                                    // FIXME 크롬 오류로 한글 입력시 키 다운 이벤트가 두번 발생해서 어쩔 수 없이 deprecated 함수를 이용...
                                    onKeyPress={(e) => e.key === "Enter" && sendChat()}
                                />
                                <Flex justifyContent={"between"}>
                                    <button>
                                        <FontAwesomeIcon icon={faFaceLaugh} />
                                    </button>
                                    <div>
                                        <span className={"text-count"}>{textInput.length}/200</span>
                                        <button onClick={() => sendChat()}>
                                            <FontAwesomeIcon icon={faPaperPlane} />
                                        </button>
                                    </div>
                                </Flex>
                            </footer>
                        </div>
                    </div>
                    <div className="room-user">
                        <header className="layout-header">
                            <p>접속자 리스트</p>
                            <span>접속자: {attendants ? attendants.length : 0}</span>
                        </header>
                        <div className={"chat-room-attendant"}>
                            <ul>
                                {attendants?.map((attendant) => {
                                    const date = new Date(attendant.joined_dt);
                                    const format = moment(date).format("YYYY-MM-DD HH:mm");
                                    return (
                                        <li key={attendant.user.uuid}>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="left-start"
                                                rootClose={true}
                                                overlay={
                                                    <Popover className={"chat-overlay room"}>
                                                        <main>
                                                            <header>
                                                                <div className={"userinfo"}>
                                                                    <i className="--rank level-12"></i>
                                                                    <p>{attendant.user.nickname}</p>
                                                                </div>
                                                            </header>
                                                            <ul>
                                                                {/* <li><i className={"--icon userinfo"}></i>유저정보</li>
                                                                    <li><i className={"--icon message"}></i>쪽지</li>
                                                                    <li><i className={"--icon onechat"}></i>1대1 대화 신청</li>
                                                                    <li><i className={"--icon friend"}></i>친구등록</li>
                                                                    <li><i className={"--icon star"}></i>별선물</li> */}
                                                                {/* 호스트? && 자기자신? */}
                                                                {isHost && attendant.user.uuid !== user?.uuid && (
                                                                    <li
                                                                        onClick={(e) => {
                                                                            kickAttendant(attendant);
                                                                        }}
                                                                    >
                                                                        <i className={"--icon star"}></i>강퇴
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </main>
                                                    </Popover>
                                                }
                                            >
                                                <div>
                                                    <UserLevelNamecard user={attendant.user} />
                                                    <p>{format}</p>
                                                </div>
                                            </OverlayTrigger>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </main>
                <ConfirmModal />
                <AlertModal />
            </PopupTemplate>
        </RoomSocketContext.Provider>
    );
}
