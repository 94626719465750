import React, {useEffect, useRef, useState} from "react";
import SqsEffect from "../../Effect";
import useInterval from "../../../../../hooks/useInterval";
import AudioPlayer from "../audio_player";
import stop from "../../../asset/sound/Stop.mp3";
import {
    board_state
    , ball_obj
    , gap
    , delay
    , last_delay} from "../game_board"

type shuffle_ball_type = "stay" | "normal" | "power"
export default function Balls({board_state, ballNumber, kind}: {board_state: board_state , ballNumber: ball_obj; kind: string}){
    const [ball_down_state, setBall_down_state] = useState<boolean>(false)
    const [ball_type, setBall_type] = useState<shuffle_ball_type>("stay")

    useEffect(()=>{
        if( board_state == "stay" ) {
            setBall_type('stay')
        } else if( board_state == "ready" ){
            setBall_type('normal')
        } else if( board_state == 'spin' ){
            setBall_down_state(true)
            setTimeout(()=>{
                setBall_type('power')
            }, ((gap * 6) + (delay * 6)) + 500)
        } else if ( board_state == 'end' ){
            setBall_down_state(false)
        }
    }, [board_state])
    return (
        <>
            <div className={`board-logo ${kind}`}></div>
            <div className={"happyball-ball-board-position"}>
                <div className={"tong back"}></div>
                {
                    ball_type == "stay"
                        ? <ShuffleStay/>
                        : <ShuffleDraw ball_type={ball_type}/>
                }
                <BallDown ball_down_state={ball_down_state} ballNumber={ballNumber}/>
                <div className={"tong ball"}></div>
                <div className={"tong front"}></div>
            </div>
        </>
    )
}
function ShuffleStay(){
    const shuffle_effect_name_stay = "shuffle_stay"
    const shuffle_effect_stay = new SqsEffect(shuffle_effect_name_stay)

    useInterval(()=>{
        const stay_st = {
            imageSize : [3400, 2100],
            frame     : 60,
            vector    : [10, 6],
            speed     : 1800,
            element   : ".tong.shuffle.stay",
            loop      : false,
        }
        shuffle_effect_stay.set(stay_st)
    }, 2500)
    return (
        <div className={`tong shuffle stay`}></div>
    )
}
function ShuffleDraw({ball_type}: {ball_type: shuffle_ball_type}){
    const shuffle_effect_name_draw = "shuffle_draw"
    const shuffle_effect_draw = new SqsEffect(shuffle_effect_name_draw)

    useEffect(()=> {
        if(ball_type == 'normal'){
            const draw_st = {
                imageSize : [1700, 1400],
                frame     : 20,
                vector    : [5, 4],
                speed     : 750,
                element   : ".tong.shuffle.normal",
                loop      : true,
            }
            shuffle_effect_draw.set(draw_st)
        }else{
            return
        }
    }, [])
    return (
        <div className={`tong shuffle ${ball_type}`}></div>
    )
}
function BallDown({ball_down_state, ballNumber}: {ball_down_state: boolean; ballNumber: ball_obj}){
    const [ball_type_number, set_ball_type_number] = useState<string[]>([])
    const [ball_down, setBall_down] = useState<string>(ball_type_number[0])
    const [ball_index, setIndex] = useState<number>(0)

    useEffect(()=>{
        if(!ball_down_state) {
            setBall_down("")
            return
        }
        const new_list = []
        for(let i=0; i<6; i++){
            let ball_type_number = `normal-${ballNumber.normal[i]}`
            if( i > 4 ){
                ball_type_number = `power-${ballNumber.powerball}`
            }
            new_list.push(ball_type_number)
        }
        set_ball_type_number(new_list)
    }, [ball_down_state])
    useEffect(()=> {
        // console.log(ball_type_number)
        if(!ball_type_number){
            return
        }

        let count = 1
        let down_delay = 0
        ball_type_number.forEach((e, index)=> {
            down_delay = (gap * (index + 1)) + (delay * (index + 1))
            // console.log(down_delay)
            setTimeout(()=>{
                setBall_down(e)
                setIndex(count)
                count++;
            }, down_delay + (index > 4 ? last_delay : 0))
        })
    }, [ball_type_number])
    return (
        <>
            <BallDownEffect ball_type_number={ball_down} ball_index={ball_index}/>
            <BallDownResult ball_type_number={ball_down}/>
        </>
    )
}
function BallDownResult({ball_type_number}: {ball_type_number: string}){
    const [resultBall, setResultBall] = useState<string[]>([])
    useEffect(()=>{
        if(resultBall.length > 6){
            setResultBall([])
        }
    }, [resultBall])
    useEffect(()=>{
        if(!ball_type_number){
            return
        }
        resultBall.push(ball_type_number)
        setResultBall(resultBall)

        if( ball_type_number.indexOf('power') > -1 ){
            setTimeout(()=>{
                setResultBall([])
            }, 3200)
        }
    }, [ball_type_number])
    return (
        <div className={"ball-result"}>
            {
                resultBall.map((cl, index)=>(
                    <div key={index}>
                        <AudioPlayer src={stop} play={true}/>
                        <figure className={`result-ball ${cl}`}/>
                    </div>
                ))
            }
        </div>
    )
}
function BallDownEffect({ball_type_number, ball_index}: {ball_type_number: string, ball_index: number}){
    const BallDownRef = useRef<HTMLDivElement>(null)

    useEffect(()=>{
        if(!BallDownRef.current){
            return
        }
        // console.log(ball_index)
        const ballDown = BallDownRef.current
        ballDown.style.display = "block"
        ballDown.className = `ball-down ${ball_type_number}`
        const ballDown_effect_name = ballDown.className
        const ballDown_effect = new SqsEffect(ballDown_effect_name)
        const st = {
            imageSize : [1200, 1830],
            frame     : 24,
            vector    : [4, 6],
            speed     : 900,
            element   : ".ball-down",
            loop      : false,
        }
        ballDown_effect.set(st, ()=>{
            setTimeout(()=>{
                if(ball_index == 5){
                    ballDown.style.display = "none"
                }
                ballDown_effect.reset(".ball-down")
            }, 800)
        })
    }, [ball_type_number])
    return (
        <div className={`ball-down ${ball_type_number}`} ref={BallDownRef}></div>
    )
}
