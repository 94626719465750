import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";

function Checkbox({name, defaultChecked, required, invalidMessage, onTrue, onFalse}:
                      {name: string; defaultChecked: boolean; required: boolean; invalidMessage: string; onTrue: ()=> void; onFalse: ()=> void; }) {
    const [check, setCheck] = useState<boolean>(defaultChecked)
    useEffect(()=> {
        if(check){
            onTrue();
        }else{
            onFalse();
        }
    }, [check])
    return (
        <div className={"form-checkbox"}>
            <input type="checkbox"
                   name={name}
                   defaultChecked={check}
                   required={required}
                   onInvalid={e=>alert(invalidMessage)}
                   onChange={()=>setCheck(!check)}/>
            {
                check ? <FontAwesomeIcon className={"--inherit"} icon={faCheckSquare} />
                      : <FontAwesomeIcon className={"--inherit"} icon={faSquare} />
            }
        </div>
    );
}

Checkbox.defaultProps = {
    defaultChecked: false,
    required: false,
    invalidMessage: "",
    onTrue: ()=> {},
    onFalse: ()=> {}
}

export default Checkbox;