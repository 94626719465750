import React, {useContext} from 'react';
import LayoutHeader from "../../components/layout/layout_header";
import MinigameTab from './tab';
import {MinigameContext} from "../../contexts/minigameProvider";
import Minigame from "./home";

export default function MinigameLayout({ children }: { children: React.ReactNode }) {
    const { gameType } = useContext(MinigameContext)
    return (
        <>
            <LayoutHeader tab={"minigame"} />
            <MinigameTab />
            <div className={"minigame-layout"}>
                <Minigame gameFetch={gameType}/>
            </div>
        </>
    );
}
MinigameLayout.defaultProps = {
    children: undefined
}
