import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { chatRoomKind } from "src/types/chat";
const chatTab = [
    { type: "free", tab: "자유", count: 0 },
    { type: "game", tab: "게임", count: 0 },
    { type: "pick", tab: "픽톡", count: 0 },
];

export default function ChatTab({ chatRoom, setChatRoom }: { chatRoom: chatRoomKind, setChatRoom: (chatRoom: chatRoomKind) => void }) {
    function handleChangeRoom(newChatRoom: chatRoomKind) {
        setChatRoom(newChatRoom)
    }

    return (
        <ul className={"chat-tab"}>
            <li className={chatRoom === 'FREE' ? "select" : ""}
                onClick={() => handleChangeRoom("FREE")}>
                자유<FontAwesomeIcon icon={faUser} /> 0
            </li>
            <li className={chatRoom === 'GAME' ? "select" : ""}
                onClick={() => handleChangeRoom("GAME")}>
                게임<FontAwesomeIcon icon={faUser} /> 0
            </li>
            <li onClick={() => alert('준비중입니다.')}>
                픽톡<FontAwesomeIcon icon={faUser} /> 0
            </li>
        </ul>
    )
}