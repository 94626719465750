import getCookie from "./getCookie"

export default async function api(input: RequestInfo | URL, init?: RequestInit) {
    const method = init?.method || 'GET'

    // CSRF 토큰이 필요한 메소드
    if (["POST", "PATCH", "PUT", "DELETE"].includes(method)) {
        let csrftoken = getCookie('csrftoken');
        if (!csrftoken) {
            await fetch('/api/csrf-token');
            csrftoken = getCookie('csrftoken');
        }
        return fetch(input, init && { ...init, headers: { ...init.headers, 'X-CSRFToken': csrftoken! } })
            .then(async res => {
                if (res.ok) {
                    return res
                } else {
                    throw res
                }
            })
    } 
    // CSRF 토큰이 필요없는 메소드
    else {
        return fetch(input, init && { ...init, headers: { ...init.headers } })
            .then(async res => {
                if (res.ok) {
                    return res
                } else {
                    throw res
                }
            })
    }
}