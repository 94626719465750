import React, { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import api from "src/utils/api"
import {UserCash} from "./inventory";
import PaginationComponent from "../../../../components/pagination";
import {InventorySearch} from "./item_history";
import useUser from "../../../../hooks/useUser";
import {with_comma} from "../../../../utils/number_comma";
import Btn from "../../../../components/form/btn";
import Flex from "../../../../components/layout/flex";

interface CashHistoryApi {
    count: number
    results: CashHistory[]
}

interface CashHistory {
    amount: number,
    explanation: string,
}

export default function StarReceivedPage() {
    const [cashHistories, setCashHistories] = useState<CashHistory[]>()

    useEffect(() => {
        alert('준비중 입니다.')
        window.history.back()
        // api('/api/user/cash-history/')
        //     .then(res => res.json())
        //     .then((data: CashHistoryApi) => {
        //         setCashHistories(data.results)
        //     })
        //     .catch(err => alert(JSON.stringify(err)))
        // console.log(cashHistories)
    }, [])

    return (
        <div className={"board-table board-user cash"}>
            <header>
                <MyStar/>
                <InventorySearch/>
            </header>

            <table>
                <thead>
                <tr>
                    <th>번호</th>
                    <th>상태</th>
                    <th>사용내역</th>
                    <th>변경 별</th>
                    <th>날짜</th>
                </tr>
                </thead>
                <tbody>
                {
                    cashHistories?.map((cash, index) => (
                        <tr key={index}>
                            <td>{index}</td>
                            <td>{cash.amount}</td>
                            <td>선물 받음 - 관리자</td>
                            <td>{cash.explanation}</td>
                            <td>23/01/27 15:24:34</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <PaginationComponent totalCount={10} />
        </div>
    )
}

export function MyStar(){
    const {user} = useUser()
    return (
        <div className={"my-cash --gap-50"}>
            <Flex>
                <strong>보유 별</strong>
                <p className={"--color-theme2"}>6,540</p>
            </Flex>
            <Flex>
                <strong>누적 구매 별</strong>
                <p className={"--color-theme2"}>1,820</p>
            </Flex>
            <Flex>
                <strong>선물한 별</strong>
                <p className={"--color-theme2"}>400</p>
            </Flex>
            <Flex className={"--ml-auto"} gap={10}>
                <Btn type={"dark"}>환전신청</Btn>
                <Btn type={"dark"}>환전신청내역</Btn>
            </Flex>
        </div>
    )
}
