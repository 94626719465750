import { Outlet, Link, useLocation } from "react-router-dom"
import BoardHead from "../../components/board/board_head";
import BoardTab from "../../components/board/board_tab";
import React from "react";
import LayoutHeader from "../../components/layout/layout_header";

export const board_tab_list = [
    {eventKey:"notices", text: "공지사항"},
    {eventKey:"tickets", text: "1:1 문의하기"},
    // {eventKey:"reports", text: "버그 & 건의", accessError: true},
]
export default function ServiceCenterLayout() {
  const location = useLocation();
  const match = location.pathname.match(/\/service_center\/(.*)\//)
  let key = "home"
  if (match) {
    key = match[1]
  } 
  return (
      <>
        <LayoutHeader tab={"customer_support"}/>
        <BoardHead/>
        <BoardTab tab_list={board_tab_list} page={"service_center"}/>
        <Outlet />
      </>
  )
}