import { useState, createContext, useRef } from "react";
import { ToastContainer, Toast, Button } from "react-bootstrap";

interface ToastType {
  title: string;
  content: string;
  notifiedAt: Date;
  room: string;
}

interface ToastContextType {
  toastify: (newToast: ToastType) => void;
}

const ToastContext = createContext<ToastContextType>({
  toastify: (toast) => () => {},
});

function ToastProvider({ children }: { children: any }) {
  const [toasts, setToasts] = useState<ToastType[]>([]);
  const popupRef = useRef<Window>();
  function toastify(newToast: ToastType) {
    setToasts((toasts) => [...toasts, newToast]);
  }

  return (
    <ToastContext.Provider value={{ toastify }}>
      {children}
      <ToastContainer position="bottom-end">
        {toasts.map((toast, index) => (
          <Toast key={index} delay={10000} autohide onClose={() => setToasts((toasts) => toasts.filter((item) => item.notifiedAt !== toast.notifiedAt))}>
            <Toast.Header>
              <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
              <strong className="me-auto">{toast.title}</strong>
              <small className="text-muted">just now</small>
            </Toast.Header>
            <Toast.Body>
              {toast.content}
              <Button
                onClick={(e) => {
                  if(popupRef.current && !popupRef.current.closed){
                    popupRef.current.focus()
                  }else{
                    popupRef.current = window.open(`/chat-room/${toast.room}/`, `/chat-room/${toast.room}/`, "width=350,height=645") || undefined;
                  }
                }}
              >
                방 바로가기
              </Button>
            </Toast.Body>
          </Toast>
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
}

export { ToastContext, ToastProvider };
