import React from 'react';
import {Outlet} from "react-router-dom";
import CategoryTab from "../../../../components/category_tab";
import {tab_interface} from "../../../../components/board/board_tab";

const board_tab_list: tab_interface[] = [
    {eventKey:"inventory",     text: "아이템"},
    {eventKey:"item-history",  text: "아이템 사용내역"},
    {eventKey:"cash-history",  text: "캐시"},
    {eventKey:"point-history", text: "포인트"},
    {eventKey:"star-history",  text: "별"},
    {eventKey:"star-received", text: "받은 별"},
]
function HistoryPage({ children }: { children?: React.ReactNode }) {
    return (
        <>
            <CategoryTab tab_list={board_tab_list} />
            {children ? children : <Outlet />}
        </>
    );
}

export default HistoryPage;