import { RouteObject } from "react-router-dom"
import CommunityLayout from "./_layout"
import CommunityPostListPage from "./list"
import CommunityPostDetailPage from "./detail"
import CommunityPostCreatePage from "./create"

const communityRoute: RouteObject = {
    path: "community",
    element: <CommunityLayout />,
    children: [
        { path: "", element: <CommunityPostListPage /> },
        {
            path: ":type", children: [
                { path: "", element: <CommunityPostListPage /> },
                { path: ":id", element: <CommunityPostDetailPage /> },
                { path: "new", element: <CommunityPostCreatePage /> },
            ]
        }
    ]
}

export default communityRoute