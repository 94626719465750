import { useState } from 'react';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import { Button, Form, Spinner } from "react-bootstrap";

import useUser from 'src/hooks/useUser';
import api from 'src/utils/api';

import logo from 'src/asset/images/common/peeks_logo.svg'
import Checkbox from "../../../components/form/checkbox";
import JoinTemplate from "./join_template";
import {isMobile} from "react-device-detect";


interface LoginFormError {
    non_field_errors?: string[]
    username?: string[]
    password?: string[]
}

interface ForgetFormError {
    username?: string[]
}

interface LoginSerializer {
    username: string
    nickname: string
    isLoggedIn: boolean
}

export default function LoginPage({registerOn=undefined}: {registerOn: any}) {
    const { mutateUser } = useUser({ loginRequired: false });
    const [forget, setForget] = useState<boolean>(false);

    return (
        <>
            <LoginTemplate mutateUser={mutateUser} registerOn={registerOn}/>
            {/*<Button onClick={e => { setForget(flag => !flag) }}>{forget ? "로그인" : "비밀번호 찾기"}</Button>*/}
        </>
    );
}
LoginPage.defaultProps = {
    registerOn: undefined
}

function LoginTemplate({ mutateUser, registerOn=undefined }: { mutateUser: any; registerOn: any }){
    const [formErrors, setFormErrors] = useState<LoginFormError>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const nextUrl = searchParams.get('next') || '/';

    function rememberLogin(checked: boolean){
        if(checked){

        }else{

        }
    }

    return (
        <JoinTemplate>
            <form onSubmit={async e => {
                e.preventDefault();
                setSubmitting(true);
                const formData = new FormData(e.target as HTMLFormElement);
                api('/api/user/login', { method: "POST", body: formData })
                    .then(res => res.json())
                    .then((data: LoginSerializer) => {
                        setSubmitting(false);
                        mutateUser(data);
                        navigate(nextUrl);
                        if(isMobile){
                            window.location.reload()
                        }
                    })
                    .catch((err: LoginFormError) => {
                        setSubmitting(false);
                        setFormErrors(err);
                    })
            }}>
                <div className={"form-row --mb-15"}>
                    <strong>이메일</strong>
                    <input type="text" name={"username"} placeholder={"mopick@gmail.com"}/>
                    {formErrors?.username && <Form.Text style={{ color: 'red', display: 'block' }}>{formErrors.username}</Form.Text> || <Form.Text></Form.Text>}
                </div>
                <div className={"form-row --mb-10"}>
                    <strong>비밀번호</strong>
                    <input type="password" name={"password"} placeholder={"비밀번호입력"}/>
                    {formErrors?.non_field_errors && <Form.Text style={{ color: 'red', display: 'block' }}>{formErrors.non_field_errors}</Form.Text>}
                    {formErrors?.password && <Form.Text style={{ color: 'red', display: 'block' }}>{formErrors.password}</Form.Text> || <Form.Text></Form.Text>}
                </div>
                <div className={"form-row"}>
                    <strong></strong>
                    <label className={"remember"}>
                        <Checkbox name={"remember"}
                                  onTrue={()=>rememberLogin(true)}
                                  onFalse={()=>rememberLogin(false)} />
                        <span>로그인 상태 유지</span>
                    </label>
                </div>

                <button className={"btn btn-theme2 " + ( submitting ? "--disabled" : "" )} type={"submit"}>로그인</button>
            </form>

            <footer>
                <Link to={"#"} onClick={e=> alert("준비중 입니다.")}>비밀번호재설정</Link>
                <Link to={"#"} onClick={e=> alert("준비중 입니다.")}>이메일주소찾기</Link>
                {
                    isMobile ? <a onClick={registerOn}>회원가입</a>
                             : <Link to={"/user/register"}>회원가입</Link>
                }
            </footer>
        </JoinTemplate>
    )
}

function ForgetForm() {
    const [formErrors, setFormErrors] = useState<ForgetFormError>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [succeeded, setSucceded] = useState<boolean>(false);

    return (<>
        {
            succeeded
                ? <div>이메일을 확인해주세요</div>
                : <Form onSubmit={async e => {
                    e.preventDefault();
                    setSubmitting(true);

                    const formData = new FormData(e.target as HTMLFormElement);
                    const response = await api('/api/user/reset-password', {
                        method: 'POST',
                        body: formData,
                    })
                    setSubmitting(false);
                    if (response.ok) {
                        setFormErrors({});
                        setSucceded(true);
                    } else {
                        const errorData: ForgetFormError = await response.json()
                        setFormErrors(errorData);
                    }
                }}>
                    <Form.Group className="mb-3">
                        <Form.Label>이메일</Form.Label>
                        <Form.Control name="username" type="text" placeholder="이메일" />
                        {formErrors?.username && <Form.Text style={{ color: 'red', display: 'block' }}>{formErrors.username}</Form.Text>}
                    </Form.Group>
                    <Button variant="primary" type="submit" disabled={submitting}>
                        {submitting
                            ? <Spinner as="span" animation="border" size="sm" role="status" />
                            : "제출"
                        }
                    </Button>
                </Form>
        }
    </>)
}