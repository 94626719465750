import React, {useState} from "react";

import useUser from "src/hooks/useUser";
import UserNamecard from "../../../components/user/user_namecard";
import Flex from "../../../components/layout/flex";
import Switch from "../../../components/form/switch";
import Btn from "../../../components/form/btn";
import ChangePasswordModal from "./popup/change-password";
import ChangeNicknameModal from "./popup/change-nickname";
import ChangeIntroModal from "./popup/change-intro";
import ChangeProfileImageModal from "./popup/change-profile-image";
import ChangeBannerModal from "./popup/change-banner";
import {with_comma} from "../../../utils/number_comma";
import {Link} from "react-router-dom";
import UserLevelNamecard from "../../../components/user/user_level_namecard";
import {isMobile} from "react-device-detect";
import WithdrawUserModal from "./popup/withdraw-user";

function Row(props: { children: React.ReactNode }) {
    return <div className={"p-row"}>{props.children}</div>;
}

export default function ProfilePage() {
    const {user} = useUser({loginRequired: true});
    const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
    const [showChangeNicknameModal, setShowChangeNicknameModal] = useState<boolean>(false);
    const [showChangeIntroModal, setShowChangeIntroModal] = useState<boolean>(false);
    const [showChangeProfileImageModal, setShowChangeProfileImageModal] = useState<boolean>(false);
    const [showChangeBannerModal, setShowChangeBannerModal] = useState<boolean>(false);
    const [showWithdrawUserModal, setShowWithdrawUserModal] = useState<boolean>(false);
    // console.log(user? user : "")

    // FIXME 유저 데이터가 아직 로딩이 안된 경우
    if (!user) {
        return <></>;
    }

    function FlexSwitch(props: { text: string }) {
        return (
            <Flex gap={8}>
                <b>{props.text}</b>
                <Switch/>
            </Flex>
        );
    }

    return (
        <div className={"board-profile"}>
            <div className={"box profile-1"}>
                <Row>
                    <div className={"subject"}>이메일</div>
                    <div className={"content"}>{user?.username}</div>
                </Row>
                <Row>
                    <div className={"subject"}>비밀번호</div>
                    <div className={"content"}>
                        <b>최근 변경일 - 2022-12-24 11:24:23</b>
                    </div>
                    <div className={"btn-area"}>
                        <Btn onClick={() => setShowChangePasswordModal(true)}>변경</Btn>
                    </div>
                </Row>
            </div>
            <div className={"box profile-1"}>
                <Row>
                    <div className={"subject"}>닉네임</div>
                    <div className={"content"}>
                        <UserLevelNamecard user={user}/>
                    </div>
                    <div className={"btn-area"}>
                        <span>보유아이템 ({user.inventory.nickname_change}개)</span>
                        <Btn onClick={(e) => setShowChangeNicknameModal(true)}>변경</Btn>
                    </div>
                </Row>
                <Row>
                    <div className={"subject"}>오늘의 한마디</div>
                    <div className={"content"}>
                        <b>{(user?.intro && user.intro) || "등록된 오늘의 한 마디가 없습니다."}</b>
                    </div>
                    <div className={"btn-area"}>
                        <span>보유아이템 ({user.inventory.intro_change}개)</span>
                        <Btn onClick={(e) => setShowChangeIntroModal(true)}>변경</Btn>
                    </div>
                </Row>
                <Row>
                    <div className={"subject"}>프로필 이미지</div>
                    <div className={"content edit-image profile"}>
                        <div>
                            <div className={"profile-box"}>{user?.profile_image ?
                                <img src={user.profile_image} alt=""/> : <></>}</div>
                            {!user?.profile_image ? <p>등록된 프로필 이미지가 없습니다.</p> : <></>}
                        </div>
                        <div>
                            <p>
                                사이즈는 150x150이상, 최대용량 1MB <br/>
                                선정적, 사회적으로 이슈가 되는 이미지는 등록 금지.
                            </p>
                        </div>
                    </div>
                    <div className={"btn-area"}>
                        <span>보유아이템 (0개)</span>
                        <Btn onClick={(e) => setShowChangeProfileImageModal(true)}>변경</Btn>
                    </div>
                </Row>
                <Row>
                    <div className={"subject"}>유저홈 베너</div>
                    <div className={"content edit-image banner"}>
                        <div>
                            <div className={"banner-box"}>{user?.banner_image ?
                                <img src={user.banner_image} alt=""/> : <></>}</div>
                            {!user?.banner_image ? <p>등록된 베너 이미지가 없습니다.</p> : <></>}
                        </div>
                        <div>
                            <p>
                                사이즈는 830x230, 최대용량 1MB <br/>
                                선정적, 사회적으로 이슈가 되는 이미지는 등록 금지.
                            </p>
                        </div>
                    </div>
                    <div className={"btn-area"}>
                        <span>보유아이템 (0개)</span>
                        <Btn onClick={() => setShowChangeBannerModal(true)}>변경</Btn>
                    </div>
                </Row>
                {isMobile ? (
                    <></>
                ) : (
                    <Row>
                        <div className={"subject"}>친구</div>
                        <div className={"content"}>
                            <b>0/20</b>
                        </div>
                        <div className={"btn-area"}>
                            <Btn>
                                <Link to={"/user/friends/"}>자세히</Link>
                            </Btn>
                        </div>
                    </Row>
                )}
            </div>
            <div className={"box profile-2"}>
                {isMobile ? (
                    <Row>
                        <div className={"subject"}>친구</div>
                        <div className={"content"}>
                            <b>0/20</b>
                        </div>
                        <div className={"btn-area"}>
                            <Btn>
                                <Link to={"/user/friends/"}>자세히</Link>
                            </Btn>
                        </div>
                    </Row>
                ) : (
                    <></>
                )}
                <Row>
                    <div className={"subject"}>아이템</div>
                    <div className={"content"}>
                        <b>{with_comma(user.inventory.count)}개</b>
                    </div>
                    <div className={"btn-area"}>
                        <Btn>
                            <Link to={"/user/history/inventory/"}>자세히</Link>
                        </Btn>
                    </div>
                </Row>
                <Row>
                    <div className={"subject"}>캐시</div>
                    <div className={"content"}>
                        <b>{with_comma(user?.cash)}</b>
                    </div>
                    <div className={"btn-area"}>
                        <Btn>
                            <Link to={"/user/history/cash-history/"}>자세히</Link>
                        </Btn>
                    </div>
                </Row>
                <Row>
                    <div className={"subject"}>포인트</div>
                    <div className={"content"}>
                        <b>{with_comma(user?.point)}</b>
                    </div>
                    <div className={"btn-area"}>
                        <Btn>
                            <Link to={"/user/history/point-history/"}>자세히</Link>
                        </Btn>
                    </div>
                </Row>
                <Row>
                    <div className={"subject"}>경험치</div>
                    <div className={"content"}>
                        <b>{with_comma(user?.exp)}</b>
                    </div>
                    <div className={"btn-area"}>
                        <Btn>
                            <Link to={"/user/exp/history/"}>자세히</Link>
                        </Btn>
                    </div>
                </Row>
                <Row>
                    <div className={"subject"}>레벨</div>
                    <div className={"content"}>
                        <b>{user?.rank}</b>
                    </div>
                    <div className={"btn-area"}>
                        <Btn>
                            <Link to={"/user/exp/"}>자세히</Link>
                        </Btn>
                    </div>
                </Row>
                <Row>
                    <div className={"subject"}>별</div>
                    <div className={"content"}>
                        <b>0</b>
                    </div>
                    <div className={"btn-area"}>
                        <Btn>
                            <Link to={"/user/history/star-history/"}>자세히</Link>
                        </Btn>
                    </div>
                </Row>
                <Row>
                    <div className={"subject"}>받은 별</div>
                    <div className={"content"}>
                        <b>0</b>
                    </div>
                    <div className={"btn-area"}>
                        <Btn>
                            <Link to={"/user/history/star-received/"}>자세히</Link>
                        </Btn>
                    </div>
                </Row>
            </div>
            <div className={"box profile-3"}>
                <Row>
                    <div className={"subject"}>푸시알림설정</div>
                    <div className={"content push-alarm"}>
                        <FlexSwitch text={"친구접속알림"}/>
                        <FlexSwitch text={"쪽지알림"}/>
                        <FlexSwitch text={"댓글알림"}/>
                    </div>
                    <div className={"btn-area"}>
                        <Btn>설정저장</Btn>
                    </div>
                </Row>
            </div>
            <div className={"box profile-3"}>
                <Row>
                    <div className={"subject"}>
                        <b>회원탈퇴</b>
                    </div>
                    <div className={"content"}>
                        <b className={"--color-danger"}>회원 탈퇴 후 30일동안 이메일 또는 전화번호로 재가입이 불가능합니다.</b>
                    </div>
                    <div className={"btn-area withdraw"}>
                        <Btn type={isMobile ? "dark" : "danger"} onClick={e=>setShowWithdrawUserModal(true)}>탈퇴</Btn>
                    </div>
                </Row>
            </div>

            <WithdrawUserModal show={showWithdrawUserModal} setShow={setShowWithdrawUserModal}/>
            <ChangePasswordModal show={showChangePasswordModal} setShow={setShowChangePasswordModal}/>
            <ChangeNicknameModal show={showChangeNicknameModal} setShow={setShowChangeNicknameModal}/>
            <ChangeIntroModal show={showChangeIntroModal} setShow={setShowChangeIntroModal}/>
            <ChangeProfileImageModal show={showChangeProfileImageModal} setShow={setShowChangeProfileImageModal}
                                     originalImage={user.profile_image}/>
            <ChangeBannerModal show={showChangeBannerModal} setShow={setShowChangeBannerModal}
                               originalImage={user.banner_image}/>
        </div>
    );
}
