import React, {useEffect, useState} from 'react';
import Logo_m from "../../asset/images/mobile/common/peeks_logo_mobile.svg"
import Flex from "../../components/layout/flex";
import 'src/asset/css/m_css.css';
import {Link} from "react-router-dom";
import Btn from "../../components/form/btn";
import useUser from "../../hooks/useUser";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import LoginPage from "../../apps/user/member/login";
import RegisterPage from "../../apps/user/member/register";

export default function MLHeader() {
    const { user, mutateUser } = useUser()
    const [loginModalShow, setLoginModalShow] = useState<boolean>(false)
    const [registerModalShow, setRegisterModalShow] = useState<boolean>(false)


    function registerOn(){
        setLoginModalShow(false)
        setRegisterModalShow(true)
    }
    return (
        <>
            <header id={"ml__header"}>
                <Flex justifyContent={"between"}>
                    <Link to="/">
                        <img src={Logo_m} alt="logo"/>
                    </Link>

                    <div className={"login"}>
                    {
                        user?.is_authenticated ?
                            <Btn onClick={e => {
                                e.preventDefault()
                                fetch('/api/user/logout')
                                    .then(res => {
                                        mutateUser()
                                        window.location.href = "/"
                                    })
                            }}>로그아웃</Btn>
                            : <Btn onClick={e=>setLoginModalShow(true)}>로그인</Btn>
                    }
                    </div>
                </Flex>
            </header>
            <LoginPageModal show={loginModalShow} setShow={setLoginModalShow} registerOn={registerOn}/>
            <RegisterPageModal show={registerModalShow} setShow={setRegisterModalShow}/>
        </>
    );
}

export function LoginPageModal({ show, setShow, registerOn=undefined }: { show: boolean, setShow: any, registerOn: any }){
    const [submitting, setSubmitting] = useState<boolean>(false);
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        fetch('/api/csrf-token');
    }, [])
    useEffect(()=>{

    },[submitting])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <div className={"member-popup"}>
                    <Btn className={"popup-close"} onClick={handleClose}> <FontAwesomeIcon icon={faClose}/> </Btn>
                    <LoginPage registerOn={registerOn}/>
                </div>
            </Modal.Body>
        </Modal>
    )
}
function RegisterPageModal({ show, setShow }: { show: boolean, setShow: any }){
    const [submitting, setSubmitting] = useState<boolean>(false);
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        fetch('/api/csrf-token');
    }, [])
    useEffect(()=>{

    },[submitting])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <div className={"member-popup"}>
                    <Btn className={"popup-close"} onClick={handleClose}> <FontAwesomeIcon icon={faClose}/> </Btn>
                    <RegisterPage/>
                </div>
            </Modal.Body>
        </Modal>
    )
}