import React, {useEffect, useState} from 'react';
import useUser from "../../../../hooks/useUser";
import {with_comma} from "../../../../utils/number_comma";
import {isMobile} from "react-device-detect";

interface levelSystem {
    name: string
    level: number
    exp: number
    messageCount: number | string
}
export const level_list: levelSystem[] = [
    {name: "손님", level: 0, exp: 0,              messageCount: 0},
    {name: "훈련병", level: 1, exp: 0,            messageCount: 0},
    {name: "이등병", level: 2, exp: 1000,         messageCount: 3},
    {name: "일병",  level: 3,  exp: 5000,         messageCount: 4},
    {name: "상병",  level: 4,  exp: 25000,        messageCount: 5},
    {name: "병장",  level: 5,  exp: 125000,       messageCount: 6},
    {name: "하사",  level: 6,  exp: 625000,       messageCount: 7},
    {name: "중사",  level: 7,  exp: 3125000,      messageCount: 8},
    {name: "상사",  level: 8,  exp: 15625000,     messageCount: 9},
    {name: "원사",  level: 9,  exp: 31250000,     messageCount: 10},
    {name: "소위",  level: 10,  exp: 62500000,    messageCount: 11},
    {name: "중위",  level: 11,  exp: 125000000,   messageCount: 12},
    {name: "대위",  level: 12,  exp: 250000000,   messageCount: 15},
    {name: "소령",  level: 13,  exp: 500000000,   messageCount: 20},
    {name: "중령",  level: 14,  exp: 1000000000,  messageCount: 25},
    {name: "대령",  level: 15,  exp: 2000000000,  messageCount: 30},
    {name: "준장",  level: 16,  exp: 4000000000,  messageCount: 40},
    {name: "소장",  level: 17,  exp: 8000000000,  messageCount: 50},
    {name: "중장",  level: 18,  exp: 16000000000, messageCount: "무제한"},
    {name: "대장",  level: 19,  exp: 32000000000, messageCount: "무제한"},
    {name: "원수",  level: 20,  exp: 64000000000, messageCount: "무제한"},
]
export default function Exp() {
    return (
        <div className={"board-user exp --pl-10 --pr-10"}>
            <header>
                <MyExp/>
            </header>

            {
                isMobile ? <LevelTable_mo list={level_list} />
                         : <LevelTable_new list={level_list} />
            }

        </div>
    );
}
function LevelTable_mo({list}: {list: levelSystem[]}){
    return (
        <div className={"new-exp-table-mo"}>
            <table>
                <thead>
                <tr>
                    <th>레벨</th>
                    <th>계급</th>
                    <th>경험치</th>
                    <th>1일 쪽지 횟수</th>
                </tr>
                </thead>
                <tbody>
                {
                    list.map((obj, index)=>(
                        <tr key={index}>
                            <td>{obj.level}</td>
                            <td>
                                <div className={"rank-card"}>
                                    <i className={`--rank level-${obj.level}`}></i>
                                    <span>{obj.name}</span>
                                </div>
                            </td>
                            <td>{with_comma(obj.exp)}</td>
                            <td>{obj.messageCount}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}
function LevelTable_new({list}: {list: levelSystem[]}){
    const level_list_1 = []
    const level_list_2 = []
    for(let i=1; i<list.length; i++){
        if(i < 11){
            level_list_1.push(list[i])
        }else{
            level_list_2.push(list[i])
        }
    }
    return (
        <div className={"new-exp-table"}>
            <table>
                <thead>
                <tr>
                    <th>레벨</th>
                    <th>계급</th>
                    <th>경험치</th>
                    <th>1일 쪽지 횟수</th>
                </tr>
                </thead>
                <tbody>
                {
                    level_list_1.map((obj, index)=>(
                        <tr key={index}>
                            <td>{obj.level}</td>
                            <td>
                                <div className={"rank-card"}>
                                    <i className={`--rank level-${obj.level}`}></i>
                                    <span>{obj.name}</span>
                                </div>
                            </td>
                            <td>{with_comma(obj.exp)}</td>
                            <td>{obj.messageCount}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <table>
                <thead>
                <tr>
                    <th>레벨</th>
                    <th>계급</th>
                    <th>경험치</th>
                    <th>1일 쪽지 횟수</th>
                </tr>
                </thead>
                <tbody>
                {
                    level_list_2.map((obj, index)=>(
                        <tr key={index}>
                            <td>{obj.level}</td>
                            <td>
                                <div className={"rank-card"}>
                                    <i className={`--rank level-${obj.level}`}></i>
                                    <span>{obj.name}</span>
                                </div>
                            </td>
                            <td>{with_comma(obj.exp)}</td>
                            <td>{obj.messageCount}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}

function MyExp(){
    const {user} = useUser()
    return (
        <div className={ isMobile ? "my-exp" : "my-cash" }>
            <strong>보유경험치</strong>
            <p>{user?.exp && with_comma(user.exp)}</p>
            <MyExpBar/>
        </div>
    )
}

function MyExpBar(){
    const {user} = useUser()
    const [lv , setLv] = useState<number>(0)
    const [exp, setExp] = useState<number>(0)
    const [leftExp, setLeftExp] = useState<number>(0)
    const [expPercent, setExpPercent] = useState<number>(0)

    useEffect(()=> {
        if(user){
            setLv(user.level)
            setExp(user.exp)

            if(user.level < 20){
                const nextAddFillExp = level_list[user.level + 1].exp - level_list[user.level].exp // 필요 총 경험치
                const userFillExp = user.exp - level_list[user.level].exp // 추가 현재 경험치

                const fillExp = nextAddFillExp - userFillExp
                const fillPercent = ((userFillExp / nextAddFillExp) * 100).toFixed(2)

                setLeftExp(fillExp)    // 필요 경험치 에서 남은 경험치
                setExpPercent(Number(fillPercent)) // 남은 경험치 %
            }else{
                // 만렙
                setLeftExp(0)    // 남은 경험치
                setExpPercent(100) // 남은 경험치 %
            }
        }
    }, [user])
    return (
        <div className={"next-rank-exp-bar"}>
            <div className={"rank-info now"}>
                <i className={"--rank level-" + lv}></i>
                <span>Lv. {lv}</span>
            </div>
            <div className={"bar-info"}>
                <div className={"bar"}>
                    <div className={"gauge"} style={{ width: expPercent+"%" }}></div>
                </div>
                <span>다음 계급 까지 남은 경험치 <b>{ with_comma( leftExp ) }</b></span>
            </div>
            <div className={"rank-info next"}>
                <i className={"--rank level-" + (lv+1)}></i>
                <span>
                    {`Lv. ${ lv < 20 ? lv+1 : "Max"}`}
                </span>
            </div>
        </div>
    )
}

function WarningExp(){
    return (
        <div className={"warning"}>
            <header>레벨 안내</header>
            <main>소위 이상 계급은 <strong className={"--color-pink"}>300,000</strong> <strong>EXP</strong> 부터 가능하며, 특수권한 지급 관계로 인하여 <strong className={"--color-pink"}>매일 00시 00분에 재설정</strong> 됩니다. <br/>
                회원님께서 보유하고 계신 포인트를 경험치로 전환하실 수 있습니다. 1 포인트당 1 경험치로 환산됩니다.</main>
        </div>
    )
}
