import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { Button, Form, Modal } from 'react-bootstrap';
import ReactQuill, { Quill } from "react-quill";
import ImageCompress from 'quill-image-compress';

import 'react-quill/dist/quill.snow.css';
Quill.register('modules/imageCompress', ImageCompress);

export default function RichEditor({ content, setContent }: { content: string, setContent: Dispatch<SetStateAction<string>> }) {
    const quillRef = useRef<ReactQuill>();
    const [showYoutubeModal, setShowYoutubeModal] = useState<boolean>(false)

    function addYoubuteVideo(url: string) {
        let validatedUrl = sanitizeYoutubeUrl(url);
        if (validatedUrl) {
            const editor = quillRef.current!.getEditor();
            let range = editor.getSelection() || { index: 0, length: 0 };
            editor.insertEmbed(range.index, 'video', validatedUrl);
        } else {
            alert('올바른 유튜브 링크 주소가 아닙니다.')
        }
    }

    function sanitizeYoutubeUrl(url: string) {
        let match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
            url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
            url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/) ||
            // youtube shorts
            url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/);
        if (match && match[2].length === 11) {
            return ('https') + '://www.youtube.com/embed/' + match[2] + '?showinfo=0';
        }
        if (match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/)) { // eslint-disable-line no-cond-assign
            return (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/';
        }
        return;
    }

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image', 'video'],
                ['clean'],
            ],
            handlers: {
                video: (e: any) => setShowYoutubeModal(true),
            }
        },
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
        // FIXME GIF는 압축이 안되서 용량 문제가 있다 다른 압축 라이브러리를 사용하자
        imageCompress: {
            quality: 0.7, // default: 0.7
            maxWidth: 800, // default: 1000
            maxHeight: 100000, // default: 1000
            imageType: "auto",
            keepImageTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
            ignoreImageTypes: ["image/gif"],
            suppressErrorLogging: true, // default
            insertIntoEditor: undefined, // default
        }
    }), [])
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike',
        'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video',]

    return <>
        <ReactQuill
            ref={(element) => {
                if (element !== null) {
                    quillRef.current = element;
                }
            }}
            modules={modules}
            formats={formats}
            theme="snow"
            value={content}
            onChange={setContent}
        />
        <YoutubeModal show={showYoutubeModal} setShow={setShowYoutubeModal} addVideo={addYoubuteVideo} />
    </>
}

function YoutubeModal({ show, setShow, addVideo }: { show: boolean, setShow: any, addVideo: any }) {
    const handleClose = () => setShow(false);

    return <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>유튜브 영상 추가</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation(); // 모달에서 submit은 preventDefault로 제어가 안된다?
            addVideo(e.currentTarget.youtubeLink.value)
            setShow(false)
        }}>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>유튜브 영상 링크</Form.Label>
                    <Form.Control
                        name="youtubeLink"
                        placeholder="유튜브 링크"
                        autoFocus
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    닫기
                </Button>
                <Button type="submit" variant="primary">
                    추가
                </Button>
            </Modal.Footer>
        </Form>
    </Modal >
}