import { useRef } from "react";
import useUser from "src/hooks/useUser";
import api from "src/utils/api2";

import moment from "moment";
import { userInterface } from "src/components/user/interface";
import UserLevelNamecard from "src/components/user/user_level_namecard";

export interface Comment {
  id: number;
  writer: userInterface;
  content: string;
  created_dt: string;
  post: number;
}

interface CommentError {
  detail: string;
}

async function handleAPIError(res: Response) {
  switch (res.status) {
    case 403: // Forbidden 에러는 권한이 없는 경우 발생한다.
      const err: any = await res.json();
      alert(err.detail);
      break;
  }
}

export default function CommentsComponent({ postId, comments, setComments }: { postId: string; comments: Comment[]; setComments: any }) {
  const { user } = useUser();
  const newCommentRef = useRef<HTMLTextAreaElement>(null);

  return (
    <div className="comments">
      {comments?.map((comment, index) => (
        <CommentTemplate key={index} comment={comment} comments={comments} setComments={setComments} />
      ))}

      <footer className={"comment-input-form"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target as HTMLFormElement);
            formData.set("post", postId);
            api(`/api/board/comments/`, {
              method: "POST",
              body: formData,
            })
              .then((res) => res.json())
              .then((new_comment) => {
                setComments([...comments, new_comment]);
                newCommentRef.current!.value = "";
              })
              .catch((res) => handleAPIError(res));
          }}
        >
          <textarea ref={newCommentRef} name="content" placeholder={!user?.is_authenticated ? "댓글은 로그인이 필요한 서비스 입니다." : ""}></textarea>
          <button type={"submit"}>댓글쓰기</button>
        </form>
      </footer>
    </div>
  );
}

function CommentTemplate({ comment, comments, setComments }: { comment: Comment; comments: Comment[]; setComments: any }) {
  const { user } = useUser();
  const isWriter = user && user.uuid === comment.writer.uuid;
  return (
    <div className={"comment-box"}>
      <header>
        <UserLevelNamecard user={comment.writer} />
        <p>{moment(comment.created_dt).format("YY/MM/DD HH:mm:ss")}</p>
      </header>
      <main>{comment.content}</main>
      <footer>
        <div className={"board-btn"}>
          {isWriter && (
            <>
              <button className={"btn"}>수정</button>
              <button
                className={"btn"}
                onClick={() =>
                  api(`/api/board/comments/${comment.id}/`, { method: "DELETE" })
                    .then((res) => setComments(comments.filter((item) => item.id !== comment.id)))
                    .catch((res) => handleAPIError(res))
                }
              >
                삭제
              </button>
            </>
          )}
          <button>신고</button>
        </div>
      </footer>
    </div>
  );
}
