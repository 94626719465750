import { useState } from "react";
import { Link } from "react-router-dom";

import RichEditor from "../richEditor";

export default function PostCreateComponent({ createPost }: { createPost: (newPost: {}) => void }) {
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");

  function handleCreate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!title) {
      return alert("제목을 입력해주세요");
    } else if (!content) {
      return alert("게시물 내용을 작성해주세요");
    }
    createPost({ title, content });
  }
  // 작성, 수정 모드인 경우
  return (
    <div className={"board-table"}>
      <div className="edit-board">
        <form onSubmit={handleCreate}>
          <div className={"edit-form-title"}>
            <input type="text" name={"title"} value={title} onChange={(e) => setTitle(e.target.value)} placeholder={"제목을 입력하세요."} />
          </div>
          <div className={"edit-form-content"}>
            <RichEditor content={content} setContent={setContent} />
          </div>

          <footer className={"edit-form-btn"}>
            <Link className={"write-new"} to={`/community/free/`}>
              취소
            </Link>
            <button className={"write-new"} type={"submit"}>
              등록
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
}
