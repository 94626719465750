import React from "react";
import { Outlet } from "react-router-dom"
import BoardHead from "../../components/board/board_head";
import BoardTab from "../../components/board/board_tab";
import LayoutHeader from "../../components/layout/layout_header";

export const board_tab_list = [
  { eventKey: "exp", text: "경험치랭킹" },
  { eventKey: "gamepick", text: "픽공유랭킹" },
  { eventKey: "star", text: "받은별랭킹", accessError: true },
]
export default function RankingLayout() {
  return (
    <>
      <LayoutHeader tab={"rank"} />
      <BoardHead />
      <BoardTab tab_list={board_tab_list} page={"ranking"} />
      <Outlet />
    </>
  )
}