import React, {useContext, useEffect, useState} from 'react';
import icon from "src/asset/images/common/peeks_icon.svg"
import {isMobile} from "react-device-detect";
import Btn from "../components/form/btn";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage, faBars, faClose} from "@fortawesome/free-solid-svg-icons";
import Chat from "../apps/chat/chat";
import Flex from "../components/layout/flex";
import logo_m from "src/asset/images/mobile/common/peeks_logo_mobile.svg"
import Navigation from "./navigation";
import {Link} from "react-router-dom";
import {MinigameContext} from "../contexts/minigameProvider";
import GamePicker from "../components/gamePicker";

export default function Footer() {
    return isMobile ? <Mobile/> : <Desktop/>;
}

function Desktop(){
    return (
        <footer id={"gl__footer"}>
            <div>
                <header>
                    <img src={icon} alt="footer icon"/>

                    <p>
                        <b className={"--color-theme2"}>모두의픽은 분석 커뮤니티 입니다. 배팅 사이트가 아닙니다.</b> <br/>
                        정식 제휴를 거치지 않고 해당 자료를 사용할 경우 법적 불이익을 받을 수 있습니다.
                    </p>
                </header>
                <div className={'address'}>
                    <span>주소 : 서울특별시 강남구 영동대로 602, 6층 C120호</span>
                    <span>대표자 : 김민재</span>
                    <span>사업자번호 : 832-03-02880</span>
                </div>
                <ul className={"policy"}>
                    <li>
                        <Link to="/policy/1">이용약관</Link>
                    </li>
                    <li>
                        <Link to="/policy/2">개인정보취급방침</Link>
                    </li>
                    <li>
                        <Link to="/policy/3">청소년보호정책</Link>
                    </li>
                    <li>
                        <Link to="/policy/4">이메일수집거부</Link>
                    </li>
                    <li>
                        <Link to="/service_center/notices/">고객센터</Link>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

function Mobile(){
    const [chatShow, setChatShow] = useState<boolean>(false)
    const [navShow, setNavShow] = useState<boolean>(false)
    return (
        <>
            <div className={"mo-float-icon"}>
                <Btn onClick={e=>setChatShow(true)}> <FontAwesomeIcon icon={faMessage} /> </Btn>
                <Btn onClick={e=>setNavShow(true)}> <FontAwesomeIcon icon={faBars} /> </Btn>
            </div>
            <Desktop/>
            <MobileUserPickModal/>
            <MobileFloatChat show={chatShow} setShow={setChatShow}/>
            <MobileFloatNavigation show={navShow} setShow={setNavShow}/>
        </>
    )
}
function MobileUserPickModal(){
    const {mobilePickState, SetMobilePickState} = useContext(MinigameContext)
    return (
        mobilePickState
            ? <div className={"m-user-pick-layout"}>
                  <div className={"m-user-pick-modal"} onClick={SetMobilePickState}></div>
                  <GamePicker />
              </div>
            : <></>
    )
}

function MobileFloatChat({show, setShow}: { show: boolean, setShow: any }){
    useEffect(()=> {

    }, [show])
    return (
        <div className={`m-float ${ show ? "" : "--hidden" }`}>
            <div className={"chatting"}>
                <Flex className={"m-chat-header"} justifyContent={"between"}>
                    <img src={logo_m} alt=""/>
                    <Btn className={"float-chat-close"} onClick={e=>setShow(false)}> <FontAwesomeIcon icon={faClose}/> </Btn>
                </Flex>
                <Chat show={show}/>
            </div>
        </div>
    )
}

function MobileFloatNavigation({show, setShow}: { show: boolean, setShow: any }){
    function moClose(){
        setShow(false)
    }
    useEffect(()=> {

    }, [show])
    return (
        <div className={`m-float ${ show ? "" : "--hidden" }`}>
            <div className={"navigation"}>
                <Navigation moClose={moClose}/>

                <Btn className={"float-nav-close"}
                     onClick={e=> setShow(false)}> <FontAwesomeIcon icon={faClose}/> </Btn>
            </div>
        </div>
    )
}