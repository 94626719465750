import React, { useEffect, useState } from "react";
import Btn from "../../../../components/form/btn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { UserCash } from "./inventory";
import { ko } from "date-fns/esm/locale";
import PaginationComponent from "../../../../components/pagination";
import { isMobile } from "react-device-detect";
import Flex from "../../../../components/layout/flex";
import { Form } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function ItemHistoryPage(props: {}) {
  useEffect(() => {
    alert("준비중 입니다.");
    window.history.back();
  }, []);

  return (
    <div className={"board-table board-user inventory"}>
      <header>
        <UserCash />
        <InventorySearch />
      </header>

      <table>
        <thead>
          <tr>
            <th>번호</th>
            <th>상태</th>
            <th>사용내역</th>
            <th>아이템</th>
            <th>변경시간</th>
          </tr>
        </thead>
        <tbody>
          {/*{*/}
          {/*    [...Array(10)].map((_, index) => (*/}
          {/*        <tr key={index}>*/}
          {/*            <td>{index}</td>*/}
          {/*            <td>사용</td>*/}
          {/*            <td>아이템사용</td>*/}
          {/*            <td>닉네임변경</td>*/}
          {/*            <td>23/01/27 15:24:34</td>*/}
          {/*        </tr>*/}
          {/*    ))*/}
          {/*}*/}
        </tbody>
      </table>
      <PaginationComponent totalCount={10} />
    </div>
  );
}

export function InventorySearch() {
  const desktop = (
    <form className={"board-search-form"}>
      <div>
        <label className="form-radio">
          <input type="radio" name="type" defaultChecked={true} />
          <span className="btn">전체</span>
        </label>
        <label className="form-radio">
          <input type="radio" name="type" />
          <span className="btn">획득</span>
        </label>
        <label className="form-radio">
          <input type="radio" name="type" />
          <span className="btn">사용</span>
        </label>
      </div>

      <div className={"d-flex --fl-items-center --gap-10"}>
        <div className="form-date-range">
          <div>시작날짜</div>
          <DateRange range={"from"} />
          <div>종료날짜</div>
          <DateRange range={"to"} />
        </div>
        <Btn type="default">검색</Btn>
      </div>
    </form>
  );
  const mobile = (
    <div className={"board-search-form-mo"}>
      <Flex justifyContent={"between"}>
        <div>
          <label className="form-radio">
            <input type="radio" name="type" defaultChecked={true} />
            <span className="btn">전체</span>
          </label>
          <label className="form-radio">
            <input type="radio" name="type" />
            <span className="btn">획득</span>
          </label>
          <label className="form-radio">
            <input type="radio" name="type" />
            <span className="btn">사용</span>
          </label>
        </div>

        <Btn className={"submit"}>검색</Btn>
      </Flex>

      <div className={"--mt-10"}>
        <Flex className={"form-date-range"} justifyContent={"between"}>
          <div>
            <p>시작날짜</p>
            <DateRange range={"from"} />
          </div>
          <div>
            <p>종료날짜</p>
            <DateRange range={"to"} />
          </div>
        </Flex>
      </div>
    </div>
  );
  return isMobile ? mobile : desktop;
}

type DATE_RANGE = "from" | "to";
export function DateRange({ range }: { range: DATE_RANGE }) {
  const [startDate, setStart] = useState(new Date());
  return (
    <Flex gap={5}>
      <DatePicker dateFormat={"yyyy-MM-dd"} selected={startDate} locale={ko} name={range} onChange={(date: Date) => setStart(date)} />
      <FontAwesomeIcon icon={faCalendar} />
    </Flex>
  );
}
