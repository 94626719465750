import React from 'react';
import {Outlet} from "react-router-dom";
import MLHeader from "./ml_header";
import NoticeLanding from "../../apps/service_center/notice/notice_landing";

function MainContainer() {
    return (
        <main id={"m__main-container"}>
            <Outlet />
        </main>
    );
}

export default MainContainer;