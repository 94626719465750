import React from 'react';

function MarketWarning(props: {}) {
    return (
        <div className={"warning"}>
            <header>아이템 구매 전에 꼭 읽어주세요</header>
            <main>아이템을 포인트로 구매하실 경우 보너스포인트는 지급되지않습니다. <br/>
                별을 이용한 구매 시 받은별만 사용가능하며 구매한 별로는 아이템을 구매할 수 없습니다.</main>
        </div>
    );
}

export default MarketWarning;