import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import useUser from 'src/hooks/useUser';
import getCookie from 'src/utils/getCookie';
import { PrivateUser } from 'src/hooks/useUser';
import { Link, Navigate } from 'react-router-dom';
import JoinTemplate from "./join_template";
import api from "../../../utils/api";
import Checkbox from "../../../components/form/checkbox";
import {isMobile} from "react-device-detect";

interface RegisterFormError {
  non_field_errors?: string[]

  username?: string[]
  nickname?: string[]
  password?: string[]
  password2?: string[]
}

export default function RegisterPage() {
  return (<>
    <RegisterTemplate />
  </>)
}


function RegisterTemplate() {
  const { user, mutateUser } = useUser()
  const [agree, setAgree] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<RegisterFormError>();

  if (user?.is_authenticated) {
    alert("회원가입이 완료되었습니다.")
    return <Navigate to="/" replace={true} />
  }
  return (
    <JoinTemplate>
      <form onSubmit={async e => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement)
        const response = await fetch('/api/user/register', {
          method: "POST", body: formData, headers: {
            'X-CSRFToken': getCookie('csrftoken')!
          }
        })
        if (response.ok) {
          const userData: PrivateUser = await response.json();
          mutateUser(userData);
          if( isMobile ) {
            window.location.reload()
          }
        } else {
          const errorData: RegisterFormError = await response.json();
          setFormErrors(errorData);
        }
      }}>
        <div className={"form-row --mb-15"}>
          <strong>이메일</strong>
          <input type="text" name={"username"} placeholder={"mopick@gmail.com"} />
          {formErrors?.username && <Form.Text className='error' style={{ color: 'red', display: 'block' }}>{formErrors.username}</Form.Text> || <Form.Text></Form.Text>}
        </div>
        <div className={"form-row --mb-15"}>
          <strong>닉네임</strong>
          <input type="text" name={"nickname"} placeholder={"닉네임 입력 (최대 8글자)"} maxLength={8} />
          {formErrors?.nickname && <Form.Text className='error' style={{ color: 'red', display: 'block' }}>{formErrors.nickname}</Form.Text> || <Form.Text></Form.Text>}
        </div>
        <div className={"form-row --mb-15"}>
          <strong>비밀번호</strong>
          <input type="password" name={"password"} placeholder={"비밀번호입력(6자리 이상)"}/>
          {formErrors?.password && <Form.Text className='error' style={{ color: 'red', display: 'block' }}>{formErrors.password}</Form.Text> || <Form.Text></Form.Text>}
        </div>
        <div className={"form-row --mb-10"}>
          <strong>비밀번호확인</strong>
          <input type="password" name={"password2"} placeholder={"비밀번호확인입력"} />
          {formErrors?.password2 && <Form.Text className='error' style={{ color: 'red', display: 'block' }}>{formErrors.password2}</Form.Text> || <Form.Text></Form.Text>}
        </div>
        <div className={"form-row"}>
          <strong></strong>
          <label className={"remember"}>
            <Checkbox name={"agree"} required invalidMessage={"약관 동의를 체크해주시기 바랍니다."} />
            <span><b>이용약관</b> 및 <b>개인정보취급방침</b>에 동의 합니다.</span>
          </label>
        </div>

        <button className={"btn btn-theme2 "} type={"submit"}>모두의픽 회원가입하기</button>
      </form>
    </JoinTemplate>
  )
}