import React, { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import api from "src/utils/api"
import PaginationComponent from "../../../../components/pagination";
import useUser from "../../../../hooks/useUser";
import {with_comma} from "../../../../utils/number_comma";
import Btn from "../../../../components/form/btn";
import {Link, useLocation} from "react-router-dom";
import {isMobile} from "react-device-detect";
import HistorySearchFilter from "./HistorySearchFilterComponent";
import moment from "moment";

interface CashHistoryApi {
    count: number
    results: CashHistory[]
}

interface CashHistory {
    amount: number,
    explanation: string,
    created_at: string,
}

export default function CashHistoryPage() {
    const [cashHistories, setCashHistories] = useState<CashHistory[]>()
    const location = useLocation()

    useEffect(() => {
        api(`/api/user/cash-history/${window.location.search}`)
            .then(res => res.json())
            .then((data: CashHistoryApi) => {
                setCashHistories(data.results)
            })
            .catch(err => alert(JSON.stringify(err)))
        // console.log(cashHistories)
    }, [location])

    return (
        <div className={"board-table board-user cash"}>
            <header>
                <UserCash/>
                <HistorySearchFilter isMobile={isMobile}/>
            </header>

            {
                isMobile ? <TableTemplateMobile cashHistories={cashHistories}/>
                         : <TableTemplateDesktop cashHistories={cashHistories}/>
            }

            <PaginationComponent totalCount={10} />
        </div>
    )
}

function TableTemplateDesktop({cashHistories}: {cashHistories: CashHistory[] | undefined}){
    return (
        <table>
            <thead>
            <tr>
                <th>번호</th>
                <th>변동 캐시</th>
                <th>변동 사유</th>
                <th>날짜</th>
            </tr>
            </thead>
            <tbody>
            {
                cashHistories?.map((cash, index) => (
                    <tr key={index}>
                        <td>{cashHistories.length - index}</td>
                        <td className={`change ${ cash.amount > 0 ? "add" : "sub" }`}>{with_comma(cash.amount)}</td>
                        <td>{cash.explanation}</td>
                        <td>{moment(cash.created_at).format('YY/MM/DD HH:mm:ss')}</td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    )
}
function TableTemplateMobile({cashHistories}: {cashHistories: CashHistory[] | undefined}){
    return (
        <table className={"history-board-mo"}>
            <thead>
            <tr>
                <th>번호</th>
                <th>변동 캐시</th>
                <th>변동 사유</th>
            </tr>
            </thead>
            <tbody>
            {
                cashHistories?.map((cash, index) => (
                    <tr key={index}>
                        <td>{cashHistories.length-index}</td>
                        <td className={`change ${ cash.amount > 0 ? "add" : "sub" }`}>
                            { with_comma(cash.amount) }
                        </td>
                        <td>
                            <p>{cash.explanation}</p>
                            <p>{moment(cash.created_at).format('YY/MM/DD HH:mm:ss')}</p>
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </table>
    )
}

function UserCash(){
    const {user} = useUser()
    return (
        <div className={"my-cash"}>
            <strong>보유캐시</strong>
            <p className={"--color-pink"}>{user?.cash && with_comma(user.cash)}</p>
            <Btn type={"theme"}>
                <Link to={"/market/"} onClick={e=> {
                    e.preventDefault()
                    alert('준비중 입니다')
                }}>캐시충전</Link>
            </Btn>
        </div>
    )
}
