import React, {useEffect, useState} from 'react';
import api from "../../../utils/api";
import {useNavigate, useSearchParams} from "react-router-dom";

interface ServiceCenterPostsApi {
    count: number
    results: Post[]
}

interface Post {
    id: number
    title: string
    writer: {
        uuid: string
        nickname: string
        rank: string
    }
    comment_count: number
    created_dt: string
}


function NoticeLanding() {
    const [posts, setPosts] = useState<Post[]>();
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    useEffect(()=> {
        api(`/api/service_center/notices/?page_size=1`)
            .then(res => res.json())
            .then((data: ServiceCenterPostsApi) => {
                setPosts(data.results)
            })
            .catch(error => alert(JSON.stringify(error)))
    }, [])
    return (
        <div className={"notice-landing"}>
            {
                posts ? posts.map((obj, index)=>(
                            <div key={index} onClick={e=> navigate(`/service_center/notices/${obj.id}`)}>
                                <span className={"type"}>NOTICE</span>
                                <div className={"title"}>{obj.title}</div>
                            </div>
                        ))
                      : <></>
            }
        </div>
    );
}

export default NoticeLanding;