import React, { useEffect, useState } from "react";
import {Link, Outlet} from "react-router-dom";
import useUser from "src/hooks/useUser";
import { MessageType } from "src/types/user";
import api from "src/utils/api2";
import Btn from "../../../components/form/btn";
import Flex from "../../../components/layout/flex";
import PaginationComponent from "../../../components/pagination";
import { useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

type MessageTabKinds = "received" | "sent" | "stored";

export default function MessageList({ tab }: { tab: MessageTabKinds }) {
    const { user, mutateUser } = useUser();

    const [messageList, setMessageList] = useState<MessageType[]>();
    const [messageCount, setMessageCount] = useState<number>();
    const [checkedMessageIdSet, setCheckedMessageIdSet] = useState<Set<number>>(new Set<number>());
    const [searchParams, setSearchParams] = useSearchParams()


    async function fetchMessages() {
        const fetch_url = `/api/user/messages/${tab}/?${searchParams}`
        return api(fetch_url)
            .then((res) => res.json())
            .then((data) => {
                setMessageList(data.results);
                setMessageCount(data.count);
            });
    }

    useEffect(() => {
        fetchMessages()
        return () => setMessageList(undefined)
    }, [tab, searchParams]);


    async function handleDeleteMessage(deletedMessage: MessageType) {
        return await api(`/api/user/messages/${tab}/${deletedMessage.id}/`, { method: 'DELETE' })
            .then(() => fetchMessages().catch((res: Response) => {
                if (res.status === 404) {
                    const pageNum = Number(searchParams.get('page') || 1)

                    if (pageNum > 1) {
                        const newParams = new URLSearchParams(searchParams)
                        newParams.set('page', (pageNum - 1).toString())
                        setSearchParams(newParams)
                    }
                }
            }))
    }

    async function handleDeleteCheckedMessage() {
        if (checkedMessageIdSet.size === 0) {
            alert('선택한 쪽지가 없습니다.')
            return
        }
        if (!window.confirm("선택한 쪽지를 삭제 하시겠습니까?")) {
            return
        }

        await api(`/api/user/messages/${tab}/delete_many/`, {
            method: 'DELETE',
            body: JSON.stringify([...checkedMessageIdSet]),
            headers: { 'content-type': "application/json" }
        }).then(res => setCheckedMessageIdSet(new Set()))
            .then(() => {
                mutateUser()
                alert('쪽지를 삭제하였습니다.')
            })

        fetchMessages().catch((res: Response) => {
            if (res.status === 404) {
                const pageNum = Number(searchParams.get('page') || 1)

                if (pageNum > 1) {
                    const newParams = new URLSearchParams(searchParams)
                    newParams.set('page', (pageNum - 1).toString())
                    setSearchParams(newParams)
                }
            }
        })
    }


    async function handleStoreCheckedMessage() {
        if (checkedMessageIdSet.size === 0) {
            alert('선택한 쪽지가 없습니다.')
            return
        }
        if (!window.confirm("선택한 쪽지를 보관 하시겠습니까?")) {
            return
        }

        await api(`/api/user/messages/${tab}/store_many/`, {
            method: 'PATCH',
            body: JSON.stringify([...checkedMessageIdSet]),
            headers: { 'content-type': "application/json" }
        }).then(res => setCheckedMessageIdSet(new Set()))
            .then(() => alert('쪽지를 보관했습니다.'))

        fetchMessages().catch((res: Response) => {
            if (res.status === 404) {
                const pageNum = Number(searchParams.get('page') || 1)

                if (pageNum > 1) {
                    const newParams = new URLSearchParams(searchParams)
                    newParams.set('page', (pageNum - 1).toString())
                    setSearchParams(newParams)
                }
            }
        })
    }

    function handleCheck(checkedMessage: MessageType) {
        setCheckedMessageIdSet(set => {
            const newSet = new Set(set)
            if (set.has(checkedMessage.id)) {
                newSet.delete(checkedMessage.id)
            } else {
                newSet.add(checkedMessage.id)
            }
            return newSet
        })
    }

    function handleCheckAll() {
        if (checkedMessageIdSet.size === messageList?.length) {
            setCheckedMessageIdSet(new Set());
        } else {
            setCheckedMessageIdSet(new Set(messageList?.map(message => message.id)))
        }
    }

    function handleReadMessage(readMessage: MessageType) {
        const isReceiver = user && user.uuid === readMessage.receiver.uuid
        if (tab === 'received' && !readMessage.read_dt && isReceiver) {
            api(`/api/user/messages/${tab}/${readMessage.id}/read/`)
                .then(res => res.json())
                .then((updatedMessage: MessageType) => {
                    mutateUser()
                    setMessageList(messageList => messageList?.map(message => message.id === readMessage.id ? updatedMessage : message))
                })
        }
    }

    return (
        <>
            <header>
                <ul className={"message-warning"}>
                    <li>
                        받은 쪽지는 <b className={"--color-red"}>7일</b> 경과 후 자동삭제됩니다.
                    </li>
                    <li>
                        보관함을 이용하시면 <b className={"--color-red"}>30일</b> 동안 보관이 가능합니다.
                    </li>
                </ul>
                <Flex gap={10} className={"--mt-20"}>
                    <Btn rect={[90, 28]} className={"--font-12"} onClick={handleCheckAll}>
                        전체선택
                    </Btn>
                    <Btn rect={[90, 28]} className={"--font-12"} onClick={handleDeleteCheckedMessage}>
                        선택삭제
                    </Btn>
                    {tab === "received" ? (
                        <Btn rect={[90, 28]} className={"--font-12"} onClick={handleStoreCheckedMessage}>
                            선택보관
                        </Btn>
                    ) : (
                        <></>
                    )}
                </Flex>
            </header>

            <div className={"board-table"}>
                <table>
                    <thead>
                    {
                        isMobile ? <tr>
                                <th style={{ width: "2.5rem" }}></th>
                                <th style={{ minWidth: "10rem", width: "30%" }}>{tab == "sent" ? "받은사람" : "보낸사람"}</th>
                                <th>보낸시간</th>
                                <th>확인시간</th>
                            </tr>
                            : <tr>
                                <th style={{ width: "3%" }}></th>
                                <th style={{ width: "15%" }}>{tab == "sent" ? "받은사람" : "보낸사람"}</th>
                                <th style={{ width: "45%" }}>내용</th>
                                <th style={{ width: "15%" }}>보낸시간</th>
                                <th style={{ width: "15%" }}>확인시간</th>
                            </tr>
                    }

                    </thead>
                    <tbody>
                    {messageList?.map((message, index) => {
                        if (isMobile) {
                            return (
                                <MessageItemMobile key={index} message={message}
                                                   tab={tab}
                                                   checked={checkedMessageIdSet.has(message.id)}
                                                   onCheck={handleCheck}
                                                   onReadMessage={handleReadMessage}
                                                   onDeleteMessage={handleDeleteMessage} />
                            )
                        } else {
                            return (
                                <MessageItem key={index} message={message}
                                             tab={tab}
                                             checked={checkedMessageIdSet.has(message.id)}
                                             onCheck={handleCheck}
                                             onReadMessage={handleReadMessage}
                                             onDeleteMessage={handleDeleteMessage} />
                            )
                        }
                    })}
                    </tbody>
                </table>
                <PaginationComponent totalCount={messageCount} perPage={20} />
            </div>
        </>
    );
}

function MessageItemMobile({ tab, message, checked, onCheck, onReadMessage, onDeleteMessage }: { tab: MessageTabKinds; message: MessageType; checked: boolean; onCheck: (checkedMessage: MessageType) => void; onReadMessage: (readMessage: MessageType) => void; onDeleteMessage: (deletedMessage: MessageType) => Promise<void> }) {
    const [open, setOpen] = useState<boolean>(false);

    function handleOpen() {
        setOpen(!open)
        onReadMessage(message)
    }

    function handleDelete() {
        if (!window.confirm("쪽지를 삭제하시겠습니까?")) {
            return
        }
        onDeleteMessage(message)
            .then(() => {
                setOpen(!open)
                alert('쪽지를 삭제하였습니다.')
            })
    }

    return (
        <>
            <tr>
                <td onClick={() => onCheck(message)}>
                    <input type={"checkbox"} name={"target"} checked={checked} readOnly />
                </td>
                <td>{tab == "sent" ? message.receiver.nickname : message.sender.nickname}</td>
                <td onClick={handleOpen}>{message.created_dt}</td>
                <td onClick={handleOpen}>{message.read_dt || '-'}</td>
            </tr>
            {open && (
                <tr>
                    <td colSpan={4} className={"message-table-cell"}>
                        <div className={"--padding-10"}>
                            <div style={{whiteSpace: 'pre-line'}}>{message.content}</div>
                        </div>
                        <div className={"--mb-10"}>
                            <Flex gap={10} justifyContent={"center"}>
                                {
                                    tab != 'sent'
                                        ? message.sender.nickname != "관리자"
                                            ? <Link to="../write/" state={{ to: message.sender.nickname }}><Btn rect={[84, 24]}>답장</Btn></Link>
                                            : <></>
                                        : <></>
                                }
                                <Btn rect={[84, 24]} onClick={handleDelete}>삭제</Btn>
                            </Flex>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}
function MessageItem({ tab, message, checked, onCheck, onReadMessage, onDeleteMessage }: { tab: MessageTabKinds; message: MessageType; checked: boolean; onCheck: (checkedMessage: MessageType) => void; onReadMessage: (readMessage: MessageType) => void; onDeleteMessage: (deletedMessage: MessageType) => Promise<void> }) {
    const [open, setOpen] = useState<boolean>(false);

    function handleOpen() {
        setOpen(!open)
        onReadMessage(message)
    }

    function handleDelete() {
        if (!window.confirm("쪽지를 삭제하시겠습니까?")) {
            return
        }
        onDeleteMessage(message)
            .then(() => {
                setOpen(!open)
                alert('쪽지를 삭제하였습니다.')
            })
    }

    return (
        <>
            <tr>
                <td onClick={() => onCheck(message)}>
                    <input type={"checkbox"} name={"target"} checked={checked} readOnly />
                </td>
                <td>{tab == "sent" ? message.receiver.nickname : message.sender.nickname}</td>
                <td onClick={handleOpen}>
                    {
                        message.content.split("\n")[0].length > 20 ? (message.content.split("\n")[0]).substring(0, 20) + "..." : message.content.split("\n")[0]
                    }
                </td>
                <td>{message.created_dt}</td>
                <td>{message.read_dt || '-'}</td>
            </tr>
            {open && (
                <tr>
                    <td colSpan={6} className={"message-table-cell"}>
                        <div className={"--padding-10"}>
                            <div style={{whiteSpace: 'pre-line'}}>{message.content}</div>
                        </div>
                        <div className={"--mb-10"}>
                            <Flex gap={10} justifyContent={"center"}>
                                {
                                    tab != 'sent'
                                        ? message.sender.nickname != "관리자"
                                            ? <Link to="../write/" state={{ to: message.sender.nickname }}><Btn rect={[84, 24]}>답장</Btn></Link>
                                            : <></>
                                        : <></>
                                }
                                <Btn rect={[84, 24]} onClick={handleDelete}>삭제</Btn>
                            </Flex>
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
}
