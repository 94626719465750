import { PublicUserType } from "./user"

export interface GamePickUserType extends PublicUserType {
    stats: {
        streak: number
        win: number
        lose: number
    }
}

export interface GamePickType {
    id: string
    kind: "HAPPYBALL" | "HAPPYBALL_LADDER" | "EOS3" | "EOS5" | "PBG5" | "NTRY_LADDER" | "DH"
    user: GamePickUserType
    game_pick: keyof typeof GAME_PICK_TEXT
    round_daily: number
    round_date: string
}

export const GAME_KIND_LABELS = {
    "HAPPYBALL": "해피545",
    "HAPPYBALL_LADDER": "해피545 사다리",
    "EOS3": "EOS 3분",
    "EOS5": "EOS 5분",
    "PBG5": "PBG 5분",
    "NTRY_LADDER": "엔트리 사다리",
    "DH": "동행파워볼"
}

export const GAME_PICK_TEXT = {
    POWERBALL_ODD: "파워볼 홀수",
    POWERBALL_EVEN: "파워볼 짝수",
    POWERBALL_UNDER: "파워볼 언더",
    POWERBALL_OVER: "파워볼 오버",
    BALLS_ODD: "일반볼 홀수",
    BALLS_EVEN: "일반볼 짝수",
    BALLS_UNDER: "일반볼 언더",
    BALLS_OVER: "일반볼 오버",
    BALLS_SMALL: "일반볼 소",
    BALLS_MEDIUM: "일반볼 중",
    BALLS_LARGE: "일반볼 대",
    LEFT: "좌",
    RIGHT: "우",
    THREE: "3줄",
    FOUR: "4줄",
    ODD: "홀",
    EVEN: "짝",
    LEFT_FOUR_ODD: "좌4",
    LEFT_THREE_EVEN: "좌3",
    RIGHT_THREE_ODD: "우3",
    RIGHT_FOUR_EVEN: "우4",
}