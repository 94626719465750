import moment from "moment";
import { useContext, useEffect, useState, useRef, FormEvent } from "react";
import { useInterval } from "usehooks-ts";

import { transMinutesCount } from "src/apps/minigame/happyball/game/game_board_timer_circle";
import { GAME_COUNT_TYPE, GAME_LAST_ROUND } from "src/apps/minigame/happyball/interface";
import Flex from "src/components/layout/flex";
import UserLevelNamecard from "src/components/user/user_level_namecard";
import { MiniGameDelay, MinigameContext, getStringMinute } from "src/contexts/minigameProvider";
import useUser from "src/hooks/useUser";
import {MinigameType, MinigameTypeNames} from "src/types/minigame";
import { GAME_PICK_VALUE_TYPE, LADDER_PICK_VALUE_TYPE, PickRoomType, RoomPickType } from "src/types/room";
import { PublicUserType } from "src/types/user";
import api from "src/utils/api2";

import { minigameTabList } from "../minigame/tab";
import { GamePickType } from "src/types/gamepick";
import { RoomSocketContext } from "./pickRoom";

const GAME_PICK_TEXT: any = {
    POWERBALL: "파워볼",
    BALLS: "일반볼",
    POWERBALL_ODD: "파워볼 홀수",
    POWERBALL_EVEN: "파워볼 짝수",
    POWERBALL_UNDER: "파워볼 언더",
    POWERBALL_OVER: "파워볼 오버",
    BALLS_ODD: "일반볼 홀수",
    BALLS_EVEN: "일반볼 짝수",
    BALLS_UNDER: "일반볼 언더",
    BALLS_OVER: "일반볼 오버",
    BALLS_SMALL: "일반볼 소",
    BALLS_MEDIUM: "일반볼 중",
    BALLS_LARGE: "일반볼 대",
    LEFT: "좌",
    RIGHT: "우",
    THREE: "3줄",
    FOUR: "4줄",
    ODD: "홀",
    EVEN: "짝",
    UNDER: "언",
    OVER: "오",
    SMALL: "소",
    MEDIUM: "중",
    LARGE: "대",
    LEFT_FOUR_ODD: "좌4",
    LEFT_THREE_EVEN: "좌3",
    RIGHT_THREE_ODD: "우3",
    RIGHT_FOUR_EVEN: "우4",
}

interface ErrorType {
    round_date?: string[]
    round_daily?: string[]
    user?: string[]
}

function getNextRoundLeftTime(termSeconds: number) {
    return 1000 * termSeconds - new Date().valueOf() % (1000 * termSeconds)
}

function getNowRound(time: Date, gameType: MinigameType) {
    const game_count_minute = GAME_COUNT_TYPE[gameType]
    const draw_for_hour = 60 / game_count_minute
    let setRound = Math.floor(time.getHours() * draw_for_hour + time.getMinutes() / game_count_minute) + 1

    if (setRound > GAME_LAST_ROUND[gameType]) {
        setRound = 1
    }

    return setRound
}

export default function PickRoomGamePicker({ room, isHost, host, picks }: { room: PickRoomType, isHost: any, host: PublicUserType, picks: GamePickType[] }) {

    useEffect(()=> {
        const length = picks.length
        for(let obj of picks){

        }
    }, [picks])
    return (
        <div className="game-pick">
            <header className="layout-header">
                <p>게임픽</p>
                <UserLevelNamecard user={host} />
            </header>
            <main>
                {isHost ? <RoomGamePickLayout room={room} /> : <></>}
                <table className={"host-pick-table"}>
                    <thead>
                        <tr>
                            <th colSpan={5}>
                                <Flex gap={20}>
                                    <p className={"--font-14"}>적중: <b>0</b></p>
                                    <p className={"--font-14"}>미적중: <b>0</b></p>
                                    <p className={"--font-14"}>승률: <b>00.00%</b></p>
                                    <p className={"--font-14"}><b>0</b> 연승</p>
                                </Flex>
                            </th>
                        </tr>
                        <tr>
                            <th>게임</th>
                            <th>날짜/회차</th>
                            <th>메인픽</th>
                            <th>서브픽</th>
                            <th>결과</th>
                        </tr>
                    </thead>
                    <tbody>
                        {picks.map((pick, index) => {
                            let pick_main = pick.game_pick.split('_')[1]
                            let pick_sub = pick.game_pick.split('_')[0]
                            if(!pick_main){
                                pick_main = pick_sub
                                pick_sub = ""
                            }
                            return (
                                <tr key={index}>
                                    <td>{/* 게임 */}
                                        {MinigameTypeNames[pick.kind.toLocaleLowerCase() as MinigameType]}
                                    </td>
                                    <td>{/* 날짜/회차 */}
                                        {pick.round_date} / <b className={"--color-red"}>{pick.round_daily}</b>
                                    </td>
                                    <td>{/* 메인픽 */}
                                        <span className={`room-pick ${pick_main}`}>{GAME_PICK_TEXT[pick_main]}</span>
                                    </td>
                                    <td>{/* 서브픽 */}
                                        <span className={`room-pick ${pick_sub}`}>{GAME_PICK_TEXT[pick_sub]}</span>
                                    </td>
                                    <td>{/* 결과 */}
                                        <span className={"room-result SUCCESS"}>-</span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </main>
        </div>
    )
}
function RoomGamePickLayout({ room }: { room: PickRoomType }) {
    const [gameType, setGameType] = useState<MinigameType>("happyball")
    const [gameLabel, setGameLabel] = useState<string>("해피545 5분")
    const { user } = useUser()

    // FIXME 로컬 타임을 timezone aware로 변경해야한다.
    const [time, setTime] = useState(new Date())
    const [round, setRound] = useState<number>(getNowRound(time, gameType))
    const [pickBlock, setPickBlock] = useState<boolean>(false)
    const [contextPickBlock, setContextPickBlock] = useState<boolean>(false)
    const { gamePickBlock } = useContext(MinigameContext)

    useEffect(() => {
        setGameLabel(MinigameTypeNames[gameType])
    }, [gameType])
    useEffect(() => {
        setTime(new Date)
    }, [gameType])
    useEffect(() => {
        setRound(getNowRound(time, gameType))
        const timer = setTimeout(() => {
            setTime(new Date)
        }, getNextRoundLeftTime(GAME_COUNT_TYPE[gameType] * 60) - (MiniGameDelay[gameType]))

        return () => {
            clearTimeout(timer)
        }
    }, [time, gameType, user])
    useEffect(() => {
        setContextPickBlock(gamePickBlock)
    }, [gamePickBlock])

    useInterval(() => {
        if (gameType == 'dh') {
            const date = new Date()
            if (date.getHours() < 6) {
                setRound(73)
                setPickBlock(true)
            } else {
                setPickBlock(false)
            }
        }
    }, 1000)

    return (
        <div className={"room-pick-layout"}>
            <RoomGamePick defaultGame={gameType} setGameType={setGameType} />
            <header>
                <span>{gameLabel}&nbsp;<b className={"--color-red"}>{round}</b>&nbsp;라운드 유저픽 등록</span>
                <PickTimers gameType={gameType} setPickBlock={setPickBlock} />
            </header>
            <PickComponent round={round} gameType={gameType} pickBlock={pickBlock} contextPickBlock={contextPickBlock} />
        </div>
    )
}

function RoomGamePick({ defaultGame, setGameType }: { defaultGame: MinigameType, setGameType: Function }) {
    const selectRef = useRef<HTMLSelectElement>(null)
    function setGameTypeOn() {
        if (selectRef.current) {
            setGameType(selectRef.current.value)
        }
    }
    return (
        <select className={"room-game-picker-nav"}
            ref={selectRef}
            onChange={setGameTypeOn} >
            {
                minigameTabList.map((type, index) => (
                    <option key={index} value={type.eventKey} >{type.text}</option>
                ))
            }
        </select>
    )
}

function PickComponent({ round, gameType, pickBlock, contextPickBlock }: { round: number; gameType: MinigameType; pickBlock: boolean; contextPickBlock: boolean }) {
    const [error, setError] = useState<ErrorType>()
    const [chosenPick, setChosenPick] = useState<RoomPickType>()
    const round_date = moment().format('YYYY-MM-DD')
    const { user } = useUser()
    const roomSocket = useContext(RoomSocketContext)

    useEffect(() => {
        roomSocket?.addEventListener("message", (event) => {
            const message = JSON.parse(event.data)
            switch (message.type) {
                case "pick":
                    if(message.pick.kind.toLocaleLowerCase() == gameType){
                        setChosenPick(message.pick);
                    }
                    break;
            }
        })
    }, [roomSocket])

    const pickURL = `/api/gamepick/${gameType}/`

    function handleCreatePick(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        if (!user || !user.is_authenticated) {
            return alert("로그인 후 이용 가능합니다.")
        }
        const formData = new FormData(e.currentTarget)
        const user_pick = `${formData.get('game_pick')}`
        if (!user_pick) {
            return alert('선택된 픽이 없습니다.')
        }

        /** 픽 등록 */
        formData.set("round_daily", round.toString())
        formData.set("round_date", round_date)
        api(pickURL, { method: "POST", body: formData })
            .then(res => res.json())
            .then((data: RoomPickType) => {
                setError(undefined)
                setChosenPick(data)
            })
            .catch(e => handleError(e))

    }
    async function handleError(res: Response) {
        if (res.headers.get('content-type') !== 'application/json') {
            return alert("오류가 발생하였습니다.")
        }
        const error: ErrorType = await res.json()
        if (error.round_date) {
            return alert(error.round_date)
        } else if (error.round_daily) {
            return alert(error.round_daily)
        } else if (error.user) {
            return alert(error.user)
        }
    }
    function fetchChosenApi() {
        // console.log(`/api/gamepick/${gameType}/${round_date}/${round}/mine/`)
        if (getNowRound(new Date, gameType) == round) {
            api(`/api/gamepick/${gameType}/${round_date}/${round}/mine/`)
                .then(res => res.json())
                .then((data: RoomPickType) => setChosenPick(data))
                .catch(e => setChosenPick(undefined))
        }
    }
    useEffect(() => {
        fetchChosenApi()
        return () => setChosenPick(undefined)
    }, [gameType, round])

    return (
        chosenPick
            ? <PickChosen round={round} game_pick={chosenPick.game_pick} />
            : pickBlock || contextPickBlock
                ? <PickBlock />
                : <PickForm onSubmit={handleCreatePick} gameType={gameType} />
    )
}

function PickBlock() {
    return (
        <>
            <main className={"pick-block-main"}>유저픽 등록 시간이 마감되었습니다.</main>
            <footer>
                <button className={"btn btn-gray --disabled"}>유저픽 등록</button>
            </footer>
        </>
    )
}

function PickChosen({ round, game_pick }: { round: number; game_pick: GAME_PICK_VALUE_TYPE }) {
    const pick_type = GAME_PICK_TEXT[game_pick].split(' ')[0]
    const pick_value = GAME_PICK_TEXT[game_pick].split(' ')[1]
    return (
        <>
            <main className={'pick-chosen-main'}>
                <p>{round} 회차 유저픽이 등록되었습니다.</p>
                <div>
                    {
                        pick_value
                            ? <>
                                <span>{pick_type}</span>
                                <span className={game_pick.split('_')[1]}>{pick_value}</span>
                            </>
                            : <>
                                <span>사다리</span>
                                <span className={game_pick}>{pick_type}</span>
                            </>
                    }
                </div>
            </main>
            <footer>
                <button className={"btn btn-gray --disabled"}>유저픽 등록</button>
            </footer>
        </>
    )
}

function PickTimers({ gameType, setPickBlock }: { gameType: MinigameType; setPickBlock: any }) {
    const [timer, setTimer] = useState<string>("00:00")

    useInterval(() => {
        const gameCount = getStringMinute(gameType)
        if (gameCount < 31) {
            setPickBlock(true)
        } else {
            setPickBlock(false)
        }
        setTimer(transMinutesCount(gameCount))
    }, 1000)
    return (
        <span>{timer}</span>
    )
}

function PickForm({ onSubmit, gameType }: { onSubmit: any; gameType: MinigameType }) {
    const [select, setSelect] = useState<GAME_PICK_VALUE_TYPE>("")

    const isLadder = gameType.split('_')[1]
    return (
        <form onSubmit={onSubmit} >
            {
                isLadder == 'ladder'
                    ? <LadderPickLabels setSelect={setSelect} />
                    : gameType == 'dh'
                        ? <DhBallPickLabels setSelect={setSelect} />
                        : <PowerballPickLabels setSelect={setSelect} />
            }
            <footer>
                <button type="submit" className={`${select ? "btn btn-theme2" : "btn btn-gray --disabled"}`}>유저픽 등록</button>
            </footer>
        </form>
    )
}

function LadderPickLabels({ setSelect }: { setSelect: any }) {
    return (
        <main className={"pick-form-main"}>
            <div className={"powerball"}>
                <p>출발 / 줄수</p>
                <div className={"cell col4"}>
                    <PickLabel setSelect={setSelect} text={"좌"} val={'LEFT'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={"우"} val={'RIGHT'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={"3"} val={'THREE'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={"4"} val={'FOUR'} isLadder={true} />
                </div>
            </div>
            <div className={"normal"}>
                <p>결과</p>
                <div className={"cell  col2"}>
                    <PickLabel setSelect={setSelect} text={'홀'} val={'ODD'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={'짝'} val={'EVEN'} isLadder={true} />
                </div>
            </div>
            <div className={"normal"}>
                <p>출발 + 줄수</p>
                <div className={"cell col4"}>
                    <PickLabel setSelect={setSelect} text={'좌3'} val={'LEFT_THREE_EVEN'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={'좌4'} val={'LEFT_FOUR_ODD'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={'우3'} val={'RIGHT_THREE_ODD'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={'우4'} val={'RIGHT_FOUR_EVEN'} isLadder={true} />
                </div>
            </div>
        </main>
    )
}

function PowerballPickLabels({ setSelect }: { setSelect: any }) {
    return (
        <main className={"pick-form-main"}>
            <div className={"powerball"}>
                <p>파워볼</p>
                <div className={"cell col4"}>
                    <PickLabel setSelect={setSelect} text={"홀"} val={'POWERBALL_ODD'} />
                    <PickLabel setSelect={setSelect} text={"짝"} val={'POWERBALL_EVEN'} />
                    <PickLabel setSelect={setSelect} text={"언더"} val={'POWERBALL_UNDER'} />
                    <PickLabel setSelect={setSelect} text={"오버"} val={'POWERBALL_OVER'} />
                </div>
            </div>
            <div className={"normal"}>
                <p>일반볼</p>
                <div className={"cell col4"}>
                    <PickLabel setSelect={setSelect} text={'홀'} val={'BALLS_ODD'} />
                    <PickLabel setSelect={setSelect} text={'짝'} val={'BALLS_EVEN'} />
                    <PickLabel setSelect={setSelect} text={'언더'} val={'BALLS_UNDER'} />
                    <PickLabel setSelect={setSelect} text={'오버'} val={'BALLS_OVER'} />
                </div>
                <div className={"cell col3"}>
                    <PickLabel setSelect={setSelect} text={'소'} val={'BALLS_SMALL'} />
                    <PickLabel setSelect={setSelect} text={'중'} val={'BALLS_MEDIUM'} />
                    <PickLabel setSelect={setSelect} text={'대'} val={'BALLS_LARGE'} />
                </div>
            </div>
        </main>
    )
}
function DhBallPickLabels({ setSelect }: { setSelect: any }) {
    return (
        <main className={"pick-form-main"}>
            <div className={"powerball"}>
                <p>파워볼</p>
                <div className={"cell col2"}>
                    <PickLabel setSelect={setSelect} text={"언더"} val={'POWERBALL_UNDER'} />
                    <PickLabel setSelect={setSelect} text={"오버"} val={'POWERBALL_OVER'} />
                </div>
            </div>
            <div className={"normal"}>
                <p>일반볼</p>
                <div className={"cell col2"}>
                    <PickLabel setSelect={setSelect} text={'홀'} val={'BALLS_ODD'} />
                    <PickLabel setSelect={setSelect} text={'짝'} val={'BALLS_EVEN'} />
                </div>
                <div className={"cell col3"}>
                    <PickLabel setSelect={setSelect} text={'소'} val={'BALLS_SMALL'} />
                    <PickLabel setSelect={setSelect} text={'중'} val={'BALLS_MEDIUM'} />
                    <PickLabel setSelect={setSelect} text={'대'} val={'BALLS_LARGE'} />
                </div>
            </div>
        </main>
    )
}

function PickLabel({ text, val, setSelect, isLadder }: { text: string, val: GAME_PICK_VALUE_TYPE | LADDER_PICK_VALUE_TYPE; setSelect: any; isLadder: boolean }) {
    let addClass: string = ""
    if (isLadder) {
        addClass = val
    } else {
        addClass = val.split('_')[1]
    }
    return (
        <label onClick={e => setSelect(val)}>
            <input type="radio" name={"game_pick"} value={val} />
            <span className={`pick ${addClass}`}>{text}</span>
        </label>
    )
}

PickLabel.defaultProps = {
    isLadder: false
}
