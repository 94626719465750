import React, {useEffect, useState} from 'react';
import useUser from "../../hooks/useUser";
import UserLevelNamecard from "../../components/user/user_level_namecard";
import {Link} from "react-router-dom";
import {with_comma} from "../../utils/number_comma";
import Btn from "../../components/form/btn";
import {Modal} from "react-bootstrap";
import LoginPage from "../../apps/user/member/login";
import RegisterPage from "../../apps/user/member/register";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import Flex from "../../components/layout/flex";

export default function User({move}: {move: any }) {
    const { user, mutateUser } = useUser()
    const [loginModalShow, setLoginModalShow] = useState<boolean>(false)
    const [registerModalShow, setRegisterModalShow] = useState<boolean>(false)


    function registerOn(){
        setLoginModalShow(false)
        setRegisterModalShow(true)
    }
    return (
        <>
            <div id={"ml__user"}>
                {
                    user?.is_authenticated
                        ? <>
                            <div className={"user"}>
                                <UserLevelNamecard user={user}/>
                                <Flex inline={true} gap={5}>
                                    <i className={"--icon message --font-16"} style={{width: "1.2em"}}></i>
                                    <a onClick={e=>move('/user/message-box/received/')} style={{lineHeight: 1.1}}>({user.new_message || 0})</a>
                                </Flex>
                            </div>
                            <div className={"user-goods"}>
                                <div>
                                    <i className={"--icon point-gold"}></i>
                                    <a onClick={e=>move("/user/history/point-history/")}>{with_comma(user.point)}</a>
                                </div>
                                <div>
                                    <i className={"--icon cash"}></i>
                                    <a onClick={e=>move("/user/history/cash-history/")}>{with_comma(user.cash)}</a>
                                </div>
                                <div>
                                    <i className={"--icon star-gold"}></i>
                                    <a onClick={e=>move("/user/history/star-history/")}>0</a>
                                </div>
                                <div>
                                    <i className={"--icon star-silver"}></i>
                                    <a onClick={e=>move("/user/history/star-received/")}>0</a>
                                </div>
                            </div>
                        </>
                        : <></>
                }
            </div>
        </>
    );
}

