import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import PostListComponent from "src/components/post/PostListComponent";
import { PostType } from "src/types/post";
import useUser from "../../hooks/useUser";

interface CommunityPostsApi {
  count: number;
  results: PostType[];
}

type CommunityTypes = "all" | "free" | "humor" | "video" | "picture" | "sports" | "guide";

export default function CommunityPostListPage() {
  const location = useLocation();
  let [searchParams] = useSearchParams();

  const [posts, setPosts] = useState<PostType[]>();
  const [count, setCount] = useState<number>(1);
  const params = useParams();
  const {user} = useUser()
  const type: CommunityTypes = (params.type as CommunityTypes) || "all";
  let perPage = 20;

  if (type === "picture" || type === "video") {
    if (isMobile) {
      perPage = 18;
    } else {
      perPage = 16;
    }
  }

  useEffect(() => {
    let fetch_url: string;

    if (type === "all") {
      searchParams.delete("type");
    } else {
      searchParams.set("type", type);
    }
    searchParams.set("page_size", perPage.toString());
    fetch_url = `/api/community/posts/?${searchParams}`;

    fetch(fetch_url)
      .then((res) => res.json())
      .then((data: CommunityPostsApi) => {
        // console.log(data.results)
        setPosts(data.results);
        setCount(data.count ? data.count : 1);
      })
      .catch((err) => alert(JSON.stringify(err)));

    return () => {};
  }, [location]);

  const listPath = `/community/${type}`;
  if (type === "picture" || type === "video") {
    return <PostListComponent posts={posts} listPath={listPath} previewMode={true} totalCount={count} isMobile={isMobile} showCreate={true} />;
  } else if (type === "all") {
    return <PostListComponent posts={posts} listPath={listPath} totalCount={count} indexType="postType" isMobile={isMobile} showCreate={false} />;
  } else if (type === "guide") {
    return <PostListComponent posts={posts} listPath={listPath} totalCount={count} indexType="id" isMobile={isMobile} showCreate={user?.is_staff} />;
  } else {
    return <PostListComponent posts={posts} listPath={listPath} totalCount={count} indexType="postType" isMobile={isMobile} showCreate={true} />;
  }
}
