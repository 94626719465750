import { RouteObject } from "react-router-dom";
import HomePage from "./home"
import Layout from "../market/_layout";
import Home from "../ranking/list";

const marketRoute: RouteObject = {
    path: "market",
    element: <Layout />,
    children: [
        {
            path: ":type", children: [
                { path: "", element: <Home /> },
                { path: ":id", element: <Home /> }
            ]
        }
    ]
}

export default marketRoute
