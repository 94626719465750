import React from 'react';
import dummy_chat_bottom from "../../asset/images/dummy/Group 72937.png";
import Chat from "../../apps/chat/chat";
import GamePicker from 'src/components/gamePicker';

export default function Aside() {
    return (
        <aside>
            <Chat/> {/* #chat__layout */}
            {/*<div className={"dummy-pick"}>*/}
            {/*    <img src={dummy_chat_bottom} alt="" />*/}
            {/*</div>*/}

            <GamePicker />
        </aside>
    );
}
