import {GameResult} from "../interface";
import React, {useEffect, useState} from "react";
import GraphBarCircle from "../../../../components/graph/circle";
import PerCount from "../../per_count";
import {GraphSML} from "../../../../components/graph/graph_sml";
import {getGraphDataObject} from "./game_state";

export function DhBallGameGraph({gameResults}: { gameResults: GameResult[];}) {
    const data = getGraphDataObject(gameResults)
    const powerBall = data.powerBall
    const normalBall = data.normalBall
    const sml = data.sml

    const [powerballOverUnderWin, setPowerballOverUnderWin] = useState<string>('red')
    const [normalOddEvenWin, setNormalOddEvenWin] = useState<string>('red')
    useEffect(() => {
        setPowerballOverUnderWin(powerBall.perCount.under < powerBall.perCount.over ? "red" : "blue")
        setNormalOddEvenWin(normalBall.perCount.odd < normalBall.perCount.even ? "red" : "blue")
    }, [gameResults])
    return (
        <>
            <div id={"powerball-result-graph"}>
                <div>
                    <header>일반볼 홀짝</header>
                    <main>
                        <div className={"graph-circle overunder"}>
                            <GraphBarCircle per={Number(normalBall.percent.odd)} color={normalOddEvenWin} />
                            <div className={"inner blue"}>
                                <div>
                                    <p className={"item-tit " + (normalOddEvenWin == "blue" ? normalOddEvenWin : "default")}>홀</p>
                                    <p className={"item-contents"}>
                                        <span>{normalBall.perCount.odd}번</span>
                                        <PerCount count={normalBall.percent.odd} />
                                    </p>
                                </div>
                                <div>
                                    <p className={"item-tit " + (normalOddEvenWin == "red" ? normalOddEvenWin : "default")}>짝</p>
                                    <p className={"item-contents"}>
                                        <span>{normalBall.perCount.even}번</span>
                                        <PerCount count={normalBall.percent.even} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div>
                    <header>파워볼 언더오버</header>
                    <main>
                        <div className={"graph-circle overunder"}>
                            <GraphBarCircle per={Number(powerBall.percent.under)} color={powerballOverUnderWin} />
                            <div className={"inner red"}>
                                <div>
                                    <p className={"item-tit " + (powerballOverUnderWin == "blue" ? powerballOverUnderWin : "default")}>언더</p>
                                    <p className={"item-contents"}>
                                        <span>{powerBall.perCount.under}번</span>
                                        <PerCount count={powerBall.percent.under} />
                                    </p>
                                </div>
                                <div>
                                    <p className={"item-tit " + (powerballOverUnderWin == "red" ? powerballOverUnderWin : "default")}>오버</p>
                                    <p className={"item-contents"}>
                                        <span>{powerBall.perCount.over}번</span>
                                        <PerCount count={powerBall.percent.over} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div>
                    <main>
                        <div className={"graph-circle l-m-s"}>
                            <div className={"inner"}></div>
                            <GraphSML s={sml.percent.s} m={sml.percent.m} l={sml.percent.l} />
                        </div>

                        <div className={"graph-count"}>
                            <div>
                                <span className={"--color-small"}>●</span>
                                <p>소 - {sml.perCount.s} <PerCount count={sml.percent.s} /> </p>
                            </div>
                            <div>
                                <span className={"--color-medium"}>●</span>
                                <p>중 - {sml.perCount.m} <PerCount count={sml.percent.m} /> </p>
                            </div>
                            <div>
                                <span className={"--color-large"}>●</span>
                                <p>대 - {sml.perCount.l} <PerCount count={sml.percent.l} /> </p>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}