import React, { useEffect, useState } from 'react';
import { redirect, useNavigate } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import useUser from 'src/hooks/useUser';
import getCookie from 'src/utils/getCookie';


interface DeleteError {

}

export default function DeletePage() {
    const { user, mutateUser } = useUser({ loginRequired: true })
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/api/csrf-token');
    }, [])

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<DeleteError>();

    return (<Button onClick={async e => {
        if (window.confirm('아이디를 삭제하시겠습니까?')) {
            setSubmitting(true);
            const response = await fetch('/api/user/delete', {
                method: "POST",
                headers: { 'X-CSRFToken': getCookie('csrftoken')!, },
            })
            setSubmitting(false);
            if (response.ok) {
                mutateUser();
                navigate('/user/login');
            } else {
                const errorData: DeleteError = await response.json()
                setError(errorData);
            }
        }
    }}>계정 삭제</Button>);
}