export type MinigameType = "happyball" | "happyball3min" | "eos5" | "eos3" | "pbg5" | "happyball_ladder" | "happyball3min_ladder" | "ntry" | "ntry_ladder" | "dh"
export const MinigameTypes: MinigameType[] = ["happyball" , "eos5" , "eos3" , "pbg5" , "happyball_ladder" , "ntry" , "ntry_ladder" , "dh"]
export const MinigameTypeNames = {
    happyball: "해피545 5분",
    happyball3min: "해피545 3분",
    eos5: "EOS파워볼 5분",
    eos3: "EOS파워볼 3분",
    pbg5: "PBG파워볼 5분",
    happyball_ladder: "해피545 사다리 5분",
    happyball3min_ladder: "해피545 사다리 3분",
    ntry: "엔트리파워볼 5분",
    ntry_ladder: "엔트리 사다리",
    dh: "동행파워볼 5분"
}