import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {Nav} from "react-bootstrap";

export interface tab_interface  {
    eventKey: string
    text: string
    default?: boolean
    clickEvent?: Function
    accessError?: boolean
}
function BoardTab({tab_list, page}: { tab_list: tab_interface[]; page: string }) {
    const [list, setList] = useState<tab_interface[]>(tab_list)
    const [selectTab, setSelectTab] = useState<string>(tab_list.first().eventKey)
    const { type } = useParams()

    useEffect(()=>{
        let isRender = false
        if(!type){
            tab_list.forEach(val => {
                if(val.default){
                    setSelectTab(val.eventKey)
                    isRender = true
                    return;
                }
            })
        }
        if( !isRender ){
            if(type){
                setSelectTab(type)
            }else{
                const locationPath = (window.location.pathname).split('/')[2]
                if(locationPath){
                    setSelectTab(locationPath)
                }
            }
        }
    })
    return (
        <div className={"board-tab col-"+list.length}>
            {
                list.map((obj,index)=>{
                    const to_path = `/${page}/` + ( obj.eventKey ? `${obj.eventKey}/` : "")
                    let className = ""
                    if( selectTab == obj.eventKey ){
                        className = "select"
                    }
                    return (
                        <Nav.Link key={index}
                                  as={Link}
                                  to={to_path}
                                  onClick={e=>{
                                      if( obj.clickEvent ){
                                          obj.clickEvent()
                                      }
                                      if( !obj.accessError ){
                                          setSelectTab(obj.eventKey)
                                      }else{
                                          e.preventDefault()
                                          alert('준비중 입니다');
                                      }
                                  }}
                                  className={ className }
                                  eventKey={obj.eventKey}>{obj.text}</Nav.Link>
                    )
                })
            }

        </div>
    );
}

export default BoardTab;