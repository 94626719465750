import React, {useState} from 'react';
import {Link} from "react-router-dom";
import PeeksContainer from "../../components/peeks/peeks_container";
import Logo from "../../asset/images/common/peeks_logo.svg"
import User from "./user";
import NoticeLanding from "../../apps/service_center/notice/notice_landing";
import Navigation from "../navigation";

export default function GLHeader() {
    return (
        <header id={"gl__header"}>
            <PeeksContainer>
                <div className={"gl__header_info"}>
                    <div>
                        <Link to="/" className={"logo"}>
                            <img src={Logo} alt=""/>
                        </Link>

                        <NoticeLanding/>
                    </div>

                    <div>
                        <Navigation/>
                        <User/>
                    </div>
                </div>
            </PeeksContainer>
        </header>
    )
}

