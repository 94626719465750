import React, {useEffect, useRef, useState} from 'react';
import {sports_tab_type} from "../_layout";
import {isMobile} from "react-device-detect";

function GetSportsScore({sports, isLanding}: {sports: sports_tab_type; isLanding: boolean}) {
    const [ iframeHeight, setHeight ] = useState<number>(970)
    const iframeRef = useRef<HTMLIFrameElement>(null)

    let iframe_url = `/sports/${sports}.html?`
    if( isLanding ){
        iframe_url += "isLanding=1"
    }
    if( isMobile ){
        iframe_url += "&isMobile=1"
    }

    useEffect(()=> {
        if(isLanding){
            setHeight(600)
        }
    }, [isLanding])
    return (
        <div className={"sports-iframe-cover"}>
            {
                sports
                    ? <iframe id={"sports_iframe"} src={iframe_url}
                              frameBorder={0}
                              height={iframeHeight}
                              ref={iframeRef} ></iframe>
                    : <></>
            }

        </div>
    );
}
export default GetSportsScore;

GetSportsScore.defaultProps = {
    sports: "soccer",
    isLanding: false
}