import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams, redirect } from "react-router-dom";
// dummy
import img_nickname from "src/asset/images/market/Icon_Item_01.svg";
import img_one from "src/asset/images/market/Icon_Item_02.svg";
import img_profile from "src/asset/images/market/Icon_Item_00.svg";
import img_random from "src/asset/images/market/Group 72849.svg";
import { with_comma } from "../../utils/number_comma";
import Radio from "../../components/form/radio";
import api from "src/utils/api";
import useUser from "src/hooks/useUser";

interface fetch_date {
  count: number;
  results: items_data[];
}
interface items_data {
  id: number;
  name: string;
  descriptions: string;
  cash_price: number;
  point_price: number;
  bonus_point: number;
  image: string;
}

export default function MarketHomePage(props: {}) {
  const [items, setItems] = useState<items_data[]>();
  function selectListItem(index: number) {
    return index;
  }
  useEffect(() => {
    fetch("/api/market/items/")
      .then((res) => res.json())
      .then((data: fetch_date) => {
        setItems(data.results);
      })
      .catch((err) => {
        console.log(err);
        alert("오류가 발생하였습니다.");
      });
  }, []);

  return (
    <div className={"board-market"}>
      <ul>{items && items.map((item_data, index) => <ItemTemplate key={index} item_data={item_data} index={index} selectItem={selectListItem} />)}</ul>
    </div>
  );
}

type payMethod = "cash" | "star" | "point";
const PAY_METHOD: payMethod[] = ["cash", "star", "point"];

function ItemTemplate({ item_data: items_data, index, selectItem }: { item_data: items_data; index: number; selectItem: Function }) {
  const { mutateUser } = useUser();

  const data = items_data;
  const [indexState, setIndexState] = useState<boolean[]>([false, false, false]);
  const [buyState, setBuyState] = useState<boolean>(false);
  const [payMethod, setPayMethod] = useState<payMethod>();
  const [quantity, setQuantity] = useState<number>(1);

  function indexSetState(is: boolean, index: number) {
    const defaultIndexState = [false, false, false];
    defaultIndexState[index] = is;
    setIndexState(defaultIndexState);
  }

  function handlePurchase() {
    if (!payMethod) {
      return;
    }
    if (window.confirm(`${{ cash: "캐시", star: "별", point: "포인트" }[payMethod]}로 구매하시겠습니까?`)) {
      api(`/api/market/buy/${data.id}?method=${payMethod}&quantity=${quantity}`, { method: "POST" })
        .then(() => {
          mutateUser();
          alert("구매가 완료되었습니다.");
        })
        .catch((err) => {
          if (err.cash) {
            alert(err.cash);
          } else if (err.point) {
            alert(err.point);
          } else if (err.method) {
            alert(err.method);
          } else if (err.star) {
            alert(err.star);
          } else {
            alert(err);
          }
        });
    }
  }

  useEffect(() => {
    // console.log(indexState)
    if (indexState.indexOf(true) > -1) {
      const payIndex = indexState.indexOf(true);
      setPayMethod(PAY_METHOD[payIndex]);
      setBuyState(true);
      selectItem(index);
    } else {
      setBuyState(false);
    }
  }, [indexState]);

  return (
    <li className={"market-items"}>
      <header>
        <img src={data.image} alt="" />
        <strong className={"name"}>{data.name}</strong>
        <div className={"bonus"}>BONUS: {with_comma(data.bonus_point)} POINT</div>
        <div className={"descriptions"}> {data.descriptions} &nbsp;</div>
      </header>
      <main>
        <div>
          <PayStyle indexState={indexSetState} index={0} checked={payMethod === "cash"} innerText={with_comma(data.cash_price) + " 캐시"} quantity={quantity} setQuantity={setQuantity} />
        </div>
        <div>
          <PayStyle indexState={indexSetState} index={1} checked={payMethod === "star"} innerText="110 받은별" quantity={quantity} setQuantity={setQuantity} />
        </div>
        <div>
          <PayStyle indexState={indexSetState} index={2} checked={payMethod === "point"} innerText={with_comma(data.point_price) + " 포인트"} quantity={quantity} setQuantity={setQuantity} />
        </div>
      </main>
      <button className={buyState ? "active" : "disabled"} onClick={handlePurchase}>
        구매하기
      </button>
    </li>
  );
}

function PayStyle(props: { innerText: number | string; checked: boolean; indexState: Function; index: number; quantity: number; setQuantity: any }) {
  const [select, setSelect] = useState<boolean>();
  function isChecked(is: boolean) {
    props.indexState(is, props.index);
  }
  useEffect(() => {
    // console.log(props.index, props.checked)
    setSelect(props.checked);
  }, [props.checked]);

  return (
    <>
      <div className={"inner " + (select ? "checked" : "")} onClick={() => isChecked(!select)}>
        {select ? <i className={"--icon lo radio active"} /> : <i className={"--icon lo radio"} />}
        <p>{props.innerText}</p>
      </div>
      {select ? <CountTemplate quantity={props.quantity} setQuantity={props.setQuantity} /> : <></>}
    </>
  );
}

function CountTemplate({ quantity, setQuantity }: { quantity: number; setQuantity: any }) {
  function decrement() {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  }

  function increment() {
    if (quantity < 99) {
      setQuantity(quantity + 1);
    }
  }

  function inputText(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value) {
      const num = Number(e.target.value);
      if (num > 0) {
        if (num < 100) {
          setQuantity(Number(e.target.value));
        } else {
          setQuantity(99);
        }
      }
    }
  }

  return (
    <div className={"item-counter"}>
      <div onClick={decrement}>-</div>
      <div>
        <input type="number" onInput={inputText} value={quantity} min={1} max={99} />
      </div>
      <div onClick={increment}>+</div>
    </div>
  );
}
