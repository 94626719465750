import Btn from "../../../components/form/btn";
import React, {useEffect} from "react";
import {DateRange} from "./history/item_history";

export default function FriendsPage(){
    useEffect(()=>{
        alert('준비중 입니다.')
        window.history.back()
    }, [])
    return (
        <div className={"board-table board-user friend"}>
            <header>
                <MyFriend/>
                <FriendSearch/>
            </header>

            <table>
                <thead>
                <tr>
                    <th>번호</th>
                    <th>구분</th>
                    <th>닉네임</th>
                    <th>등록날짜</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>23</td>
                    <td>친구</td>
                    <td>신라면블랙</td>
                    <td>23/01/27 15:24:24</td>
                    <td>
                        <Btn>수정</Btn>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

function MyFriend(){
    return (
        <div className={"my-friend"}>
            <div>
                <strong>친구</strong>
                <p><b>10</b></p>
            </div>
            <div>
                <strong>블랙</strong>
                <p><b>1</b></p>
            </div>
            <div>
                <strong>전체 등록 인원</strong>
                <p><b>11</b> / 20</p>
            </div>
        </div>
    )
}
export function FriendSearch(){
    return (
        <div className={"board-search-form"}>
            <div>
                <label className="form-radio">
                    <input type="radio" name="type" checked readOnly={true}/>
                    <span className="btn">전체</span>
                </label>
                <label className="form-radio">
                    <input type="radio" name="type" />
                    <span className="btn">친구</span>
                </label>
                <label className="form-radio">
                    <input type="radio" name="type" />
                    <span className="btn">블랙</span>
                </label>
            </div>

            <div className={"d-flex --fl-items-center --gap-10"}>
                <label className={"form-title"}>
                    <p>닉네임</p>
                    <input name={"title"} />
                    <span className={"btn"}>검색</span>
                </label>
            </div>
        </div>
    )
}
