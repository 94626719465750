import React from 'react';
import {Outlet} from "react-router-dom";
import CategoryTab from "../../../../components/category_tab";
import {tab_interface} from "../../../../components/board/board_tab";

const board_tab_list: tab_interface[] = [
    {eventKey:"history",  text: "경험치 내역"},
    {eventKey:"info",     text: "레벨 안내"},
]
function ExpPage({ children }: { children?: React.ReactNode }) {
    return (
        <>
            <CategoryTab tab_list={board_tab_list} />
            {children ? children : <Outlet />}
        </>
    );
}

export default ExpPage;