import React from 'react';
import {
    PowerballResultTable,
    LadderResultTable,
    LadderGameStats,
    PoweballGameStats,
    DhBallResultTable, DhBallGameStats
} from "./game_state";
import {GameResult, VIEW_TYPE} from "../interface";
import {PowerballGameGraph} from "./game_graph_poweball";
import {LadderGameGraph} from "./game_graph_ladder";
import {DhBallGameGraph} from "./game_graph_dh";

function GameStateLayout({gameResults, viewType}: {gameResults: GameResult[]; viewType: VIEW_TYPE}) {
    return (
        viewType != 'ladder'
            ? <PowerballLayout gameResults={gameResults} viewType={viewType} />
            : <LadderLayout gameResults={gameResults} />
    );
}

export default GameStateLayout;

function PowerballLayout({gameResults, viewType}: {gameResults: GameResult[]; viewType: VIEW_TYPE}){
    return (
        <>
            {
                gameResults.first().kind == "dh"
                    ? <>
                        <DhBallGameStats gameResults={gameResults} viewType={viewType}/>
                        <DhBallGameGraph gameResults={gameResults} />
                        <DhBallResultTable gameResults={gameResults} />
                      </>
                    : <>
                        <PoweballGameStats gameResults={gameResults} viewType={viewType} />
                        <PowerballGameGraph gameResults={gameResults} />
                        <PowerballResultTable gameResults={gameResults} />
                      </>
            }
        </>
    )
}
function LadderLayout({gameResults}: {gameResults: GameResult[];}){
    return (
        <>
            <LadderGameStats gameResults={gameResults} />
            <LadderGameGraph gameResults={gameResults} />
            <LadderResultTable gameResults={gameResults} />
        </>
    )
}