import { Pagination } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function PaginationComponent({ totalCount, perPage = 20 }: { totalCount: number | undefined; perPage?: number }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageNum = Number(searchParams.get("page")) || 1;

  let items = [];
  if (!totalCount) {
    totalCount = 1;
  }
  for (let i = Math.max(1, currentPageNum - 4); i < Math.min(totalCount / perPage + 1, currentPageNum + 5); i++) {
    items.push(
      <Pagination.Item
        key={i}
        active={i === currentPageNum}
        onClick={(e) =>
          setSearchParams((searchParams) => {
            searchParams.set("page", i.toString());
            return searchParams;
          })
        }
      >
        {i}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      {isMobile ? (
        <></>
      ) : (
        <Pagination.Prev
          disabled={currentPageNum <= 1}
          onClick={() =>
            setSearchParams((searchParams) => {
              searchParams.set("page", (currentPageNum - 1).toString());
              return searchParams;
            })
          }
        />
      )}
      {items}
      {isMobile ? (
        <></>
      ) : (
        <Pagination.Next
          disabled={currentPageNum >= totalCount / perPage}
          onClick={() =>
            setSearchParams((searchParams) => {
              searchParams.set("page", (currentPageNum + 1).toString());
              return searchParams;
            })
          }
        />
      )}
    </Pagination>
  );
}

export function OldPaginationComponent({ currentPage, count, perPage }: { currentPage: number; count: number; perPage: number }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  let items = [];
  for (let i = Math.max(1, currentPage - 4); i < Math.min(count / perPage + 1, currentPage + 5); i++) {
    items.push(
      <Pagination.Item key={i} active={i === currentPage} onClick={(e) => setSearchParams({ page: i.toString() })}>
        {i}
      </Pagination.Item>
    );
  }
  return (
    <Pagination>
      <Pagination.Prev disabled={currentPage <= 1} onClick={(e) => navigate(`?page=${currentPage - 1}`)} />
      {items}
      <Pagination.Next disabled={currentPage >= count / perPage} onClick={() => navigate(`?page=${currentPage + 1}`)} />
    </Pagination>
  );
}
