import { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import api from "src/utils/api"

interface ExpHistoryApi {
    count: number
    results: ExpHistory[]
}

interface ExpHistory {
    amount: number,
    explanation: string,
}

export default function ExpHistoryPage() {
    const [expHistories, setExpHistories] = useState<ExpHistory[]>()

    useEffect(() => {
        api('/api/user/exp-history/')
            .then(res => res.json())
            .then((data: ExpHistoryApi) => {
                setExpHistories(data.results)
            })
            .catch(err => alert(JSON.stringify(err)))
    }, [])

    return <div>
        <h2>포인트 내역</h2>
        <Table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>변동 포인트</th>
                    <th>변동 사유</th>
                </tr>
            </thead>
            <tbody>
                {expHistories?.map((expHistory, index) =>
                    <tr key={index}>
                        <td>#</td>
                        <td key="1">{expHistory.amount}</td>
                        <td key="2">{expHistory.explanation}</td>
                    </tr>)}
            </tbody>
        </Table>
    </div>

}