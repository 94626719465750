import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import useUser from "../hooks/useUser";
import {isMobile} from "react-device-detect";
import LayoutHeader from "../components/layout/layout_header";
import User from "./mo/user";
import {minigameTabList} from "../apps/minigame/tab";
import {board_tab_list as community_tab_list} from "../apps/community/_layout";
import {board_tab_list as rank_tab_list} from "../apps/ranking/_layout";
import {board_tab_list as market_tab_list} from "../apps/market/_layout";
import {board_tab_list as service_tab_list} from "../apps/service_center/_layout";
import {board_tab_list as mypage_tab_list} from "../apps/user/_layout";
import Btn from "../components/form/btn";
import {LoginPageModal} from "./mo/ml_header";

export default function Navigation({moClose}: {moClose: any}) {
    return isMobile ? <Mobile moClose={moClose}/> : <Desktop/>
}
Navigation.defaultProps = {
    moClose: undefined
}

function Desktop(){
    const {user, isLoading} = useUser();
    return (
        <nav className={"gl__navigation"}>

            <Link to="/home">홈</Link>
            <Link to="/minigame/happyball">미니게임</Link>
            <Link to="/sports/">스포츠</Link>
            <Link to="/community/all/">커뮤니티</Link>
            <Link to="/ranking/exp/">랭킹</Link>
            {/*<Link to="/room/">채팅방</Link>*/}
            <Link to="/market/">마켓</Link>
            <Link to="/service_center/notices/">고객센터</Link>
            {!isLoading && user?.is_authenticated
                ? <Link to="/user/profile">마이페이지</Link>
                : <></>
            }
        </nav>
    )
}

function Mobile({moClose}: {moClose: any}){
    const [loginShow, setLoginShow] = useState<boolean>(false)
    const navigate = useNavigate()
    const {user} = useUser()

    function move(location: string){
        navigate(location)
        moClose()
    }
    return (
        <>
            <header>
                <div className={"navigation-user-info"}>
                    {
                        user?.is_authenticated
                            ? <>
                                <div className={"profile"}>
                                    { user.profile_image ? <img src={user.profile_image} alt="profile"/> : <></> }
                                </div>
                                <User move={move}/>
                              </>
                            : <> <Btn onClick={e=>setLoginShow(true)} className={"login-btn"}>로그인</Btn> <LoginPageModal registerOn={false} show={loginShow} setShow={setLoginShow}/> </>
                    }
                </div>
            </header>
            <main>
                <div>
                    <LayoutHeader tab={"home"} onClick={e=> move(`/home/`)}/>
                </div>
                <div>
                    <LayoutHeader tab={"sports"} onClick={e=> move(`/sports/`)}/>
                </div>
                <div>
                    <LayoutHeader tab={"minigame"}/>
                    <ul>
                        {
                            minigameTabList.map((nav, index)=>(
                                <li key={index} onClick={e=> move(`/minigame/${nav.eventKey}`)}>{nav.text}</li>
                            ))
                        }
                    </ul>
                </div>
                <div>
                    <LayoutHeader tab={"community"}/>
                    <ul>
                        {
                            community_tab_list.map((nav, index)=>(
                                <li key={index} onClick={e=> move(`/community/${nav.eventKey}/`)}>{nav.text}</li>
                            ))
                        }
                    </ul>
                </div>
                <div>
                    <LayoutHeader tab={"rank"}/>
                    <ul>
                        {
                            rank_tab_list.map((nav, index)=>(
                                <li key={index} onClick={e=> move(`/ranking/${nav.eventKey}/`)}>{nav.text}</li>
                            ))
                        }
                    </ul>
                </div>
                {/*<div>*/}
                {/*    <LayoutHeader tab={"chat"}/>*/}
                {/*    <ul>*/}
                {/*        <li onClick={e=> move(`/room/`)}>채팅방</li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
                <div>
                    <LayoutHeader tab={"market"}/>
                    <ul>
                        {
                            market_tab_list.map((nav, index)=>(
                                <li key={index} onClick={e=> move(`/market/${nav.eventKey}/`)}>{nav.text}</li>
                            ))
                        }
                    </ul>
                </div>
                <div>
                    <LayoutHeader tab={"customer_support"}/>
                    <ul>
                        {
                            service_tab_list.map((nav, index)=>(
                                <li key={index} onClick={e=> move(`/service_center/${nav.eventKey}/`)}>{nav.text}</li>
                            ))
                        }
                    </ul>
                </div>

                {
                    user?.is_authenticated
                        ? <div>
                            <LayoutHeader tab={"my_page"}/>
                            <ul>
                                {
                                    mypage_tab_list.map((nav, index)=>(
                                        <li key={index} onClick={e=> {
                                            if( !user?.is_authenticated ){
                                                alert('로그인 후 이용 가능합니다.');
                                            }else{
                                                move(`/user/${nav.eventKey}/`)
                                            }
                                        }}>{nav.text}</li>
                                    ))
                                }
                            </ul>
                          </div>
                        : <></>
                }

            </main>
        </>
    )
}