

export function with_comma(numbers: number){
    // 숫자 콤마 추가
    if( (/^[0-9\,\-\.]+$/).test(`${numbers}`) == false || numbers == 0 ){
        return 0;
    }else{
        let n = (numbers + '');
        const reg = /(^[+-]?\d+)(\d{3})/;
        while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');

        return n;
    }
}
export function del_comma(numbers: number){
    // 숫자 콤마 제거
    if( (/^[0-9\,]+$/).test(`${numbers}`) == false || numbers == 0 ){
        return numbers;
    }else{
        const reg = new RegExp(/\,/gi);
        return Number(numbers.toString().replace(reg, ""));;
    }
}