import React, {ReactElement} from 'react';
import logo from "../../../asset/images/common/peeks_logo.svg";

export default function PointChangeTemplate(props: { children: React.ReactNode }) {
    return (
        <div className={"login-form exp-change-form"}>
            <header>
                <p className={"--font-16 --bold"}>포인트 경험치전환</p>

                 <div className={"warning"}>
                     <header>포인트 경험치 전환 안내</header>
                     <main>커뮤니티 활동 및 아이템 구매 시 획득한 포인트를 경험치로 전환 할 수 있습니다. <br/>
                         전환된 경험치는 포인트로 되돌릴 수 없습니다. <br/>
                         1포인트당 1경험치로 전환됩니다.</main>
                 </div>
            </header>
            <main>{props.children}</main>
        </div>
    );
}
