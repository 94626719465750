import { FormEvent, useContext, useEffect, useState } from "react";
import { getStringMinute, MinigameContext, MiniGameDelay } from "src/contexts/minigameProvider";
import api from "src/utils/api2";
import useUser from "../hooks/useUser";
import { transMinutesCount } from "../apps/minigame/happyball/game/game_board_timer_circle";
import { useInterval } from "usehooks-ts";
import {MinigameType, MinigameTypeNames} from "../types/minigame";
import { GAME_COUNT_TYPE, GAME_LAST_ROUND } from "../apps/minigame/happyball/interface";
import moment from "moment";
import { UserSocketContext } from "src/contexts/userSocketProvider"
export interface HappyballPickType {
    game_pick: GAME_PICK_VALUE_TYPE
    id: number
    round_daily: number
    round_date: string
    user: string
}
export type GAME_PICK_VALUE_TYPE = "" | "POWERBALL_ODD" | "POWERBALL_EVEN" | "POWERBALL_UNDER" | "POWERBALL_OVER" |
    "BALLS_ODD" | "BALLS_EVEN" | "BALLS_UNDER" | "BALLS_OVER" |
    "BALLS_SMALL" | "BALLS_MEDIUM" | "BALLS_LARGE"
type LADDER_PICK_VALUE_TYPE = "" | "LEFT" | "RIGHT" | "THREE" | "FOUR" | "ODD" | "EVEN" | "LEFT_FOUR_ODD" | "LEFT_THREE_EVEN" | "RIGHT_THREE_ODD" | "RIGHT_FOUR_EVEN"
export const GAME_PICK_TEXT: any = {
    POWERBALL_ODD: "파워볼 홀수",
    POWERBALL_EVEN: "파워볼 짝수",
    POWERBALL_UNDER: "파워볼 언더",
    POWERBALL_OVER: "파워볼 오버",
    BALLS_ODD: "일반볼 홀수",
    BALLS_EVEN: "일반볼 짝수",
    BALLS_UNDER: "일반볼 언더",
    BALLS_OVER: "일반볼 오버",
    BALLS_SMALL: "일반볼 소",
    BALLS_MEDIUM: "일반볼 중",
    BALLS_LARGE: "일반볼 대",
    LEFT: "좌",
    RIGHT: "우",
    THREE: "3줄",
    FOUR: "4줄",
    ODD: "홀",
    EVEN: "짝",
    LEFT_FOUR_ODD: "좌4",
    LEFT_THREE_EVEN: "좌3",
    RIGHT_THREE_ODD: "우3",
    RIGHT_FOUR_EVEN: "우4",
}
interface ErrorType {
    round_date?: string[]
    round_daily?: string[]
    user?: string[]
}
function getNextRoundLeftTime(termSeconds: number) {
    return 1000 * termSeconds - new Date().valueOf() % (1000 * termSeconds)
}

export function getNowRound(time: Date, gameType: MinigameType) {
    const game_count_minute = GAME_COUNT_TYPE[gameType]
    const draw_for_hour = 60 / game_count_minute
    let setRound = Math.floor(time.getHours() * draw_for_hour + time.getMinutes() / game_count_minute) + 1

    if (setRound > GAME_LAST_ROUND[gameType]) {
        setRound = 1
    }

    return setRound
}
export default function GamePicker() {
    const { gameType } = useContext(MinigameContext)
    const gameLabel = MinigameTypeNames[gameType]
    const { user } = useUser()

    // FIXME 로컬 타임을 timezone aware로 변경해야한다.
    const [time, setTime] = useState(new Date())
    const [round, setRound] = useState<number>(getNowRound(time, gameType))
    const [pickBlock, setPickBlock] = useState<boolean>(false)
    const [contextPickBlock, setContextPickBlock] = useState<boolean>(false)
    const { gamePickBlock } = useContext(MinigameContext)


    useEffect(() => {
        setTime(new Date)
        setRound(getNowRound(new Date, gameType))
    }, [gameType])
    useEffect(() => {
        setRound(getNowRound(new Date, gameType))
        const timer = setTimeout(() => {
            setTime(new Date)
        }, getNextRoundLeftTime(GAME_COUNT_TYPE[gameType] * 60) - (MiniGameDelay[gameType]))

        return () => {
            clearTimeout(timer)
        }
    }, [time, gameType, user])
    useEffect(() => {
        setContextPickBlock(gamePickBlock)
    }, [gamePickBlock])

    useInterval(() => {
        if (gameType == 'dh') {
            const date = new Date()
            if (date.getHours() < 6) {
                setRound(73)
                setPickBlock(true)
            } else {
                setPickBlock(false)
            }
        }
    }, 1000)
    return (
        <div className="game-pick">
            <header>
                <span>{gameLabel}&nbsp;<b className={"--color-red"}>{round}</b>&nbsp;라운드 유저픽 등록</span>
                <PickTimers gameType={gameType} setPickBlock={setPickBlock} />
            </header>
            <PickComponent round={round} gameType={gameType} pickBlock={pickBlock} contextPickBlock={contextPickBlock} />
        </div>
    )
}

function PickComponent({ round, gameType, pickBlock, contextPickBlock }: { round: number; gameType: MinigameType; pickBlock: boolean; contextPickBlock: boolean }) {
    const [error, setError] = useState<ErrorType>()
    const [chosenPick, setChosenPick] = useState<HappyballPickType>()
    const round_date = moment().format('YYYY-MM-DD')
    const { user } = useUser()

    const { userSocketRef } = useContext(UserSocketContext)

    useEffect(() => {
        if (userSocketRef.current) {
            userSocketRef.current.addEventListener("message", (event) => {
                const message = JSON.parse(event.data)
                switch (message.type) {
                    case "pick":
                        console.log(message)
                        if(message.pick.kind.toLocaleLowerCase() == gameType){
                            setError(undefined)
                            setChosenPick(message.pick);
                        }
                        break;
                }
            })
        }
    }, [userSocketRef.current])

    function handleCreatePick(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const formData = new FormData(e.currentTarget)
        const user_pick = `${formData.get('game_pick')}`
        /** 픽 등록 */
        if (!user || !user.is_authenticated) {
            alert('로그인 후 이용 가능합니다.')
        } else {
            if (!user_pick) {
                alert('선택된 픽이 없습니다.')
            } else {
                formData.set("round_daily", round.toString())
                formData.set("round_date", round_date)
                api(`/api/gamepick/${gameType}/`, { method: "POST", body: formData })
                    .then(res => res.json())
                    .then((data: HappyballPickType) => {
                        setError(undefined)
                        setChosenPick(data)
                    })
                    .catch(e => handleError(e))
                // if (window.confirm(`'${GAME_PICK_TEXT[user_pick]}' 픽을 등록하겠습니까?`)) {
                // }
            }
        }
    }
    async function handleError(res: Response) {
        if (res.headers.get('content-type') !== 'application/json') {
            return alert("오류가 발생하였습니다.")
        }
        const error: ErrorType = await res.json()
        if (error.round_date) {
            return alert(error.round_date)
        } else if (error.round_daily) {
            return alert(error.round_daily)
        } else if (error.user) {
            return alert(error.user)
        }
    }
    function fetchChosenApi() {
        // console.log(`/api/gamepick/${gameType}/${round_date}/${round}/mine/`)
        if (user?.is_authenticated) {
            if (getNowRound(new Date, gameType) == round) {
                api(`/api/gamepick/${gameType}/${round_date}/${round}/mine/`)
                    .then(res => res.json())
                    .then((data: HappyballPickType) => setChosenPick(data))
                    .catch(e => setChosenPick(undefined))
            }
        }
    }

    useEffect(() => {
        fetchChosenApi()
        return () => setChosenPick(undefined)
    }, [gameType, round])

    return (
        chosenPick
            ? <PickChosen round={round} game_pick={chosenPick.game_pick} />
            : pickBlock || contextPickBlock
                ? <PickBlock />
                : <PickForm onSubmit={handleCreatePick} gameType={gameType} />
    )
}

function PickBlock() {
    return (
        <>
            <main className={"pick-block-main"}>유저픽 등록 시간이 마감되었습니다.</main>
            <footer>
                <button className={"btn btn-gray --disabled"}>유저픽 등록</button>
            </footer>
        </>
    )
}
function PickChosen({ round, game_pick }: { round: number; game_pick: GAME_PICK_VALUE_TYPE }) {
    const pick_type = GAME_PICK_TEXT[game_pick].split(' ')[0]
    const pick_value = GAME_PICK_TEXT[game_pick].split(' ')[1]
    return (
        <>
            <main className={'pick-chosen-main'}>
                <p>{round} 회차 유저픽이 등록되었습니다.</p>
                <div>
                    {
                        pick_value
                            ? <>
                                <span>{pick_type}</span>
                                <span className={game_pick.split('_')[1]}>{pick_value}</span>
                            </>
                            : <>
                                <span>사다리</span>
                                <span className={game_pick}>{pick_type}</span>
                            </>
                    }
                </div>
            </main>
            <footer>
                <button className={"btn btn-gray --disabled"}>유저픽 등록</button>
            </footer>
        </>
    )
}
function PickTimers({gameType, setPickBlock}: {gameType: MinigameType; setPickBlock: any}){
    const [timer, setTimer] = useState<string>("00:00")

    useInterval(()=> {
        const gameCount = getStringMinute(gameType)
        if(gameCount < 31) {
            setPickBlock(true)
        } else {
            setPickBlock(false)
        }

        setTimer(transMinutesCount(gameCount))
    }, 1000)
    return (
        <span>{timer}</span>
    )
}
function PickForm({ onSubmit, gameType }: { onSubmit: any; gameType: MinigameType }) {
    const [select, setSelect] = useState<GAME_PICK_VALUE_TYPE>("")

    const isLadder = gameType.split('_')[1]
    return (
        <form onSubmit={onSubmit} >
            {
                isLadder == 'ladder'
                    ? <LadderPickLabels setSelect={setSelect} />
                    : gameType == 'dh'
                        ? <DhBallPickLabels setSelect={setSelect} />
                        : <PowerballPickLabels setSelect={setSelect} />
            }
            <footer>
                <button type="submit" className={`${select ? "btn btn-theme2" : "btn btn-gray --disabled"}`}>유저픽 등록</button>
            </footer>
        </form>
    )
}
function LadderPickLabels({ setSelect }: { setSelect: any }) {
    return (
        <main className={"pick-form-main"}>
            <div className={"powerball"}>
                <p>출발 / 줄수</p>
                <div className={"cell col4"}>
                    <PickLabel setSelect={setSelect} text={"좌"} val={'LEFT'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={"우"} val={'RIGHT'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={"3"} val={'THREE'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={"4"} val={'FOUR'} isLadder={true} />
                </div>
            </div>
            <div className={"normal"}>
                <p>결과</p>
                <div className={"cell  col2"}>
                    <PickLabel setSelect={setSelect} text={'홀'} val={'ODD'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={'짝'} val={'EVEN'} isLadder={true} />
                </div>
            </div>
            <div className={"normal"}>
                <p>출발 + 줄수</p>
                <div className={"cell col4"}>
                    <PickLabel setSelect={setSelect} text={'좌3'} val={'LEFT_THREE_EVEN'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={'좌4'} val={'LEFT_FOUR_ODD'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={'우3'} val={'RIGHT_THREE_ODD'} isLadder={true} />
                    <PickLabel setSelect={setSelect} text={'우4'} val={'RIGHT_FOUR_EVEN'} isLadder={true} />
                </div>
            </div>
        </main>
    )
}
function PowerballPickLabels({ setSelect }: { setSelect: any }) {
    return (
        <main className={"pick-form-main"}>
            <div className={"powerball"}>
                <p>파워볼</p>
                <div className={"cell col4"}>
                    <PickLabel setSelect={setSelect} text={"홀"} val={'POWERBALL_ODD'} />
                    <PickLabel setSelect={setSelect} text={"짝"} val={'POWERBALL_EVEN'} />
                    <PickLabel setSelect={setSelect} text={"언더"} val={'POWERBALL_UNDER'} />
                    <PickLabel setSelect={setSelect} text={"오버"} val={'POWERBALL_OVER'} />
                </div>
            </div>
            <div className={"normal"}>
                <p>일반볼</p>
                <div className={"cell col4"}>
                    <PickLabel setSelect={setSelect} text={'홀'} val={'BALLS_ODD'} />
                    <PickLabel setSelect={setSelect} text={'짝'} val={'BALLS_EVEN'} />
                    <PickLabel setSelect={setSelect} text={'언더'} val={'BALLS_UNDER'} />
                    <PickLabel setSelect={setSelect} text={'오버'} val={'BALLS_OVER'} />
                </div>
                <div className={"cell col3"}>
                    <PickLabel setSelect={setSelect} text={'소'} val={'BALLS_SMALL'} />
                    <PickLabel setSelect={setSelect} text={'중'} val={'BALLS_MEDIUM'} />
                    <PickLabel setSelect={setSelect} text={'대'} val={'BALLS_LARGE'} />
                </div>
            </div>
        </main>
    )
}
function DhBallPickLabels({ setSelect }: { setSelect: any }) {
    return (
        <main className={"pick-form-main"}>
            <div className={"powerball"}>
                <p>파워볼</p>
                <div className={"cell col2"}>
                    <PickLabel setSelect={setSelect} text={"언더"} val={'POWERBALL_UNDER'} />
                    <PickLabel setSelect={setSelect} text={"오버"} val={'POWERBALL_OVER'} />
                </div>
            </div>
            <div className={"normal"}>
                <p>일반볼</p>
                <div className={"cell col2"}>
                    <PickLabel setSelect={setSelect} text={'홀'} val={'BALLS_ODD'} />
                    <PickLabel setSelect={setSelect} text={'짝'} val={'BALLS_EVEN'} />
                </div>
                <div className={"cell col3"}>
                    <PickLabel setSelect={setSelect} text={'소'} val={'BALLS_SMALL'} />
                    <PickLabel setSelect={setSelect} text={'중'} val={'BALLS_MEDIUM'} />
                    <PickLabel setSelect={setSelect} text={'대'} val={'BALLS_LARGE'} />
                </div>
            </div>
        </main>
    )
}
function PickLabel({ text, val, setSelect, isLadder }: { text: string, val: GAME_PICK_VALUE_TYPE | LADDER_PICK_VALUE_TYPE; setSelect: any; isLadder: boolean }) {
    let addClass: string = ""
    if (isLadder) {
        addClass = val
    } else {
        addClass = val.split('_')[1]
    }
    return (
        <label onClick={e => setSelect(val)}>
            <input type="radio" name={"game_pick"} value={val} />
            <span className={`pick ${addClass}`}>{text}</span>
        </label>
    )
}
PickLabel.defaultProps = {
    isLadder: false
}

