import React, {useContext, useEffect, useRef, useState} from "react"
import dayjs from "dayjs";
/** conponent */
/** interface */
import {
    DEFAULT_STATS_TYPE, FETCH_RESULT_STATUS_TYPE, GAME_COUNT_TYPE, GAME_FETCH_DELAY, VIEW_TYPE,
    GAME_TYPE_OPTIONS,
    GAME_TYPES,
    GameResult,
    GameResultData, GameResultStats,
    GameType, GameUserPick,
    LABELS,
    STANDARS, LADDER_RESULT_TYPES, LADDER_RESULT_TYPE_OPTIONS, GAME_LAST_ROUND
} from "./interface";
/** css */
import "./happyBallRillGame.scss"
import Flex from "../../../components/layout/flex";
import {isMobile} from "react-device-detect";
import {useParams} from "react-router-dom";
/** image */
import empty_logo from '../asset/si-glyph-cone.png'
import moment from "moment";
import Btn from "../../../components/form/btn";
import {getStringMinute, MinigameContext, MinigameContextType} from "../../../contexts/minigameProvider";
import api from "../../../utils/api";
import {GAME_PICK_TEXT} from "../../../components/gamePicker";
import UserLevelNamecard from "../../../components/user/user_level_namecard";
import Icon from "../../../components/icon/icon";
import {useInterval} from "usehooks-ts";
import {MinigameType} from "../../../types/minigame";
import {PowerballResultTable} from "./graph/game_state";
import {PowerballGameGraph} from "./graph/game_graph_poweball";
import {LadderGameGraph} from "./graph/game_graph_ladder";
import GameStateLayout from "./graph/_layout";
import {DhBallGameGraph} from "./graph/game_graph_dh";

export default function HappyballPowerBall ({isLanding=false, gameFetchKey, viewType}: {isLanding? :boolean; gameFetchKey: string; viewType: VIEW_TYPE}) {
    const [fetchResultStatus, setFetchResultStatus] = useState<FETCH_RESULT_STATUS_TYPE>("NORMAL")
    const [blockPage, setBlock] = useState<boolean>(false)
    const [gameResults, setGameResults] = useState<GameResult[]>();
    const [kind, setKind] = useState<string>("");
    const {type} = useParams()
    const {SetGamePickBlock} = useContext(MinigameContext)
    // const fetch_url = "/api/happyball/results/"
    // const iframe_url = "/game/"
    const fetch_url = `/api/game/${gameFetchKey.split('_')[0]}/results/`
    const iframe_url = `/${gameFetchKey.split('_')[0]}/${viewType}/`

    useEffect(() => {
        fetch(fetch_url)
            .then(async response => {
                if (response.ok) {
                    const data = await response.json();
                    setFetchResultStatus(data.status)
                    setGameResults(data.results);
                    setKind(data.results.first().kind);
                } else {
                    console.log('게임 결과 fetch 실패');
                    // alert("게임을 불러 올 수 없습니다.")
                    return
                }
            })
            .catch((e) => {
                console.log(e)
            })
    }, [type, gameFetchKey, kind])

    function fetchResults(): any {
        if (!gameResults) {
            return
        }
        // console.log(gameResults.last())
        // 현재 state에 포함된 가장 마지막 라운드 이후 데이터를 요청
        const getRound = gameResults[0].round_daily == GAME_LAST_ROUND[gameFetchKey] ? 1 : gameResults[0].round_daily + 1
        fetch(`${fetch_url}?date=${moment().format('YYYY-MM-DD')}&daily=${getRound}`)
            .then(async response => {
                if (response.ok) {
                    const data = await response.json();
                    setFetchResultStatus(data.status)
                    let fetchedResults = data.results;
                    if (fetchedResults.length > 0) {
                        setGameResults(fetchedResults.concat(gameResults.slice(0, -fetchedResults.length)));
                    }
                } else {
                    console.log('게임 결과 fetch 실패');
                }
            })
    }

    // 결과 값이 추가될 때마다 다음 결과값 요청을 위한 세팅
    // 다음 라운드 결과가 나올 때까지 기다렸다가 (getLeftTime())
    // 매 5초마다 요청(성공 시(gameResults가 바뀌면) clearInterval)
    useEffect(() => {
        if (!gameResults) {
            return
        }
        let intervalId: NodeJS.Timer
        let timeoutId = setTimeout(() => {
            fetchResults();
            intervalId = setInterval(fetchResults, 1000)
        }, getLeftTime(gameResults[0]?.game_draw_dt, gameResults[0]?.kind))

        if(fetchResultStatus == 'MAINTENANCE'){
            SetGamePickBlock(true)
            setBlock(true)
        }else if(fetchResultStatus == 'NORMAL'){
            SetGamePickBlock(false)
            setBlock(false)
        }

        return () => {
            clearTimeout(timeoutId)
            clearInterval(intervalId)
        }

    }, [gameResults, type, kind])
    if (gameResults && gameFetchKey.split('_')[0] == gameResults.first().kind) {
        // console.log(gameFetchKey, gameResults.first().kind)
        return (
                <div id={"happyball-layout"}>
                    {
                        blockPage ? <BlockMinigame/>
                                  : !isLanding ? <>
                                                    {
                                                        isMobile ? <iframe src={iframe_url} width="840" height={"750"} frameBorder="0" style={{ maxWidth: "100%" }} allow="clipboard-read; clipboard-write"></ iframe>
                                                            : <iframe src={iframe_url} width="840" height={"600"} frameBorder="0" allow="clipboard-read; clipboard-write"></ iframe>

                                                    }
                                                    <GameCellLayout gameResults={gameResults} viewType={viewType}/>
                                                </>

                                                : <> {/* 랜딩 페이지용 일부만 표기 */}
                                                    <div id="powerball-cell-layout" className={"landing"}>
                                                        <PowerballResultTable gameResults={gameResults} />
                                                    </div>
                                                </>
                    }
                </div>
            )
    } else {
        return <div>로딩중</div>
    }
}

type SUB_TAB = "result" | "user_pick"
function GameCellLayout({gameResults, viewType}: {gameResults: GameResult[]; viewType: VIEW_TYPE}){
    const [tab, setTab] = useState<SUB_TAB>("result")
    
    return (
        <div id="powerball-cell-layout">
            <ul className={"powerball-tab"}>
                <li onClick={e=> setTab('result')} className={ tab == 'result' ? "select" : "" }>
                    <span>최근분석데이터</span>
                </li>
                <li onClick={e=> setTab('user_pick')} className={ tab == 'user_pick' ? "select" : "" }>
                    <span>유저픽</span>
                </li>
            </ul>
            {
                tab == 'result' 
                    ? <GameStateLayout gameResults={gameResults} viewType={viewType}/>
                    : <CellUserPick gameResults={gameResults}/>
            }
        </div>
    )
}
function CellUserPick({gameResults}: {gameResults: GameResult[]}){
    const first = gameResults.first()
    const defaultRoundSet = first.round_daily + 1
    const defaultDate = moment().format('YYYY-MM-DD')

    const [pickData, setPickData] = useState<GameUserPick[]>()
    const [optionLength, setOptionLength] = useState<number>(defaultRoundSet)
    const [selectRound, setSelectRound] = useState<number>(defaultRoundSet)
    const [roundDate, setRoundDate] = useState<string>(defaultDate)

    const {gameType} = useContext(MinigameContext)

    const selectRef = useRef<HTMLSelectElement>(null)
    const dateRef = useRef<HTMLInputElement>(null)

    function fetchNowRound(){
        setOptionLength(defaultRoundSet)
        setSelectRound(defaultRoundSet)
        setRoundDate(defaultDate)
        if(selectRef.current){
            selectRef.current.value = String(defaultRoundSet)
        }
        if(dateRef.current){
            dateRef.current.value = moment().format('YYYY-MM-DD')
        }
    }

    function fetchUserPickList(){
        const max_round = GAME_LAST_ROUND[gameType]
        api(`/api/gamepick/${gameType}/${roundDate}/${selectRound}/`)
            .then(res => res.json())
            .then(res => {
                // console.log(res)
                setPickData(res)
                if(defaultDate != roundDate){
                    setOptionLength(max_round)
                }else{
                    setOptionLength(defaultRoundSet)
                }
            })
    }
    useInterval(()=> {
        fetchUserPickList()
    }, 5000)
    useEffect(()=>{
        fetchNowRound()
        fetchUserPickList()
    }, [gameResults])
    useEffect(()=> {
        fetchUserPickList()
    }, [selectRound, roundDate])
    useEffect(()=> {

    }, [pickData])
    return (
        <>
            {
                isMobile ? <MobileUserPickButton /> : <></>
            }
            <div className={"user-pick-search-form"}>
                <select name="round" ref={selectRef} onChange={e=> setSelectRound(Number(e.target.value)) }>
                    {
                        [...Array(optionLength)].map((_, index)=> {
                            const round = optionLength-index
                            return (
                                <option key={index} value={round} >{round} 회차</option>
                            )
                        })
                    }
                </select>
                <input type="date" ref={dateRef}
                       onChange={e=> setRoundDate(e.target.value) }
                       defaultValue={moment(first.game_draw_dt).format('YYYY-MM-DD')}/>
                <Btn type={"default"} onClick={fetchNowRound}>현재회차</Btn>
            </div>
            {
                gameType.split('_')[1] == 'ladder' ? <LadderGameGraph gameResults={gameResults} /> : <></>
            }
            {
                gameType == 'dh'
                    ? <DhBallGameGraph gameResults={gameResults}/>
                    : <PowerballGameGraph gameResults={gameResults}/>
            }

            <div className={"user-pick-table"}>
                <table>
                    <thead>
                    <tr>
                        <th>순위</th>
                        <th>구분</th>
                        <th>픽</th>
                        <th>결과</th>
                        <th>승률</th>
                        <th>연승</th>
                        <th>닉네임</th>
                        <th>채팅방</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        pickData && pickData.length > 0
                            ? pickData.map((data, index)=> {
                                const game_pick = GAME_PICK_TEXT[data.game_pick]
                                const pick_type  = game_pick.split(' ')[0]
                                const pick_value = game_pick.split(' ')[1]

                                const pick_type_en  = data.game_pick.split('_')[0]
                                const pick_value_en = data.game_pick.split('_')[1]
                                const states = data.user.stats
                                let winning_rate: string;
                                if(!states.win && !states.lose){
                                    winning_rate = "0%"
                                }else{
                                    winning_rate = (states.win / (states.win + states.lose) * 100).toFixed() + "%"
                                }
                                return (
                                    <tr key={index}>
                                        {/*순위*/}
                                        <td>{index+1}</td>
                                        {/*구분*/}
                                        <td className={"pick-type"}>
                                            <span className={pick_type_en}>{pick_type}</span>
                                        </td>
                                        {/*픽*/}
                                        <td className={"pick-value"}>
                                            <span className={pick_value_en}>{pick_value}</span>
                                        </td>
                                        {/*결과*/}
                                        <td>-</td>
                                        {/*승률*/}
                                        <td>
                                            <Flex justifyContent={"center"} gap={5}>
                                                <span className={"--color-red"}>{`${states.win}승`}</span>
                                                <span className={"--color-small"}>{`${states.lose}패`}</span>
                                                <span>({`${winning_rate}`})</span>
                                            </Flex>
                                        </td>
                                        {/*연승*/}
                                        <td>
                                            {
                                                states.streak > 0
                                                    ? <span className={"--color-even"}>{`${states.streak}연승`}</span>
                                                    : <span className={"--color-small"}>{`${-states.streak}연패`}</span>
                                            }
                                        </td>
                                        {/*닉네임*/}
                                        <td>
                                            <UserLevelNamecard user={data.user} />
                                        </td>
                                        {/*채팅방*/}
                                        <td>
                                            <Icon icon={"lo chat-bubble"} size={24} />
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr><td colSpan={8}>유저픽 데이터가 존재하지 않습니다.</td></tr>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}
function MobileUserPickButton(){
    const { SetMobilePickState } = useContext(MinigameContext)
    return (
        <>
            <button className={"mobile-open-user-pick btn btn-theme"} onClick={SetMobilePickState}>유저픽 등록</button>
        </>
    )
}

export function getLeftTime(last_game_draw_dt: string, kind: string) {
    const lastGameTime = new Date(last_game_draw_dt);
    // (게임별 텀) + 딜레이
    // console.log(lastGameTime.getTime(), Date.now())
    const returnLeftTimeDelay = lastGameTime.getTime() + (GAME_COUNT_TYPE[kind] * 60 * 1000 + GAME_FETCH_DELAY[kind]) - Date.now();
    if(returnLeftTimeDelay > 0){
        return returnLeftTimeDelay
    }else{
        return getStringMinute(kind as MinigameType) * 1000
    }
}

// 관리자 임시 차단
export function BlockMinigame(){
    return (
        <div className="empty">
            <img src={empty_logo} alt="monologo" />
                <p>중계화면 점검 중 입니다.</p>
                <p>보다 안정적인 서비스를 위해 서버 점검 작업을 진행 중입니다.</p>
        </div>
    )
}