import React, {ReactNode, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import Policy_1 from "./contents/1";
import Policy_4 from "./contents/4";
import Policy_3 from "./contents/3";
import Policy_2 from "./contents/2";

function _Page() {
    const [textContents, setText] = useState<ReactNode>("1")
    const {type} = useParams()
    useEffect(()=>{
        switch(type){
            case "1": setText(<Policy_1/>); break; // 이용약관
            case "2": setText(<Policy_2/>); break; // 개인정보처리방침
            case "3": setText(<Policy_3/>); break; // 청소년보호정책
            case "4": setText(<Policy_4/>); break; // 이메일주소 수집거부
        }
    }, [textContents])
    return (
        <div className={"board-policy"}>
            {textContents ? textContents : <></>}
        </div>
    );
}

export default _Page;