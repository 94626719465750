import React from 'react';
import Minigame from "./minigame/home";
import ScoreBoardPage from "./sports/sports/score_board";
import {Landing as LandingCommunity} from "../components/board/landing";
import {Link} from "react-router-dom";
import NoticeLanding from "./service_center/notice/notice_landing";
import SportsLayout from "./sports/_layout";

function MainLandingLayout() {
    return (
        <>
            <div className={"m_landing-notice"}>
                <NoticeLanding/>
            </div>
            <Minigame isLanding={true}/>  {/* #minigame-tab */}
            <Link className={"landing-go-minigame-layout"} to={"/minigame/"}> &gt;&gt; 해피 545파워볼 중계화면 바로가기</Link>
            <LandingCommunity/>
            <SportsLayout isLanding={true}/>
        </>
    );
}

export default MainLandingLayout;