import React, {useState} from "react";
import {tab_interface} from "../../components/board/board_tab";
import LayoutHeader from "../../components/layout/layout_header";
import SportsTab from "./sports/sports_tab";
import GetSportsScore from "./sports/get_sports_score";

export const board_tab_list: tab_interface[] = [
    {eventKey: "", text: "라이브스코어", clickEvent: () => alert('준비중입니다.')},
    // {eventKey:"analysis", text: "스포츠분석 게시판", default: true},
]
export type sports_tab_type = "soccer" | "baseball" | "basketball" | "volleyball" | "hockey" | "football"
export default function SportsLayout({isLanding}: {isLanding: boolean}) {
    const [sports, setSports] = useState<sports_tab_type>("soccer")
    return (
        <>
            <LayoutHeader tab={"sports"}/>
            <SportsTab sports={sports} setSports={setSports}/> {/* #sports-tab */}
            <GetSportsScore sports={sports} isLanding={isLanding}/>
        </>
    )
}
SportsLayout.defaultProps = {
    isLanding: false
}
