import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import moment from "moment";
import RichEditor from "src/components/richEditor";
import UserLevelNamecard from "src/components/user/user_level_namecard";
import useUser from "src/hooks/useUser";
import { PostType } from "src/types/post";

export default function PostDetailComponent({ post, updatePost, deletePost, admin_required = false }: { post: PostType; updatePost?: any; deletePost?: any; admin_required?: boolean }) {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { user } = useUser();
  // 해당 유저가 수정 권한이 있나?
  let editable = post.writer.uuid === user?.uuid;
  editable = user?.is_staff ? true : editable;
  // 수정 모드
  if (editMode) {
    return <PostDetailEditor post={post} updatePost={updatePost} setEditMode={setEditMode} />;
  }
  // 읽기 모드
  else {
    return <PostDetailViewer post={post} isMobile={false} editable={editable} setEditMode={setEditMode} onDeletePost={deletePost} />;
  }
}

function PostDetailEditor({ post, updatePost, setEditMode }: { post: PostType; updatePost: (post: PostType) => Promise<any>; setEditMode: any }) {
  const [title, setTitle] = useState<string>(post.title);
  const [content, setContent] = useState<string>(post.content);

  function handleUpdate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!title) {
      return alert("제목을 입력해주세요");
    } else if (!content) {
      return alert("게시물 내용을 작성해주세요");
    }
    updatePost({ ...post, title, content }).then(() => setEditMode(false));
  }

  return (
    <div className={"board-table"}>
      <div className="edit-board">
        <form onSubmit={handleUpdate}>
          <div className={"edit-form-title"}>
            <input type="text" name={"title"} value={title} onChange={(e) => setTitle(e.target.value)} placeholder={"제목을 입력하세요."} />
          </div>
          <div className={"edit-form-content"}>
            <RichEditor content={content} setContent={setContent} />
          </div>
          <footer className={"edit-form-btn"}>
            <Link className={"write-new"} to={`/community/free/`}>
              취소
            </Link>
            <button className={"write-new"} type={"submit"}>
              등록
            </button>
          </footer>
        </form>
      </div>
    </div>
  );
}

function PostDetailViewer({ post, isMobile, editable, setEditMode, onDeletePost }: { post: PostType; isMobile: boolean; editable: boolean; setEditMode: (editMode: boolean) => void; onDeletePost: () => void }) {
  return (
    <div className={"board-table"}>
      <div className={"board-detail"}>
        <header>
          <h1>
            {post.title} <span>{post.comment_count > 0 ? `+${post.comment_count}` : post.comment_count != 0 ? post.comment_count : ""}</span>
          </h1>
          <div>
            <UserLevelNamecard user={post.writer} />
            <div>{moment(post.created_dt).format("YYYY-MM-DD HH:mm:ss")}</div>
          </div>
        </header>
        <main>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </main>
        <footer>
          {editable ? (
            <>
              <Button onClick={() => setEditMode(true)}>수정</Button>
              <Button onClick={onDeletePost}>삭제</Button>
            </>
          ) : (
            <Button>신고</Button>
          )}
        </footer>
      </div>
      <div className={"board-search-form"}>
        <Link className={"write-new"} style={{ width: "4.8rem" }} to={"../"}>
          목록
        </Link>
      </div>
    </div>
  );
}
