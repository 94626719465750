import React from "react";
import {isMobile} from "react-device-detect";
import {extend} from "dayjs";


export class ToggleDeviceMode extends React.PureComponent {
    public Mobile: React.ReactNode;
    public Desktop: React.ReactNode;

    render(){
        if(isMobile){
            return ( this.Mobile )
        }else{
            return ( this.Desktop )
        }
    }
}
