import React from "react";
import BoardHead from "../../components/board/board_head";
import { Outlet } from "react-router-dom";
import BoardTab, { tab_interface } from "../../components/board/board_tab";
import LayoutHeader from "../../components/layout/layout_header";

export const board_tab_list: tab_interface[] = [
    { eventKey: "profile", text: "내정보" },
    { eventKey: "history", text: "보유내역" },
    { eventKey: "exp", text: "경험치" },
    { eventKey: "friends", text: "친구" },
    { eventKey: "message-box", text: "쪽지" },
]
export default function UserLayout() {
    return (
        <>
            <LayoutHeader tab={"my_page"} />
            <BoardHead />
            <BoardTab page={"user"} tab_list={board_tab_list} />
            <Outlet />
        </>
    )
}