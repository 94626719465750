import React, {useEffect, useState} from 'react';
import LayoutHeader from "../layout/layout_header";
import LandingBoardBox from "./landing_board_box";
import {isMobile} from "react-device-detect";
import UserLevelNamecard from "../user/user_level_namecard";
import { PostType } from 'src/types/post';
import moment from "moment";
import Flex from "../layout/flex";
import {Link, useNavigate, useSearchParams} from "react-router-dom";

export function Landing(props: {}) {
    return (
        <div className={"board-layout"}>
            <LayoutHeader tab={"community"}/>
            { isMobile ? <Mobile/> : <Desktop/> }
        </div>
    );
}

function Desktop(){
    return (
        <main className={"pc-landing-community-area"}>
            <LandingBoardBox board_type={"all"}/>
            <LandingBoardBox board_type={"free"}/>
            <LandingBoardBox board_type={"humor"}/>
            {/*<LandingBoardBox board_type={"sports"}/>*/}
            <LandingBoardBox board_type={"picture"}/>
            {/*<LandingBoardBox board_type={"video"}/>*/}
        </main>
    )
}

type mo_landing_co_tab = "all" | "free" | "humor" | "picture"
interface MobileLandingTab {
    type: mo_landing_co_tab
    name: string
}
function Mobile(){
    const [ tab, setTab ] = useState<mo_landing_co_tab>("all")
    const [ list, setList ] = useState<PostType[]>()
    const navigate = useNavigate()
    let [searchParams] = useSearchParams();
    const landing_community: MobileLandingTab[] = [
        { type: "all", name: "모아보기" },
        { type: "free", name: "자유 게시판" },
        { type: "humor", name: "유머 게시판" },
        { type: "picture", name: "사진 게시판" },
    ]
    useEffect(()=> {
        searchParams.set('page_size', "6")
        if(tab != "all"){
            searchParams.set('type', tab)
        }else{
            searchParams.delete('type')
        }
        const fetch_url = `/api/community/posts/?${searchParams}`

        fetch(fetch_url)
            .then(res => res.json())
            .then((data) => {
                setList(data.results)
            })
            .catch(err => alert(JSON.stringify(err)))
    }, [tab])
    return (
        <main className={"mo-landing-community-area"}>
            <div className={"board-tab col-3"}>
                {
                    landing_community.map((t_obj, index)=> (
                        <a  key={index}
                            className={tab == t_obj.type ? "select" : ""}
                            onClick={e=> {
                            e.preventDefault()
                            setTab(t_obj.type)
                        }}>{t_obj.name}</a>
                    ))
                }
            </div>
            {
                list? (tab == "picture" )
                        ? <MoLandingCommunityTemplatePicture list={list}/>
                        : <MoLandingCommunityTemplateDefault list={list}/>
                    : <></>
            }

            <a className="landing-go-minigame-layout --mt-20" href={`/community/${tab}/`}> &gt;&gt; 더보기</a>
        </main>
    )
}

function MoLandingCommunityTemplatePicture({list}: { list: PostType[] }){
    const navigate = useNavigate()
    return (
        <ul className={"board-picture-mo"}>
            {
                list?.map((li, index)=>{
                    let thumbnailStyle: object = {};
                    if (li.thumbnail) {
                        thumbnailStyle = {
                            backgroundImage: "url('" + li.thumbnail + "')"
                        }
                    }
                    return (
                        <li className={!li.thumbnail ? "empty" : ""} style={thumbnailStyle}>
                            <Link className={"thumbnail-link"} to={`/community/${li.type}/${li.id}`}></Link>
                        </li>
                    )
                })
            }
        </ul>
    )
}
function MoLandingCommunityTemplateDefault({list}: { list: PostType[] }){
    const navigate = useNavigate()
    return (
        <ul className={"mo-board"}>
            {
                list?.map((li, index)=>{
                    const create_dt = moment(li.created_dt).format('YY/MM/DD')
                    let return_text: string = ""
                    switch(li.type){
                        case "free" :
                            return_text = "자유";
                            break;
                        case "humor" :
                            return_text = "유머";
                            break;
                        case "picture" :
                            return_text = "사진";
                            break;
                        case "video" :
                            return_text = "영상";
                            break;
                        case "sports" :
                            return_text = "분석";
                            break;
                    }
                    return (
                        <li key={li.id} onClick={e=> navigate(`/community/${li.type}/${li.id}`)}>
                            <b className={`board-type ${li.type}`}>{return_text}</b>
                            <div>
                                <p className={"title"}>{li.title}</p>
                                <Flex gap={5}>
                                    <UserLevelNamecard user={li.writer}/>
                                    <span>{create_dt}</span>
                                </Flex>
                            </div>
                        </li>
                    )
                })
            }
        </ul>
    )
}