import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceLaugh, faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import useUser from "../../hooks/useUser";
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

function ChatFooter({ onSend }: { onSend: any }) {
    const [textInput, setTextInput] = useState("")
    const [textCount, setTextCount] = useState(0)
    const { user } = useUser()
    const isKeyboardOpen = useDetectKeyboardOpen()

    function checkTextCount(e: any) {
        const inputText = e.target.value
        const inputTextCount = inputText.length
        if (inputTextCount > 200) {
            const subStringText = inputText.substring(0, 200)
            setTextInput(subStringText)
            setTextCount(200)
        } else {
            setTextInput(inputText)
            setTextCount(inputTextCount)
        }
    }
    return (
        <footer className={isKeyboardOpen ? "--mb-30" : ""}>
            <form onSubmit={e => {
                e.preventDefault()
                onSend(textInput)
                setTextInput("")
            }}>
                <p>{user?.nickname}</p>
                <input type="text" onInput={checkTextCount} placeholder={"메시지 입력하기..."} max={200} value={textInput}
                    onKeyPress={e => {
                        // FIXME 크롬 오류로 한글 입력시 키 다운 이벤트가 두번 발생해서 어쩔 수 없이 deprecated 함수를 이용...
                        if (e.key === 'Enter') {
                            onSend(textInput);
                            setTextInput("");
                        }
                    }} />
                <div className={"d-flex --fl-between-center"}>
                    <button>
                        <FontAwesomeIcon icon={faFaceLaugh} />
                    </button>
                    <div>
                        <span className={"text-count"}>{textCount}/200</span>
                        <button onClick={() => {
                            onSend(textInput)
                            setTextInput("")
                        }}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </div>
                </div>
            </form>
        </footer>
    );
}

export default ChatFooter;