import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CommentsComponent, { Comment } from "src/components/comments";
import { PostType } from "src/types/post";
import PostDetailComponent from "src/components/post/PostDetailComponent";
import api from "src/utils/api";
import ServiceCenterPostListPage from "./list";

type ServiceCenterPostApi = {
  post: ServiceCenterPost;
  comments: Comment[];
};

interface ServiceCenterPost extends PostType {}

export default function ServiceCenterPostDetailPage() {
  const { type, id: postId } = useParams();
  const [post, setPost] = useState<ServiceCenterPost>();
  const [comments, setComments] = useState<Comment[]>([]);
  const navigate = useNavigate();
  const admin_required = type === "notices";

  useEffect(() => {
    api(`/api/service_center/${type}/${postId}/with_comments/`)
      .then((res) => res.json())
      .then((data: ServiceCenterPostApi) => {
        setPost(data.post);
        setComments(data.comments);
      })
      .catch((error) => window.alert(JSON.stringify(error)));
  }, [postId]);

  async function updatePost(notice: ServiceCenterPost) {
    return api(`/api/service_center/${type}/${postId}/`, {
      method: "PATCH",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(notice),
    })
      .then((res) => res.json())
      .then((data: ServiceCenterPost) => setPost(data))
      .catch((err) => window.alert(JSON.stringify(err)));
  }

  async function deletePost() {
    return api(`/api/service_center/${type}/${postId}/`, { method: "DELETE" })
      .then(() => navigate(`/service_center/${type}/`))
      .catch((err) => window.alert(JSON.stringify(err)));
  }

  if (!post || !postId) {
    return <h1>로딩중</h1>;
  }
  return (
    <>
      <PostDetailComponent post={post} updatePost={updatePost} deletePost={deletePost} />
      <CommentsComponent postId={postId} comments={comments} setComments={setComments} />
      <ServiceCenterPostListPage inline={true}/>
    </>
  );
}
