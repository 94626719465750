import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import useUser from 'src/hooks/useUser';
import api from 'src/utils/api2';
import Btn from "../../../components/form/btn";
import Flex from "../../../components/layout/flex";

interface MessageCreateErrorType {
    receiver?: string[]
    detail?: string[]
}

export default function MessageWrite() {
    const { user, mutateUser } = useUser();
    const [receiverNickname, setReceiverNickname] = useState<string>('')
    const [text, setText] = useState<string>('')

    const [error, setError] = useState<MessageCreateErrorType>()
    const navigate = useNavigate()

    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        if (state?.to) {
            setReceiverNickname(state?.to)
        }
    }, [state?.to])
    useEffect(() => {
        if (searchParams?.get('to')) {
            setReceiverNickname(searchParams.get('to') as string)
        }
    }, [searchParams])

    function handleTextChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        const newText = e.currentTarget.value
        if (newText.length < 1001) {
            setText(newText)
        } else {
            setText(newText.substring(0, 1000))
        }
    }

    function handleSendMessage(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        if (text.length > 999) {
            return alert('1000자를 초과하였습니다.')
        }
        const body = { content: text, receiver: receiverNickname }
        api(`/api/user/messages/sent/`, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': "application/json" } })
            .then(res => {
                alert('전송완료')
                setText('')
                mutateUser()
            })
            .catch(res => handleError(res))
    }

    async function handleError(res: Response) {
        if (res.headers.get('content-type') !== 'application/json') {
            return alert("오류가 발생하였습니다.")
        }
        const error: MessageCreateErrorType = await res.json()
        if (error.detail) {
            return alert(error.detail)
        } else if (error.receiver) {
            return alert(error.receiver)
        }
    }

    function handleResetMessage() {
        if (!window.confirm('쪽지횟수 초기화권을 사용하시겠습니까?')) {
            return
        }
        api(`/api/user/reset-message`, { method: "POST" })
            .then(res => {
                mutateUser()
            })
            .catch(async (res: Response) => {
                if (res.headers.get('content-type') !== 'application/json') {
                    return alert('오류가 발생하였습니다.')
                }
                const error = await res.json()
                if (error.detail) {
                    return alert(error.detail)
                }
            })
    }

    return (
        <>
            <header>
                <ul className={"message-warning"}>
                    <li>상대방을 비방 또는 욕설을 포함한 쪽지 작성 시 예고 없이 차단됩니다.</li>
                    <li>1회 최대 전송 글자 수는 <b>1,000자</b> 입니다.</li>
                    <li>계급에 따라 정해진 전송횟수 소진 시 <b className={"--color-blue"}>“쪽지횟수 초기화”</b> 아이템을 사용하면 다시 쪽지를 보내실 수 있습니다.</li>
                </ul>
                <div className={"message-count"}>
                    <strong>전송가능 쪽지</strong>
                    <p>
                        <span className={"--color-red"}>
                            {
                                user?.daily_info.remain_message === null ? '∞' : user?.daily_info.remain_message
                            }
                        </span> / {user?.daily_info.message_limit === null ? '∞' : user?.daily_info.message_limit}
                    </p>
                    <Flex gap={5} className={"--ml-auto"}>
                        <Btn onClick={handleResetMessage}>쪽지횟수 초기화</Btn>
                        <Btn onClick={e => navigate('/market')}>마켓 바로가기</Btn>
                    </Flex>
                </div>
            </header>

            <main>
                <form onSubmit={handleSendMessage}>
                    <Flex gap={20}>
                        <p className={"--font-14"}>닉네임 입력</p>
                        <input type={"text"} className={"target-nickname"} name="receiver" onChange={e => setReceiverNickname(e.currentTarget.value)} value={receiverNickname} required />

                        <p className={"--ml-auto --pr-10"}>
                            {text.length > 999 ? <b className={"--color-red"}>{text.length}</b> : text.length} / 1000
                        </p>
                    </Flex>

                    <textarea className={"send-message"} name="content" onChange={handleTextChange} value={text} required></textarea>
                    <Flex justifyContent={"end"}>
                        <Btn className={"btn btn-theme send"} isSubmit={true}>쪽지 전송</Btn>
                    </Flex>
                </form>
            </main>
        </>
    );
}
