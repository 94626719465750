import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

type landing_tab = "home" | "sports" | "minigame" | "community" | "market" | "rank" | "chat" | "customer_support" | "my_page"
const tab_set = {
    home: {
        name   : "HOME",
        name_ko: "메인"
    },
    sports: {
        name   : "SPORTS DATA",
        name_ko: "스포츠"
    },
    minigame: {
        name   : "MINI GAME",
        name_ko: "미니게임"
    },
    community: {
        name   : "COMMUNITY",
        name_ko: "커뮤니티"
    },
    market: {
        name   : "MARKET",
        name_ko: "마켓"
    },
    rank: {
        name   : "RANKING",
        name_ko: "랭킹"
    },
    chat: {
        name   : "CHAT",
        name_ko: "채팅방"
    },
    customer_support: {
        name   : "CUSTOMER SUPPORT",
        name_ko: "고객센터"
    },
    my_page: {
        name   : "MY PAGE",
        name_ko: "마이페이지"
    },
}
interface tab_interface {
    icon: landing_tab
    name: string
    name_ko: string
}

function LayoutHeader({tab, onClick}: {tab: landing_tab, onClick: React.EventHandler<any>}) {
    const [stateTab, setStateTab] = useState<landing_tab>()
    const navigate = useNavigate()
    return (
        <header className={"layout-header"} onClick={onClick}>
            <i className={"--icon header-i "+tab}></i>
            <strong>{tab_set[tab].name}</strong>
            <span>|</span>
            <span>{tab_set[tab].name_ko}</span>
        </header>
    );
}
LayoutHeader.defaultProps = {
    onClick: ()=>{}
}

export default LayoutHeader;