import { useEffect, useState } from "react";
import api from "src/utils/api";
import ChangeNicknameModal from "../popup/change-nickname";
import ChangeIntroModal from "../popup/change-intro";
import ResetMessageModal from "../popup/reset-message";
import useUser from "../../../../hooks/useUser";
import { with_comma } from "../../../../utils/number_comma";
import Btn from "../../../../components/form/btn";

import img_nickname from "src/asset/images/market/Icon_Item_01.svg";
import img_profile from "src/asset/images/market/Icon_Item_02.svg";
import img_one from "src/asset/images/market/Icon_Item_00.svg";
import img_random from "src/asset/images/market/Group 72849.svg";
import img_message from "src/asset/images/market/Group 729.svg";
import { Link } from "react-router-dom";

interface InventoryType {
  nickname_change: number;
  intro_change: number;
  message_reset: number;
}

export default function ItemPage() {
  const [inventory, setInventory] = useState<InventoryType>();
  const [showChangeNicknameModal, setShowChangeNicknameModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<"nickname" | "intro" | "message">();

  // FIXME 모달에서 아이템을 사용하면 인벤토리에 변화가 생긴다.
  // 이 변화를 적절하게 감지하는 방법이 필요하다
  useEffect(() => {
    api("/api/user/inventory")
      .then((res) => res.json())
      .then((data: InventoryType) => setInventory(data))
      .catch((err) => alert(JSON.stringify(err)));
  }, [showModal]);

  return (
    <>
      <div className={"board-table board-user inventory"}>
        <header>
          <UserCash />
        </header>

        <main>
          <header>보유 아이템</header>
          <ul className="item-list">
            {inventory && inventory.nickname_change > 0 && (
              <li>
                <div className="item-image">
                  <img src={img_nickname} alt="" />
                </div>
                <div className="item-name">닉네임 변경권</div>
                <div className="count">보유개수: {inventory.nickname_change}</div>
                <button onClick={(e) => setShowModal("nickname")}>아이템 사용</button>
              </li>
            )}
            {inventory && inventory.intro_change > 0 && (
              <li>
                <div className="item-image">
                  <img src={img_profile} alt="" />
                </div>
                <div className="item-name">오늘의 한마디 변경권</div>
                <div className="count">보유개수: {inventory.intro_change}</div>
                <button onClick={(e) => setShowModal("intro")}>아이템 사용</button>
              </li>
            )}
            {inventory && inventory.message_reset > 0 && (
              <li>
                <div className="item-image">
                  <img src={img_message} alt="" />
                </div>
                <div className="item-name">쪽지 횟수 초기화권</div>
                <div className="count">보유개수: {inventory.message_reset}</div>
                <button onClick={(e) => setShowModal("message")}>아이템 사용</button>
              </li>
            )}
          </ul>
        </main>
      </div>
      <ChangeNicknameModal show={showModal === "nickname"} setShow={() => setShowModal(undefined)} />
      <ChangeIntroModal show={showModal === "intro"} setShow={() => setShowModal(undefined)} />
      <ResetMessageModal show={showModal === "message"} setShow={() => setShowModal(undefined)} />
    </>
  );
}

export function UserCash() {
  const { user } = useUser();
  return (
    <div className={"my-cash"}>
      <strong>보유캐시</strong>
      <p className={"--color-pink"}>{user?.cash && with_comma(user.cash)}</p>
      <Btn type={"theme"}>
        <Link to={"/market/"}>마켓 바로가기</Link>
      </Btn>
    </div>
  );
}
