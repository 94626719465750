import moment from "moment";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {PostType} from "src/types/post";

import PaginationComponent from "src/components/pagination";
import UserLevelNamecard from "src/components/user/user_level_namecard";
import useUser from "src/hooks/useUser";
import Flex from "../layout/flex";
import {Button, Form} from "react-bootstrap";
import {useEffect, useState} from "react";
import Btn from "../form/btn";

export default function PostListComponent({
                                              listPath,
                                              posts,
                                              totalCount,
                                              previewMode = false,
                                              Header,
                                              SearchForm,
                                              indexType = "id",
                                              isMobile = false,
                                              showCreate = false,
                                          }: {
    listPath: string;
    posts: PostType[] | undefined;
    totalCount: number;
    previewMode?: boolean;
    Header?: any;
    SearchForm?: any;
    isMobile?: boolean;
    indexType?: "id" | "postType";
    showCreate?: boolean;
}) {
    const {type} = useParams();
    const {user} = useUser();
    const [searchParams] = useSearchParams();

    const [getPage, setPage] = useState<string>('1')

    // useEffect(()=>{
    //   window.scrollTo({top: 0})
    // }, [posts])
    useEffect(()=> {
        const search_page = searchParams.get('page')
        if(search_page){
            setPage(search_page)
        }
    })

    if (!posts) {
        return <>로딩중</>;
    }

    if (previewMode) {
        return (
            <div className="board-table">
                <ul className={isMobile ? "board-picture-mo" : "board-picture"}>
                    {posts.map((post) => (
                        <PreviewListItem key={post.id} post={post} listPath={listPath}/>
                    ))}
                </ul>
                <div className={"list-search-form"}>
                    <PaginationComponent totalCount={totalCount!} perPage={16}/>
                    {!isMobile && <ListSearchForm listPath={listPath}/>}
                    {showCreate && (
                        <Link
                            className="write-new"
                            to={`${listPath}/new`}
                            onClick={(e) => {
                                if (!user || user.is_anonymous) {
                                    e.preventDefault();
                                    alert("로그인 후 이용가능합니다.");
                                }
                            }}
                        >
                            글쓰기
                        </Link>
                    )}
                </div>
                {" "}
            </div>
        );
    } else {
        return (
            <div className="board-table">
                {Header && <Header/>}
                {SearchForm}
                {isMobile
                    ?  <ul className={"mo-board"}>
                        {posts.map((post) => (
                            <ListItem key={post.id}
                                      post={post}
                                      listPath={listPath}
                                      indexType={indexType}
                                      getPage={getPage}
                                      isMobile={isMobile}/>
                        ))}
                      </ul>
                    : <table className="default">
                        <tbody>
                        {posts.map((post: PostType) => (
                            <ListItem key={post.id}
                                      post={post}
                                      listPath={listPath}
                                      getPage={getPage}
                                      indexType={indexType}/>
                        ))}
                        </tbody>
                     </table>
                }
                <div className={"list-search-form"}>
                    <PaginationComponent totalCount={totalCount!} perPage={20}/>
                    {!isMobile && <ListSearchForm listPath={listPath}/>}
                    {showCreate && (
                        <Link
                            className="write-new"
                            to={`${listPath}/new`}
                            onClick={(e) => {
                                if (!user || user.is_anonymous) {
                                    e.preventDefault();
                                    alert("로그인 후 이용가능합니다.");
                                }
                            }}
                        >
                            글쓰기
                        </Link>
                    )}
                </div>
                {" "}
            </div>
        );
    }
}

function ListItem({post, listPath, isMobile = false, indexType, getPage = '0'}:
                      { post: PostType; listPath: string; isMobile?: boolean; indexType?: "id" | "postType"; getPage: string }) {
    const navigate = useNavigate();
    const created_dt = moment(post.created_dt).format("YY/MM/DD");
    let indexLabel: string;
    if (indexType === "postType") {
        switch (post.type) {
            case "free":
                indexLabel = "자유";
                break;
            case "humor":
                indexLabel = "유머";
                break;
            case "picture":
                indexLabel = "사진";
                break;
            case "video":
                indexLabel = isMobile ? "영상" : "비디오";
                break;
            case "sports":
                indexLabel = isMobile ? "분석" : "스포츠분석";
                break;
            default:
                indexLabel = "기타";
                break;
        }
    } else {
        indexLabel = post.id.toString();
    }
    let navigation_path = `${listPath}/${post.id}`
    if(getPage){
        navigation_path += `?page=${getPage}`
    }
    if (isMobile) {
        return (
            <li key={post.id} onClick={(e) => navigate(navigation_path)}>
                <b className={`board-type ${post.type}`}>{indexLabel}</b>
                <div>
                    <p className={"title"}>{post.title}</p>
                    <Flex gap={5}>
                        <UserLevelNamecard user={post.writer}/>
                        <span>{created_dt}</span>
                    </Flex>
                </div>
            </li>
        );
    }
    return (
        <tr key={post.id}>
            <td>
                <b className={post.type}>{indexLabel}</b>
            </td>
            <td>
                <Link to={navigation_path}>
                    {post.title} {post.comment_count ?
                    <strong className={"--ml-5 --color-red"}>[{post.comment_count}]</strong> : ""}{" "}
                </Link>
            </td>
            <td>
                <UserLevelNamecard user={post.writer}/>
            </td>
            <td>{moment(post.created_dt).format("YY/MM/DD")}</td>
        </tr>
    );
}

function PreviewListItem({post, listPath}: { post: PostType; listPath: string }) {
    let thumbnailStyle: Object = {};
    if (post.thumbnail) {
        thumbnailStyle = {
            backgroundImage: "url('" + post.thumbnail + "')",
        };
    }
    return (
        <li className={!post.thumbnail ? "empty" : ""} style={thumbnailStyle}>
            <Link className={"thumbnail-link"} to={`${listPath}/${post.id}`}></Link>
            <div className={"detail-info"}>
                <p>{post.title}</p>
                <UserLevelNamecard user={post.writer}/>
            </div>
        </li>
    );
}

function ListSearchForm({listPath}: { listPath: string }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [option, setOption] = useState<string>("title");
    const [value, setValue] = useState<string>("");

    function handleSearch(e: React.FormEvent) {
        e.preventDefault();
        if (value) {
            setSearchParams(`${option}=${value}`);
        } else {
            setSearchParams("");
        }
    }

    return (
        <form className={"form-title"} onSubmit={handleSearch}>
            <select value={option} onChange={(e) => setOption(e.currentTarget.value)}>
                <option value="title">제목</option>
                <option value="writer">작성자</option>
            </select>
            <input type="text" value={value} onChange={(e) => setValue(e.currentTarget.value)}/>
            <Btn type="submit">검색</Btn>
        </form>
    );
}
