import React, {useState, useEffect} from "react";
import {Modal} from "react-bootstrap";
import useUser from "src/hooks/useUser";
import api from "src/utils/api";
import JoinTemplate from "../../member/join_template";
import PointChangeTemplate from "./point-change-template";
import Btn from "../../../../components/form/btn";


export default function ExpChangePoint({ show, setShow }: { show: boolean, setShow: any }) {
    const {user, mutateUser} = useUser()
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [exchangePoint, setExchangePoint] = useState<number>(0);
    const handleClose = () => {
        setShow(false);
    }
    function allPoint(){
        setExchangePoint(user?.point && user.point || 0)
    }

    useEffect(() => {
        fetch('/api/csrf-token');
    }, [])
    useEffect(()=>{

    },[submitting])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <PointChangeTemplate>
                    <form onSubmit={async e => {
                        e.preventDefault();
                        setSubmitting(true);
                        const formData = new FormData(e.target as HTMLFormElement);
                        // const response = await api('/api/user/change-nickname', {
                        //     method: "POST",
                        //     body: formData,
                        // })
                        //     .then(res => res.json())
                        //     .then(data => {
                        //         mutateUser();
                        //         alert('포인트 전환 완료');
                        //         window.location.reload()
                        //     })
                        //     .catch(err => alert(JSON.stringify(err)))
                        //     .finally(() => setSubmitting(false))
                    }}>
                        <div className={"form-row --mb-15"}>
                            <strong>내 포인트</strong>
                            <input type="text" className={"mypoint"} name={"mypoint"} defaultValue={user?.point}/>
                        </div>
                        <div className={"form-row --mb-15"}>
                            <strong>전환 포인트</strong>
                            <input type="text" className={"exp-changepoint"} name={"exp-changepoint"} defaultValue={exchangePoint} />
                            <div className={"absolute-btn"}>
                                <Btn onClick={e=>{e.preventDefault(); allPoint()}}>전체포인트</Btn>
                            </div>
                        </div>

                        <button className={"btn btn-theme2 " + ( submitting ? "--disabled" : "" )} type={"submit"}>전환하기</button>
                    </form>

                </PointChangeTemplate>
            </Modal.Body>
        </Modal>
    );
}