import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import api from "src/utils/api";
import PaginationComponent from "src/components/pagination";
import useUser from "src/hooks/useUser";
import { with_comma } from "src/utils/number_comma";
import { Form, useLocation, useSearchParams } from "react-router-dom";
import { DateRange } from "./item_history";
import { Button } from "react-bootstrap";
import Flex from "src/components/layout/flex";
import Btn from "src/components/form/btn";
import HistorySearchFilter from "./HistorySearchFilterComponent";
import moment from "moment";

interface PointHistoryApi {
  count: number;
  results: PointHistory[];
}

interface PointHistory {
  amount: number;
  explanation: string;
  created_at: string;
}

export default function PointHistoryPage() {
  const [pointHistories, setPointHistories] = useState<PointHistory[]>();
  const { user } = useUser();
  const location = useLocation();

  useEffect(() => {
    api(`/api/user/point-history/${window.location.search}`)
      .then((res) => res.json())
      .then((data: PointHistoryApi) => {
        setPointHistories(data.results);
      })
      .catch((err) => alert(JSON.stringify(err)));
  }, [location]);

  return (
    <div className={"board-table board-user point"}>
      <header>
        {/*<UserPoint toggle={toggleExpModal}/>*/}
        <div className={"my-cash"}>
          <strong>보유포인트</strong>
          <p className={"--color-blue"}>{user?.point && with_comma(user.point)}</p>
        </div>
        <HistorySearchFilter isMobile={isMobile} />
      </header>

      {isMobile ? <TableTemplateMobile pointHistories={pointHistories} /> : <TableTemplateDesktop pointHistories={pointHistories} />}
      <PaginationComponent totalCount={10} />
    </div>
  );
}

function TableTemplateDesktop({ pointHistories }: { pointHistories: PointHistory[] | undefined }) {
  return (
    <table>
      <thead>
        <tr>
          <th>번호</th>
          <th>변동 포인트</th>
          <th>변동 사유</th>
          <th>날짜</th>
        </tr>
      </thead>
      <tbody>
        {pointHistories?.map((cash, index) => (
          <tr key={index}>
            <td>{pointHistories.length - index}</td>
            <td className={`change ${ cash.amount > 0 ? "add" : "sub" }`}>{with_comma(cash.amount)}</td>
            <td>{cash.explanation}</td>
            <td>{moment(cash.created_at).format('YY/MM/DD HH:mm:ss')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
function TableTemplateMobile({ pointHistories }: { pointHistories: PointHistory[] | undefined }) {
  return (
    <table className={"history-board-mo"}>
      <thead>
        <tr>
          <th>번호</th>
          <th>변동 포인트</th>
          <th>변동 사유</th>
        </tr>
      </thead>
      <tbody>
        {pointHistories?.map((cash, index) => (
          <tr key={index}>
            <td>{pointHistories.length - index}</td>
            <td className={`change ${cash.amount > 0 ? "add" : "sub"}`}>
                {with_comma(cash.amount)}
            </td>
            <td>
              <p>{cash.explanation}</p>
              <p>{moment(cash.created_at).format('YY/MM/DD HH:mm:ss')}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
