import React, {useEffect, useRef, useState} from 'react';
import {GameResult, VIEW_TYPE, LABELS} from "../interface";
import AudioPlayer from "./audio_player";
import startBgm from "../../asset/sound/Start.mp3"
import GameBoardTimerCircle from "./game_board_timer_circle";

import { useNavigate } from 'react-router-dom';
import {MinigameType} from "../../../../types/minigame";
import peeksLogo from '../../../../asset/images/common/peeks_logo.svg'
import Balls from "./view_type/Balls";
import Reels from "./view_type/Reels";
import Ladder, {getLadderLine, getLadderResult} from "./view_type/Ladder";
import DhBalls from "./view_type/dh_balls";

export const ball_number_obj ={
    normal: [0, 0, 0, 0, 0],
    powerball: 0
}
export interface ball_obj {
    normal: number[]
    powerball: number
}
export type board_state = "" | "stay" | "ready" | "spin" | "end"
export const gap = 900
export const delay = 800
export const last_delay = 2000

export default function GameBoard({gameResults, game, viewType}: { gameResults: GameResult[], game: MinigameType; viewType: VIEW_TYPE }) {
    const [state, setState] = useState<board_state>("")
    const [kind, setKind] = useState<string>(gameResults.first().kind)
    const [bgmState, setBgmState] = useState(false)
    const [resultPopup, setResultPopup] = useState(false)
    const [ballNumber, setBallNumber] = useState<ball_obj>(ball_number_obj)

    // console.log(state)
    useEffect(()=> {
        // console.log(state)
        if(viewType == 'reel'){
            if(state == 'ready'){
                setTimeout(()=> {
                    setState('spin')
                }, 0)
            }else if(state == 'spin'){
                setBgmState(true)
                setTimeout(()=> {
                    setState('end')
                }, 1000)
            }else if(state == 'end'){
                setBgmState(false)
                setTimeout(()=> {
                    setResultPopup(true)
                }, 10000)
                setTimeout(()=> {
                    setState('stay')
                    setResultPopup(false)
                }, 13500)
            }
        }else if(viewType == 'ball'){
            if( state == 'ready' ){
                setBgmState(true)
                setTimeout(()=>{
                    setState('spin')
                },  100)
            }else if( state == 'spin' ){
                setTimeout(()=>{
                    setState('end')
                    setResultPopup(true)
                },  13500)
            }else if( state == 'end' ){
                setTimeout(()=>{
                    setState('stay')
                    setResultPopup(false)
                },  2000)
            }
        }else if(viewType == 'dh'){
            if( state == 'ready' ){
                setBgmState(true)
                setTimeout(()=>{
                    setState('spin')
                },  100)
            }else if( state == 'spin' ){
                setTimeout(()=>{
                    setState('end')
                    setResultPopup(true)
                },  7000)
            }else if( state == 'end' ){
                setTimeout(()=>{
                    setState('stay')
                    setResultPopup(false)
                },  2800)
            }
        }else if(viewType == 'ladder'){
            if( state == 'ready' ){
                setBgmState(true)
                setState('spin')
            }else if( state == 'spin' ){
                setTimeout(()=>{
                    setResultPopup(true)
                },  3200)
                setTimeout(()=> {
                    setState('end')
                }, 5000)
            }else if( state == 'end' ){
                setResultPopup(false)
                setState('stay')
            }
        }
    }, [state])
    useEffect(()=> {
        const round_ball_data = gameResults.first()
        const normal_ball_sort = round_ball_data.balls
        // @ts-ignore
        ball_number_obj.normal = normal_ball_sort;
        ball_number_obj.powerball = round_ball_data.power_ball;
        setBallNumber(ball_number_obj)

        setState('ready')
    }, [gameResults])
    useEffect(()=> {
        setState('stay')
        setKind(gameResults.first().kind)
    }, [])
    return (
        <>
            <AudioPlayer src={startBgm} play={bgmState} />
            <PeeksLogoUrl viewType={viewType} />
            <div className={`game-board ${viewType} ${kind}`} >
                <PreLoad viewType={viewType}/>
                {/* 이동 */}
                {/*<div className={`peeks-location-layout ${viewType}`} onClick={e=> window.open('https://repicks.co.kr', 'repicks')}></div>*/}

                { viewType == "reel" ? <Reels board_state={state} ballNumber={ballNumber} kind={kind}/> : <></> }
                { viewType == "ball" ? <Balls board_state={state} ballNumber={ballNumber} kind={kind}/> : <></> }
                { viewType == "dh"   ? <DhBalls board_state={state} gameResults={gameResults.first()} /> : <></> }
                { viewType == "ladder" ? <Ladder board_state={state} ballNumber={ballNumber} kind={kind} /> : <></> }
                { resultPopup ? <GameResultPopup gameResults={gameResults} viewType={viewType} /> : <></> }
            </div>
            { (viewType == 'reel' || viewType == 'ball') && state == 'stay' ? <ToggleType game={game} viewType={viewType} /> : <></> }
            {/* 게임 시간 */}
            {
                state == 'stay' ? <GameBoardTimerCircle gameResultFirst={gameResults.first()}  viewType={viewType}/>
                                 : <></>
            }
        </>
    );
}
function PeeksLogoUrl({viewType}:{viewType: VIEW_TYPE}){
    return (
        <div className={`peeks-logo-url ${viewType}`}>
            <img src={peeksLogo} alt="logo"/>
            {/*<p>Repicks.co.kr</p>*/}
        </div>
    )
}
function ToggleType({game, viewType}: {game: MinigameType; viewType: VIEW_TYPE}){
    const navigate = useNavigate()
    return (
        <div className={`game-type-toggle-btn ${viewType}`}>
            <div onClick={e=>{ navigate(`/${game}/ball/`) }}></div>
            <div onClick={e=>{ navigate(`/${game}/reel/`) }}></div>
        </div>
    )
}

export function GameResultPopup({gameResults, viewType}: { gameResults: GameResult[]; viewType: VIEW_TYPE }){
    return (
        <>
            {
                viewType == "ladder"
                    ? <LadderResultPopup gameResults={gameResults.first()} />
                    : <></>
            }
            {
                viewType == "ball" || viewType == "reel"
                    ? <PowerballResultPopup gameResults={gameResults.first()}/>
                    : <></>
            }
            {
                viewType == "dh"
                    ? <DhResultPopup gameResults={gameResults.first()} />
                    : <></>
            }
        </>
    )
}
function DhResultPopup({gameResults}: {gameResults: GameResult}){
    return (
        <div className={`result-card ladder`}>
            <div className="inner">
                <div className="round">{gameResults.round_daily}회차 결과</div>
                <div className={"ranges ladder"}>
                    <span className={`ladder-result-ball dh ${gameResults.balls_odd_even}`}></span>
                    <span className={`ladder-result-ball dh ${gameResults.balls_size}`}></span>
                    <span className={`ladder-result-ball dh ${gameResults.power_ball_under_over}`}></span>
                </div>
            </div>
        </div>
    )
}
function LadderResultPopup({gameResults}: {gameResults: GameResult}){
    const firstNumber = gameResults.balls[0]
    const ladder_result = getLadderResult(firstNumber)
    const START = ladder_result.split('_')[0]
    const LINE = getLadderLine(firstNumber)
    const END = ladder_result.split('_')[1]
    return (
        <div className={`result-card ladder ${gameResults.kind}`}>
            <div className="inner">
                <div className="round">{gameResults.round_daily}회차 결과</div>
                <div className={"ranges ladder"}>
                    <span className={`ladder-result-ball ${START}`}></span>
                    <span className={`ladder-result-ball LINE${LINE}`}></span>
                    <span className={`ladder-result-ball ${END}`}></span>
                </div>
            </div>
        </div>
    )
}
function PowerballResultPopup({gameResults}: {gameResults: GameResult}){
    return (
        <div className={`result-card`}>
            <div className="inner">
                <div className="round">{gameResults.round_daily}회차 결과</div>
                <div className={"numbers"}>
                    <span className={"num-" + gameResults.balls[0]}>{ gameResults.balls[0] }</span>
                    <span className={"num-" + gameResults.balls[1]}>{ gameResults.balls[1] }</span>
                    <span className={"num-" + gameResults.balls[2]}>{ gameResults.balls[2] }</span>
                    <span className={"num-" + gameResults.balls[3]}>{ gameResults.balls[3] }</span>
                    <span className={"num-" + gameResults.balls[4]}>{ gameResults.balls[4] }</span>
                    <span className={"powerball"}>{ gameResults.power_ball }</span>
                </div>
                <div className={"ranges"}>
                    {/* happyball 홀짝 */}
                    <span className={"rect " + gameResults.power_ball_odd_even}>
                                            {"P" + LABELS[gameResults.power_ball_odd_even]}
                                        </span>
                    {/* happyball 언오 */}
                    <span className={"rect " + gameResults.power_ball_under_over}>
                                            {"P" + LABELS[gameResults.power_ball_under_over]}
                                        </span>
                    {/* 일반 홀짝 */}
                    <span className={"rect " + gameResults.balls_odd_even}>
                                            {LABELS[gameResults.balls_odd_even]}
                                        </span>
                    {/* 일반 언오 */}
                    <span className={"rect " + gameResults.balls_under_over}>
                                            {LABELS[gameResults.balls_under_over]}
                                        </span>
                    {/* 소, 중, 대 */}
                    <span className={"rect " + gameResults.balls_size}>
                                            {LABELS[gameResults.balls_size]}
                                        </span>
                </div>
            </div>
        </div>
    )
}

function PreLoad({viewType}: {viewType: VIEW_TYPE}) {
    const [state, setState] = useState<boolean>(false)
    let view_type_component = <></>

    useEffect(()=> {
        setTimeout(()=> {
            setState(true)
        }, 5000)
    }, [])

    switch(viewType){
        case "ball":
            view_type_component = <>
                <div className="shuffle-stay"></div>
                <div className="shuffle-normal"></div>
                <div className="shuffle-power"></div>
                {
                    [...Array(28)].map((_, index)=>(
                        <div key={index} className={`ball-down-normal-${index + 1}`}></div>
                    ))
                }
                {
                    [...Array(10)].map((_, index)=>(
                        <div key={index} className={`ball-down-normal-${index}`}></div>
                    ))
                }
                <div className="ball-down-ODD"></div>
                <div className="ball-down-EVEN"></div>
                <div className="ball-down-SMALL"></div>
            </>
        break;
        case "reel":
            view_type_component = <>
                <div className="spin-effect"></div>
                <div className="spin-effect-powerball"></div>
                <div className="spin-normal-number"></div>
                {
                    [...Array(28)].map((_, index)=>(
                        <div key={index} className={`spin-normal-number-end number-${index + 1}`}></div>
                    ))
                }
                <div className="spin-powerball-number"></div>
                {
                    [...Array(10)].map((_, index)=>(
                        <div key={index} className={`spin-powerball-number-end number-${index}`}></div>
                    ))
                }
            </>
        break;
        case "ladder":
            view_type_component = <>
                <div className={"LEFT_EVEN"}></div>
                <div className={"LEFT_ODD"}></div>
                <div className={"RIGHT_EVEN"}></div>
                <div className={"RIGHT_ODD"}></div>
            </>
        break;
        case "dh":
            view_type_component = <></>
        break;
    }
    return (
        state
            ? <div className={`preload ${viewType}`}>{view_type_component}</div>
            : <></>
    )
}