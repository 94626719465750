import React from 'react';

type popupTypes = "room" | "create"
function PopupTemplate({title, children, popupType}: {title: string, children: React.ReactNode, popupType: popupTypes}) {
    return (
        <div className={`room-popup-template ${popupType}`}>
            {
                popupType == 'create'
                    ? <>
                        <header>{title}</header>
                        <main>{children}</main>
                    </>
                    : children
            }
        </div>
    );
}

export default PopupTemplate;