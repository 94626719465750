import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import Icon from "../../components/icon/icon";
import UserLevelNamecard from "../../components/user/user_level_namecard";
import {userInterface} from "../../components/user/interface";
import {with_comma} from "../../utils/number_comma";
import {RankWarning} from "./header/rank_warning";

interface RankUser extends userInterface {
    exp: number
}

export default function RankingHome() {
    const [ranking, setRanking] = useState<RankUser[]>()
    const { type } = useParams();

    useEffect(() => {
        fetch('/api/user/ranking')
            .then(res => res.json())
            .then(data => setRanking(data))
    }, [])

    return (
        <>
            <RankWarning />
            <div className={"board-table"}>
                <table className={"rank " + type as string}>
                    <thead>
                        <tr>
                            <th>순위</th>
                            <th>닉네임</th>
                            <th>계급</th>
                            <th>경험치</th>
                            <th>오늘의 한마디</th>
                            <th>채팅방</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ranking?.map((user, index) => {
                                return (
                                    (

                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <UserLevelNamecard user={user} />
                                            </td>
                                            <td>{user.rank}</td>
                                            <td className={"align-center"}>{with_comma(user.exp)}</td>
                                            <td>...</td>
                                            <td>
                                                <Icon icon={"lo chat-bubble"} size={24} />
                                            </td>
                                        </tr>
                                    )
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
