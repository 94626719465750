import React, {useEffect, useState} from 'react';
import PerCount from "./per_count";
import PickBar from "./pick_bar";
import LoadPowerBallGame from "src/apps/minigame/happyball";
import {MinigameType} from "../../types/minigame";
import {VIEW_TYPE} from "./happyball/interface";

export default function Minigame ({isLanding=false, gameFetch}: {isLanding? :boolean; gameFetch: MinigameType}) {
    const [viewType, setViewType] = useState<VIEW_TYPE>("ball")
    useEffect(()=> {
        if(gameFetch.split('_')[1] == 'ladder'){
            setViewType('ladder')
        }else{
            setViewType('ball')
        }
    }, [gameFetch])
    return (
        <section>
            <LoadPowerBallGame isLanding={isLanding} gameFetchKey={gameFetch} viewType={viewType} />
        </section>
    );
}
Minigame.defaultProps = {
    isLanding: false,
    gameFetch: "happyball"
}

const PICK_data = [
    { "pick_name": "파워볼 홀짝", "ot": "홀", "lt": "짝", "o": 54, "l": 46, "highlights": "" },
    { "pick_name": "파워볼 언오", "ot": "언더", "lt": "오버", "o": 32, "l": 68, "highlights": "" },
    { "pick_name": "일반볼 홀짝", "ot": "홀", "lt": "짝", "o": 58, "l": 42, "highlights": "" },
    { "pick_name": "일반볼 언오", "ot": "언더", "lt": "오버", "o": 50, "l": 50, "highlights": "" },
]
const PICK_sum = [
    { "name": "대", "per": 33 },
    { "name": "중", "per": 33 },
    { "name": "소", "per": 33 }
]

function MinigameContents() {
    for(let i:number=0; i<4; i++){
        if(PICK_data[i].o > PICK_data[i].l){
            PICK_data[i].highlights = "o"
        }else if(PICK_data[i].o < PICK_data[i].l){
            PICK_data[i].highlights = "l"
        }
    }
    return (
        <div id={"minigame-contents"}>
            <div className={"user-pick"}>
                <div className={"inner"}>
                    <header>유저픽</header>
                    <main>
                        { PICK_data.map( (items, key)=>(
                            <div className={"pick-bar"} key={key}>
                                <div className={"pick-item " + `${ items.o > items.l ? "--bg-gb-odd" : "--bg-gb-even" }`}>{ items.pick_name }</div>
                                <div className={"pick-count"}>
                                    <PerCount count={items.o}/>
                                    <PerCount count={items.l}/>
                                </div>
                                <div className={"absolute-bar " + items.highlights}>
                                    <div>
                                        <span>{items.ot}</span>
                                        <PickBar className={"odd-under"} count={items.o}/>
                                    </div>
                                    <div>
                                        <span>{items.lt}</span>
                                        <PickBar className={"even-over"} count={items.l}/>
                                    </div>
                                </div>
                            </div>
                        ) ) }
                        <div className={"pick-sum"}>
                            { PICK_sum.map( (items, key)=>(
                                <div className={"sum-layer"} key={key}>
                                    <div>{items.name}</div>
                                    <div>
                                        <PerCount count={items.per}/>
                                    </div>
                                </div>
                            ) ) }
                        </div>
                    </main>
                </div>
            </div>
            <div className={"pick-result"}>
                <div className={"inner"}>
                    <header>추첨결과</header>
                    <main>
                        <ul>
                            {
                                [...Array(20)].map((_, index)=>(
                                    <li className={"result-bar"} key={index}>
                                        <p>144회차 236-9429</p>
                                        <div>
                                            <div className={"balls"}>
                                                <span className="num-1">1</span>
                                                <span className="num-7">7</span>
                                                <span className="num-15">15</span>
                                                <span className="num-20">20</span>
                                                <span className="num-28">28</span>
                                                <span className="powerball">1</span>
                                            </div>
                                            <div className={"ranges"}>
                                                <div className="rect even">P홀</div>
                                                <div className="rect under">P언</div>
                                                <div className="rect even">짝</div>
                                                <div className="rect over">오</div>
                                                <div className="rect small">소</div>
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </main>
                </div>
            </div>
        </div>
    );
}