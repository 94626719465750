import {createContext} from "react";
import {RankerType} from "../../../types/ranking";
import {GAME_PICK_VALUE_TYPE} from "../../../components/gamePicker";
import {PublicUserType} from "../../../types/user";

export type GameType =
    "power_ball_odd_even"
    | "power_ball_under_over"
    | "power_ball_section"
    | "balls_odd_even"
    | "balls_under_over"
    | "balls_size"
    | "balls_section"

export interface GameResultData {
    kind: string
    round: number
    game_draw_dt: string
    power_ball: number
    balls: number[]
}

export type VIEW_TYPE = "reel" | "ball" | "ladder" | "dh"
export type FETCH_RESULT_STATUS_TYPE = "NORMAL" | "MAINTENANCE"

export interface GameResult extends GameResultData {
    round_daily: number
    game_date: string
    game_time: string
    balls_sum: number

    power_ball_odd_even: 'ODD' | 'EVEN'
    power_ball_under_over: 'UNDER' | 'OVER'
    power_ball_section: 'A' | 'B' | 'C' | 'D'
    balls_odd_even: 'ODD' | 'EVEN'
    balls_under_over: 'UNDER' | 'OVER'
    balls_size: 'SMALL' | 'MEDIUM' | 'LARGE'
    balls_section: 'A' | 'B' | 'C' | 'D' | 'E' | 'F'
}
export interface LadderResults extends  GameResultData {
    round_daily: number
    game_date: string
    game_time: string
    balls_sum: number

    LEFT_RIGHT: "LEFT" | "RIGHT"
    ODD_EVEN: "ODD" | "EVEN"
    LINE: "LINE3" | "LINE4"
    COMB: COMB_TYPE
}
export type COMB_TYPE = "LEFT_4_ODD" | "LEFT_3_EVEN" | "RIGHT_3_ODD" | "RIGHT_4_EVEN"
export interface GameUserPick {
    game_pick: GAME_PICK_VALUE_TYPE
    id: number
    round_daily: number
    round_date: string
    user: RankerType
}

export interface GameResultStats {
    numOfTurn: number
    numOfSingle: number
    numOfMultiple: number
    maxLength: number
    countMap: any
    maxLengthMap: any
    maxSingleInRow: number
}
export interface GAME_BOARD_TIMER_INTERFACE {
    happyball: number,
    happyball3min: number,
    happyball_ladder: number,
    happyball3min_ladder: number,
    eos5: number,
    eos3: number,
    pbg5: number,
    ntry: number,
    ntry_ladder: number,
    dh: number,
}
export const GAME_BOARD_TIMER: any | GAME_BOARD_TIMER_INTERFACE = {
    // 게임별 데이터 fetch 타이머 (초)
    happyball: 306,
    happyball3min: 180,
    happyball_ladder: 306,
    happyball3min_ladder: 180,
    eos5: 300,
    eos3: 180,
    pbg5: 299,
    ntry: 300,
    ntry_ladder: 300,
    dh: 300,
}
export const GAME_FETCH_DELAY: any | GAME_BOARD_TIMER_INTERFACE = {
    // 게임별 데이터 fetch 타이머 딜레이 (밀리초)
    happyball: 6500,
    happyball3min: 10000,
    happyball_ladder: 6500,
    happyball3min_ladder: 10000,
    eos5: -2000,
    eos3: -4000,
    pbg5: 0,
    ntry: -18000,
    ntry_ladder: -18000,
    dh: -35000,
}
export const GAME_COUNT_TYPE: any | GAME_BOARD_TIMER_INTERFACE = {
    // 게임별 진행 시간 (분)
    happyball: 5,
    happyball3min: 3,
    happyball_ladder: 5,
    happyball3min_ladder: 3,
    eos5: 5,
    eos3: 3,
    pbg5: 5,
    ntry: 5,
    ntry_ladder: 5,
    dh: 5,
}
export const GAME_LAST_ROUND: any | GAME_BOARD_TIMER_INTERFACE = {
    // 게임별 마지막 라운드
    happyball: 288,
    happyball3min: 480,
    happyball_ladder: 288,
    happyball3min_ladder: 480,
    eos5: 288,
    eos3: 480,
    pbg5: 288,
    ntry: 288,
    ntry_ladder: 288,
    dh: 288,
}
/** hidden tab
 *  "power_ball_section", "balls_section"
 *
 * */
export const GAME_TYPES: GameType[] = ["power_ball_odd_even", "power_ball_under_over", "balls_odd_even", "balls_under_over", "balls_size"]
export const GAME_TYPES_DH: GameType[] = ["balls_odd_even", "power_ball_under_over", "balls_size"]

export const GAME_TYPE_OPTIONS = {
    power_ball_odd_even: ['ODD', 'EVEN'],
    power_ball_under_over: ['UNDER', 'OVER'],
    power_ball_section: ['A', 'B', 'C', 'D'],
    balls_odd_even: ['ODD', 'EVEN'],
    balls_under_over: ['UNDER', 'OVER'],
    balls_size: ['SMALL', 'MEDIUM', 'LARGE'],
    balls_section: ['A', 'B', 'C', 'D', 'E', 'F'],
}
export const LADDER_OPTIONS = {
    LEFT_RIGHT: ["LEFT", "RIGHT"],
    ODD_EVEN: ["ODD", "EVEN"],
    LINE: ["LINE3", "LINE4"],
    COMB: ["LEFT_3_EVEN", "LEFT_4_ODD", "RIGHT_3_ODD", "RIGHT_4_EVEN"],
}
export type LadderType = "START" | "LINE" | "END"
export const LADDER_RESULT_TYPES: LadderType[] = ["START", "LINE", "END"]
export const LADDER_RESULT_TYPE_OPTIONS = {
    START: ["LEFT", "RIGHT"],
    LINE: [3, 4],
    END: ["ODD", "EVEN"],
}
interface LADDER_LABELS_TYPE {
    LEFT: string
    RIGHT: string
    ODD: string
    EVEN: string
    LINE3: string
    LINE4: string

    ODD_EVEN: string
    LEFT_RIGHT: string
    LINE: string
    COMB: string

    LEFT_3_EVEN: string
    LEFT_4_ODD: string
    RIGHT_3_ODD: string
    RIGHT_4_EVEN: string
}
export const LADDER_LABELS: LADDER_LABELS_TYPE = {
    LEFT: "좌",
    RIGHT: "우",
    ODD: "홀",
    EVEN: "짝",
    LINE3: '3',
    LINE4: '4',

    ODD_EVEN: "홀짝패턴",
    LEFT_RIGHT: "좌우패턴",
    LINE: "사다리수패턴",
    COMB: "조합패턴",

    LEFT_3_EVEN: "좌3짝",
    LEFT_4_ODD: "좌4홀",
    RIGHT_3_ODD: "우3홀",
    RIGHT_4_EVEN: "우4짝",
}
export const LABELS = {
    power_ball_odd_even: "파워볼-홀짝",
    power_ball_under_over: "파워볼-오버언더",
    power_ball_section: "파워볼-구간",
    balls_odd_even: "일반볼-홀짝",
    balls_under_over: "일반볼-오버언더",
    balls_size: "일반볼-대중소",
    balls_section: "일반볼-구간",
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    SMALL: "소",
    MEDIUM: '중',
    LARGE: '대',
    ODD: "홀",
    EVEN: "짝",
    UNDER: "언",
    OVER: "오",
}

export const STANDARS = {
    power_BALL_UNDER_OVER: 4.5,
    power_BALL_A_B: 2.5,
    power_BALL_B_C: 4.5,
    power_BALL_C_D: 6.5,
    BALL_UNDER_OVER: 72.5,
    BALL_SMALL_MEDIUM: 64.5,
    BALL_MEDIUM_LARGE: 80.5,
    BALL_A_B: 35.5,
    BALL_B_C: 49.5,
    BALL_C_D: 57.5,
    BALL_D_E: 65.5,
    BALL_E_F: 78.5
}

export const DEFAULT_STATS_TYPE = GAME_TYPES[0]
export const DEFAULT_STATS_TYPE_DH = GAME_TYPES_DH[0]

declare global {
    interface Array<T> {
        last(): T
        first(): T
        numbericSort(): T
    }
}

Array.prototype.last = function () {
    return this[this.length - 1];
};
Array.prototype.first = function () {
    return this[0];
};
Array.prototype.numbericSort = function () {
    return this.sort(function (a, b) { return a - b; });
};
