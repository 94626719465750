export function RankWarning() {
    return (
        <div className={"warning"}>
            <header>레벨 랭킹에 대한 안내입니다.</header>
            <main>회원님들의 누적 경험치에 따른 레벨을 바탕으로 순위를 산정합니다. <br />
                레벨이 같을 경우 경험치가 많을 수록 랭킹 순위가 올라갑니다.
            </main>
        </div>
    );
}

export function PickWarning() {
    return (
        <div className={"warning"}>
            <header>픽공유 랭킹에 대한 안내입니다.</header>
            <main>특정 게임에 대한 픽(결과 예측)을 입력한 회원분들의 종합 랭킹 페이지입니다. <br />
                게임 누적 참여 횟수 30 회 미만의 회원은 랭킹에 등록되지 않습니다. 최근 참여 100 게임 기준 상위 30 위 까지 제공합니다.
            </main>
        </div>
    );
}