import { Button } from "react-bootstrap";
import { Form } from "react-router-dom";
import Btn from "src/components/form/btn";
import Flex from "src/components/layout/flex";
import { DateRange } from "./item_history";

export default function HistorySearchFilter({ isMobile }: { isMobile: boolean }) {
  const desktop = (
    <div className={"board-search-form"}>
      <div>
        <label className="form-radio">
          <input type="radio" name="type" defaultChecked={true} />
          <span className="btn">전체</span>
        </label>
        <label className="form-radio">
          <input type="radio" name="type" />
          <span className="btn">획득</span>
        </label>
        <label className="form-radio">
          <input type="radio" name="type" />
          <span className="btn">사용</span>
        </label>
      </div>

      <Form className={"d-flex --fl-items-center --gap-10"}>
        <div className="form-date-range">
          <div>시작날짜</div>
          <DateRange range={"from"} />
          <div>종료날짜</div>
          <DateRange range={"to"} />
        </div>
        <Btn type={"default"}>검색</Btn>
      </Form>
    </div>
  );
  const mobile = (
    <Form className={"board-search-form-mo"}>
      <Flex justifyContent={"between"}>
        <div>
          <label className="form-radio">
            <input type="radio" name="type" defaultChecked={true} />
            <span className="btn">전체</span>
          </label>
          <label className="form-radio">
            <input type="radio" name="type" />
            <span className="btn">획득</span>
          </label>
          <label className="form-radio">
            <input type="radio" name="type" />
            <span className="btn">사용</span>
          </label>
        </div>

        <Btn className={"submit"}>검색</Btn>
      </Flex>

      <div className={"--mt-10"}>
        <Flex className={"form-date-range"} justifyContent={"between"}>
          <div>
            <p>시작날짜</p>
            <DateRange range={"from"} />
          </div>
          <div>
            <p>종료날짜</p>
            <DateRange range={"to"} />
          </div>
        </Flex>
      </div>
    </Form>
  );
  return isMobile ? mobile : desktop;
}
