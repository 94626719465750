import React, {useEffect, useState} from 'react';
import {board_head_interface} from "../../apps/community/header/box";
import {Link, useNavigate} from "react-router-dom";

type board_type = "all" | "free" | "humor" | "picture" | "video" | "sports" | "ready"
const board_name = {
    all:     { name:"모아보기",        page: "community", page_size: 9 },
    free:    { name:"자유 게시판",      page: "community", page_size: 9 },
    humor:   { name:"유머 게시판",      page: "community", page_size: 9 },
    picture: { name:"사진 게시판",      page: "community", page_size: 8 },
    video:   { name:"동영상 게시판",    page: "community", page_size: 8 },
    sports:  { name:"스포츠분석 게시판", page: "community", page_size: 9 },
    ready:   { name:"준비중",    page: "", page_size: 9 },
}
function LandingBoardBox({board_type}: {board_type: board_type}) {
    const [selectType, setTypeTab] = useState<board_type>(board_type)
    const [boardList, setBoardList] = useState<board_head_interface[]>([])
    const navigate = useNavigate()

    function getCommunityName(type: string){
        let return_text: string = "";
        switch(type){
            case "free" :
                return_text = "자유";
                break;
            case "humor" :
                return_text = "유머";
                break;
            case "picture" :
                return_text = "사진";
                break;
            case "video" :
                return_text = "비디오";
                break;
            case "sports" :
                return_text = "스포츠분석";
                break;
        }
        return return_text;
    }
    useEffect(() => {
        const page_size = board_name[selectType].page_size
        let fetch_url: string
        if(selectType == "all") {
            fetch_url = `/api/community/posts/?page_size=${page_size}`
        }else{
            fetch_url = `/api/community/posts/?type=${selectType}&page_size=${page_size}`
        }

        fetch(fetch_url)
            .then(res => res.json())
            .then((data) => {
                setBoardList(data.results)
            })
            .catch(err => alert(JSON.stringify(err)))
        // if(board_type == "analyses"){
        //     fetch(`/api/sports/analyses/?page_size=9`)
        //         .then(res => res.json())
        //         .then((data) => {
        //             setBoardList(data.results)
        //         })
        //         .catch(err => alert(JSON.stringify(err)))
        // }else{
        //     fetch(`/api/community/posts/?type=${selectType}&page_size=${page_size}`)
        //         .then(res => res.json())
        //         .then((data) => {
        //             setBoardList(data.results)
        //         })
        //         .catch(err => alert(JSON.stringify(err)))
        // }
    }, [selectType])
    return (
        <div className={"board-box landing"}>
            <header onClick={e=> {
                navigate(`/${board_name[selectType].page}/${selectType}/`);
            }}>{board_name[selectType].name}</header>
            <main>
                <ul className={selectType}>
                    {
                        boardList ? boardList.map((data, index)=>{
                                        const link = `/${board_name[selectType].page}/${selectType}/${data.id}`
                                        if( selectType == "picture" || selectType == "video" ){
                                            return (
                                                <li key={data.id} onClick={e=> navigate(link)}>
                                                    <img src={data.thumbnail} alt=""/>
                                                </li>
                                            )
                                        }else if(selectType == "all"){
                                            return (
                                                <li key={data.id} onClick={e=> navigate(link)}>
                                                    <b>[{getCommunityName(data.type)}]</b>
                                                    <p>{data.title}</p>
                                                </li>
                                            )
                                        }else{
                                            return (
                                                <li key={data.id} onClick={e=> navigate(link)}>
                                                    <p>{data.title}</p>
                                                </li>
                                            )
                                        }
                                    })
                            : <></>
                    }
                </ul>
            </main>
        </div>
    );
}

export default LandingBoardBox;