import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CommentsComponent, { Comment } from "src/components/comments";
import PostDetailComponent from "src/components/post/PostDetailComponent";
import { PostType } from "src/types/post";
import api from "src/utils/api2";
import CommunityPostListPage from "./list";

interface PostApiData {
  post: PostType;
  comments: Comment[];
}

export default function CommunityPostDetailPage() {
  const { type, id: postId } = useParams();
  const [post, setPost] = useState<PostType>();
  const [comments, setComments] = useState<Comment[]>([]);
  const navigate = useNavigate();

  let apiUrl = `/api/community/posts`;

  useEffect(() => {
    window.scrollTo({ top: 0 });
    api(`${apiUrl}/${postId}/with_comments/`)
      .then((res) => res.json())
      .then((data: PostApiData) => {
        setPost(data.post);
        setComments(data.comments);
      })
      .catch((res: Response) => CommunityApiErrorHandler(res));
  }, [postId]);

  async function CommunityApiErrorHandler(res: Response) {
    switch (res.status) {
      case 403: // Forbidden
        const err = await res.json();
        alert(err.detail);
        break;
      case 404: // Not Found
        alert("존재하지 않는 게시물 입니다.");
        navigate(".");
        break;
      case 413:
        alert("파일 최대 첨부 용량은 총 10MB 입니다.");
        break;
      default:
        alert("오류가 발생하였습니다.");
    }
  }

  async function updatePost(post: PostType) {
    return api(`${apiUrl}/${postId}/`, {
      method: "PATCH",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(post),
    })
      .then((res) => res.json())
      .then((data: PostType) => setPost(data))
      .catch((res: Response) => CommunityApiErrorHandler(res));
  }

  async function deletePost() {
    return api(`${apiUrl}/${postId}/`, { method: "DELETE" })
      .then(() => navigate(`/community/${type}/`))
      .catch((res: Response) => CommunityApiErrorHandler(res));
  }

  if (!post || !postId) {
    return <h1>로딩중</h1>;
  }

  return (
    <>
      <PostDetailComponent post={post} updatePost={updatePost} deletePost={deletePost} />
      <CommentsComponent postId={postId} comments={comments} setComments={setComments} />
      <CommunityPostListPage />
    </>
  );
}
