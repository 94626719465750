import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";

import PostListComponent from "src/components/post/PostListComponent";
import useUser from "src/hooks/useUser";
import { PostType } from "src/types/post";
import api from "src/utils/api";
const LABEL: { [key: string]: string } = {
  notices: "공지사항",
  tickets: "1:1 문의",
  reports: "버그 & 건의",
};

interface ServiceCenterPostsApi {
  count: number;
  results: PostType[];
}

export default function ServiceCenterPostListPage({ inline = false }: { inline?: boolean }) {
  const [posts, setPosts] = useState<PostType[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageNum, setPageNum] = useState<number>();
  const [searchParams] = useSearchParams();
  const { type } = useParams();
  const location = useLocation();
  const { user } = useUser();

  useEffect(() => {
    // console.log(type)
    api(`/api/service_center/${type}/?${searchParams}`)
      .then((res) => res.json())
      .then((data: ServiceCenterPostsApi) => {
        setPosts(data.results);
        setCount(data.count);
        setPageNum(Number(searchParams.get("page")) || 1);
      })
      .catch((error) => alert(JSON.stringify(error)));
  }, [location]);

  const listPath = `/service_center/${type}`;

  if (type === "tickets") {
    return <PostListComponent listPath={listPath} posts={posts} totalCount={count} isMobile={isMobile} showCreate={true} Header={!inline && WarningQNA} SearchForm={!inline && !isMobile && <TicketsSearchForm listPath={listPath} />} />;
  }

  return <PostListComponent listPath={listPath} posts={posts} totalCount={count} isMobile={isMobile} showCreate={user?.is_staff} />;
}

export function TicketsSearchForm({ listPath }: { listPath: string }) {
  const { user } = useUser();
  return (
    <header>
      <div className={"board-search-form"}>
        <div>
          <label className={"form-radio"}>
            <input type="radio" name={"type"} checked readOnly={true} />
            <span className={"btn"}>전체</span>
          </label>
          <label className={"form-radio"}>
            <input type="radio" name={"type"} />
            <span className={"btn"}>대기</span>
          </label>
          <label className={"form-radio last"}>
            <input type="radio" name={"type"} />
            <span className={"btn"}>완료</span>
          </label>
        </div>

        <Link
          className={"write-new"}
          to={`${listPath}/new`}
          onClick={(e) => {
            if (user?.is_anonymous) {
              e.preventDefault();
              alert("로그인 후 이용가능합니다.");
            }
          }}
        >
          글쓰기
        </Link>

        <label className={"form-title"}>
          <p>제목</p>
          <input name={"title"} />
          <span className={"btn"}>검색</span>
        </label>
      </div>
    </header>
  );
}

function WarningQNA() {
  return (
    <div className={"warning"}>
      <header>문의하기 전에 꼭 읽어주세요.</header>
      <main>
        본 게시판은 회원 정보나 서비스 이용 중 오류 등 운영진에게 개인적으로 직접적인 문의와 답변을 원하실 때 이용하시는 공간입니다. <br />
        사이트 이용 방법은 공지사항, 자주 묻는 질문에 마련돼있으니 먼저 검색을 부탁드립니다. <br />
        게시물 및 댓글, 쪽지에 대한 신고는 해당 게시물과 댓글, 쪽지에 있는 신고 버튼을 이용해주시기 바랍니다. <br />
        버그 제보나 건의, 분석기 추가 요청은 해당 게시판을 이용해주시기 바랍니다. <br />
        문의시 사이트 비방 혹은 운영자에 대한 욕설 / 반말이 포함될 경우 문의 내용과 관계 없이 해당 유저에게 경고 / 전과 패널티가 부여될 수 있습니다. <br />
        문의시 기본 매너를 지켜주시기 바랍니다(운영자에 대한 지속적인 비매너 행위시 전과 패널티 / IP 차단 등의 조치가 이루어질 수 있습니다).
      </main>
    </div>
  );
}
