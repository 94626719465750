import Layout from "./_layout"
// import ServiceCenterPostListPage from "./posts"
// import ServiceCenterPostDetailPage from "./post"
import ServiceCenterPostListPage from "./list"
import ServiceCenterPostDetailPage from "./detail"
import ServiceCenterPostCreatePage from "./create"

const serviceCenterRoute = {
    path: "service_center",
    element: <Layout />,
    children: [
        {
            path: ":type", children: [
                { path: "", element: <ServiceCenterPostListPage /> },
                { path: ":id", element: <ServiceCenterPostDetailPage /> },
                { path: "new", element: <ServiceCenterPostCreatePage /> },
            ]
        }
    ],
}

export default serviceCenterRoute