import { ToastProvider } from "src/contexts/toastProvider";
import { UserSocketProvider } from "src/contexts/userSocketProvider";
import PeeksContainer from "../components/peeks/peeks_container";
import { ToggleDeviceMode } from "./device";
import Footer from "./footer";
import M_MainContainer from "./mo/main_container";
import MLHeader from "./mo/ml_header";
import GLHeader from "./pc/gl_header";
import MainContainer from "./pc/main_container";
import Aside from "./pc/aside";

export default class Layout extends ToggleDeviceMode {
    Desktop = (
        <ToastProvider>
            <UserSocketProvider>
                <div className={"__body"}>
                    <GLHeader />
                    <PeeksContainer>
                        <div id={"divide-aside-main"}>
                            <Aside />         {/* chat , pick */}
                            <MainContainer /> {/* #main__container > Outlet */}
                        </div>
                    </PeeksContainer>
                    <Footer />
                </div>
            </UserSocketProvider>
        </ToastProvider>
    );

    Mobile = (
        <ToastProvider>
            <UserSocketProvider>
                <div className={"m__body"}>
                    <MLHeader />
                    <M_MainContainer />
                    <Footer />
                </div>
            </UserSocketProvider>
        </ToastProvider>
    );
}
