import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { Nav } from "react-bootstrap";

export interface tab_interface {
    eventKey: string,
    text: string
}
export default function CategoryTab({ tab_list }: { tab_list: tab_interface[]; }) {
    const [list, setList] = useState<tab_interface[]>(tab_list)
    const [selectTab, setSelectTab] = useState<string>(list.first().eventKey)
    const { category } = useParams()

    useEffect(() => {
        if (category) {
            setSelectTab(category)
        } else {
            const locationPath = (window.location.pathname).split('/')[3]
            if (locationPath) {
                setSelectTab(locationPath)
            }
        }
    })
    return (
        <div className={"category-tab"}>
            {
                list.map((obj, index) => (
                    <Nav.Link key={index}
                        as={Link}
                        to={`${obj.eventKey}/`}
                        onClick={() => setSelectTab(obj.eventKey)}
                        className={selectTab == obj.eventKey ? "select" : ""}
                        eventKey={obj.eventKey}>{obj.text}</Nav.Link>
                ))
            }

        </div>
    );
}
