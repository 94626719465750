import { useNavigate, useParams } from "react-router-dom";

import { Comment } from "src/components/comments";
import { PostType } from "src/types/post";
import PostCreateComponent from "src/components/post/PostCreateComponent";
import api from "src/utils/api";
import ServiceCenterPostListPage from "./list";

type ServiceCenterPostApi = {
  post: ServiceCenterPost;
  comments: Comment[];
};

interface ServiceCenterPost extends PostType {}

export default function ServiceCenterPostCreatePage() {
  const { type } = useParams();
  const navigate = useNavigate();
  const admin_required = type === "notices";

  function createPost(newPost: {}) {
    api(`/api/service_center/${type}/`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(newPost),
    })
      .then((res) => res.json())
      .then((data: ServiceCenterPost) => navigate(`/service_center/${type}/${data.id}`))
      .catch((err) => window.alert(JSON.stringify(err)));
  }

  return (
    <>
      <PostCreateComponent createPost={createPost} />
      <ServiceCenterPostListPage inline={true} />
    </>
  );
}
