import ScoreBoardPage from "./sports/score_board"
import AnalysisListPage from "./analysis/list"
import AnalysisDetailPage from "./analysis/detail"
import AnalysisCreatePage from "./analysis/create"
import SportsLayout from "./_layout"
import GetSportsScore from "./sports/get_sports_score";

const sportsRoute = {
    path: "sports",
    element: <SportsLayout />,
    children: [
        { path: "", element: <GetSportsScore/> },
        { path: ":sports_name", element: <GetSportsScore/> }
        // { path: "", element: <ScoreBoardPage /> },
        // { path: "", element: <AnalysisListPage /> },
        // {
        //     path: "analysis", children: [
        //         { path: "", element: <AnalysisListPage /> },
        //         { path: ":id", element: <AnalysisDetailPage /> },
        //         { path: "new", element: <AnalysisCreatePage /> },
        //     ]
        // }
    ]
}

export default sportsRoute