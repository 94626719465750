import React from 'react';

function SportsIcon(props: {icon: string; size: number}) {
    const size = "--font-" + props.size
    return (
        <i className={`${"--i sports " + props.icon + " " + size}`}></i>
    );
}
SportsIcon.defaultProps = {
    size: 10
}
export default SportsIcon;