import { RouteObject } from "react-router-dom";
import PickRoomList from "./pickRoom_list";

const roomRoute: RouteObject = {
    path: "room",
    children: [
        { path: "", element: <PickRoomList /> },
    ]
}

export default roomRoute