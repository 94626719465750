import React from "react";
import {Modal} from "react-bootstrap";
import JoinTemplate from "../../member/join_template";
import Btn from "../../../../components/form/btn";


export default function WithdrawUserModal({ show, setShow }: { show: boolean, setShow: any }) {
    const handleClose = () => {
        setShow(false);
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <JoinTemplate>
                    <p className={"align-center --font-18 --bold"}>회원 탈퇴 안내</p>

                    <div className={""}>
                        <p className={"--mt-40 --bold"}>회원 탈퇴 시 회원 정보 및 사용기록이 모두 삭제됩니다.</p>
                        <p className={"--mt-20"}>포인트, 캐시, 친구 등 모든 이용기록이 삭재되며, 삭제된 기록은 복구되지 않습니다.</p>

                        <p className={"--mt-20 --bold --color-red"}>탈퇴 후 30일 동안 동일한 이메일로 재가입이 불가능 합니다.</p>
                    </div>
                    <form className={"--mt-40"} onSubmit={async e => {
                        e.preventDefault();
                    }}>
                        <div className={"form-row --mb-15"}>
                            <strong>비밀번호 입력</strong>
                            <input type="password" name={"password"} placeholder={"비밀번호 입력"}/>
                        </div>

                        <button className={"btn btn-theme2"} type={"submit"}>회원 탈퇴</button>
                        
                        <Btn type={"gray"} className={"--mt-20"} onClick={handleClose}>회원탈퇴 취소</Btn>
                    </form>

                </JoinTemplate>
            </Modal.Body>
        </Modal>
    );
}