import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser, faComment} from '@fortawesome/free-solid-svg-icons';
import MobileUserPick from "./mobile_user_pick";
import api from "../../../../utils/api";
import {useInterval} from "usehooks-ts";
import {GameUserPick} from "../interface";
import {MinigameType} from "../../../../types/minigame";
import {GAME_PICK_VALUE_TYPE} from "../../../../components/gamePicker";
import UserLevelNamecard from "../../../../components/user/user_level_namecard";
import {OverlayTrigger} from "react-bootstrap";

function GameBoardUserPick({gameType, roundDate, round}: {gameType: MinigameType; roundDate: string; round: number}) {
    const [pickData, setPickData] = useState<GameUserPick[]>()
    function fetchUserPickList(){
        api(`/api/gamepick/${gameType}/${roundDate}/${round+1}/`)
            .then(res => res.json())
            .then(res => setPickData(res))
            .catch(e => setPickData(undefined))
    }
    useInterval(()=> {
        fetchUserPickList()
    }, 5000)
    useEffect(()=> {
        fetchUserPickList()
    }, [])
    return (
        <>
            <div className={`result-board left ${gameType}`}>
                <div className={"rotate-board"}>
                    <header className={"title"}>유저픽</header>
                    <main className={"contents"}>
                        <div className={"scroll"}>
                            <ul>
                            {
                                pickData?.map((data, index) =>
                                    <ListItem key={index} data={data}/>
                                )
                            }
                            </ul>
                        </div>
                    </main>
                </div>
            </div>
            <MobileUserPick pickData={pickData}/>
        </>
    );
}
export function ListItem({data}: { data: GameUserPick }){
    const winlose = (data.user.stats.streak > 0 ? `win` : `lose`)
    const winloseCount = (data.user.stats.streak > 0 ? data.user.stats.streak : data.user.stats.streak * -1)
    let addClass: string = ""
    if((data.game_pick).split('_')[0] == 'BALLS' || (data.game_pick).split('_')[0] == 'POWERBALL'){
        addClass = (data.game_pick).split('_')[1]
    }else{
        addClass = data.game_pick
    }
    return (
        <li className={"items"}>
            <div className={`win-count ${winlose}`}>{winloseCount}</div>
            {/*<div className={"icon"}>*/}
            {/*    <FontAwesomeIcon icon={faUser}/>*/}
            {/*</div>*/}
            {/*<div className="name">{data.user.nickname}</div>*/}
            <UserLevelNamecard user={data.user}/>

            <div className={`rect ${addClass}`}>{changeGamePickText(data.game_pick)}</div>

            <OverlayTrigger
                trigger="click"
                placement={"left-start"}
                rootClose={true}
                overlay={
                    <div className={"chat-overlay user-pick-overlay"}>
                        <main>
                            <header>
                                <UserLevelNamecard user={data.user} />
                            </header>
                            <ul>
                                <li onClick={e=> window.open(`/user/message-box/write/?to=${data.user.nickname}`)} >
                                    <a style={{ "display": "flex", "alignItems": "center" }}>
                                        <i className={"--icon message"}></i>쪽지
                                    </a>
                                </li>
                            </ul>
                        </main>
                    </div>
                }
            >
                <div className={"join-chat on"}>
                    <FontAwesomeIcon icon={faComment}/>
                </div>
            </OverlayTrigger>
        </li>
    )
}
const GAME_PICK_TEXT_ST: any = {
    POWERBALL_ODD: "P홀",
    POWERBALL_EVEN: "P짝",
    POWERBALL_UNDER: "P언",
    POWERBALL_OVER: "P오",
    BALLS_ODD: "홀",
    BALLS_EVEN: "짝",
    BALLS_UNDER: "언",
    BALLS_OVER: "오",
    BALLS_SMALL: "소",
    BALLS_MEDIUM: "중",
    BALLS_LARGE: "대",

    // ladder
    LEFT: "좌",
    RIGHT: "우",
    ODD: "홀",
    EVEN: "짝",
    THREE: "3줄",
    FOUR: "4줄",

    LEFT_THREE_EVEN: "좌3",
    LEFT_FOUR_ODD: "좌4",
    RIGHT_THREE_ODD: "우3",
    RIGHT_FOUR_EVEN: "우4",
}

function changeGamePickText(game_pick: GAME_PICK_VALUE_TYPE){
    return GAME_PICK_TEXT_ST[game_pick]
}

export default GameBoardUserPick;
