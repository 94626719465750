import React, {useContext, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser, faComment} from '@fortawesome/free-solid-svg-icons';
import {IframeContext} from "./iframe_provider";
import {GameUserPick} from "../interface";
import {ListItem} from "./game_board_user_pick";

function MobileUserPick({pickData}: {pickData : undefined | GameUserPick[]}) {
    const [openState, setOpenState] = useState<boolean>(false)
    const [openText, setOpenText] = useState<string>("유저픽")
    const [modalState, setModalState] = useState<boolean>(false)
    const {mobileModalState, toggleModalState} = useContext(IframeContext)
    function toggleState(){
        setOpenState(!openState)
        setModalState(!modalState)
        if(openState) {
            toggleModalState(0)
            setOpenText('유저픽')
        }else{
            toggleModalState(1)
            setOpenText('닫기')
        }
    }

    let leftStyle = {}
    if( mobileModalState == 2 ){
        leftStyle = { zIndex: 6 }
    }else{
        leftStyle = { zIndex: 8 }
    }
    return (
        <>
            <div className={"mobile-open user-pick"} style={leftStyle} onClick={toggleState}>{openText}</div>
            <div className={"result-board left mobile"} aria-expanded={openState}>
                <div className={"rotate-board"}>
                    <header className={"title"}>유저픽</header>
                    <main className={"contents"}>
                        <div className={"scroll"}>
                            <ul>
                                {
                                    pickData?.map((data, index) =>
                                        <ListItem key={index} data={data}/>
                                    )
                                }
                            </ul>
                        </div>
                    </main>
                </div>
            </div>
            {
                modalState ? <div className={"result-board-modal"}></div>
                    : ""
            }
        </>
    );
}

export default MobileUserPick;
