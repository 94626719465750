import {
    COMB_TYPE,
    DEFAULT_STATS_TYPE, DEFAULT_STATS_TYPE_DH,
    GAME_TYPE_OPTIONS,
    GAME_TYPES, GAME_TYPES_DH,
    GameResult,
    GameResultStats,
    GameType,
    LABELS, LADDER_LABELS, LADDER_OPTIONS, LadderResults,
    VIEW_TYPE
} from "../interface";
import React, {useEffect, useState} from "react";
import {getLadderLine, getLadderResult} from "../game/view_type/Ladder";
import moment from "moment";
import Flex from "../../../../components/layout/flex";

type LadderTabType = "ODD_EVEN" | "LEFT_RIGHT" | "LINE" | "COMB"
const DEFAULT_LADDER_TAB_TYPE: LadderTabType[] = ["ODD_EVEN", "LEFT_RIGHT", "LINE", "COMB"]
export function PoweballGameStats({ gameResults, viewType }: { gameResults: GameResult[]; viewType: VIEW_TYPE }) {
    const [statsType, setStatsType] = useState<GameType>(DEFAULT_STATS_TYPE)
    const [tabProps, setSelectTab] = useState(6)
    const matrix = makeGameResultsMatrix(gameResults, statsType);
    const stats = makeGameResultsStats(gameResults, statsType);
    return (
        <div id={"public-powerball-cell"}>
            <div className={"powerball-cell-tab"}>
                <TabUnderBar left={tabProps} />
                {GAME_TYPES.map((type, index) =>
                    <button key={index} className={statsType == type ? "select" : ""}
                            onClick={
                                (e) => {
                                    setStatsType(type)
                                    setSelectTab(e.currentTarget.offsetLeft)
                                }
                            }>{LABELS[type]}</button>
                )}
            </div>
            <div className={`${statsType}`}>
                <div className="additional">
                    <div className="summary">
                        {GAME_TYPE_OPTIONS[statsType].map((option, key_) =>
                            <div key={key_}>
                                <div>
                                    <span className={"round " + option as keyof typeof LABELS}>{LABELS[option as keyof typeof LABELS]}</span>
                                    {stats.countMap.get(option)}번
                                    <span className={"--font-10"}>({(stats.countMap.get(option) / [...stats.countMap.values()].reduce((a, b) => a + b, 0) * 100).toFixed(2)}%)</span>
                                    {" "}{stats.maxLengthMap.get(option)}연속
                                </div>
                                {GAME_TYPE_OPTIONS[statsType].length != (key_ + 1) ? <span className={"bar"}></span> : ""}
                            </div>
                        )}
                    </div>
                    <div>
                        <div><span className={"round Rjrdla"}>꺽임</span>{stats.numOfTurn}번</div>
                        {
                            statsType != 'balls_size'
                                ? <>
                                    <span className={"bar"}></span>
                                    <div><span className={"round vhdekd"}>퐁당</span>{stats.numOfSingle}번({(stats.numOfSingle / stats.numOfTurn * 100).toFixed(2) + '%'}) {stats.maxSingleInRow}연속</div>
                                    <span className={"bar"}></span>
                                    <div><span className={"round dusthr"}>연속</span>{stats.numOfMultiple}번({(stats.numOfMultiple / stats.numOfTurn * 100).toFixed(2) + '%'}) {Math.max(...stats.maxLengthMap.values())}연속</div>
                                </>
                                : ""
                        }
                    </div>
                </div>
                <div className={`powerball-cell-table ${viewType}`}>
                    <div className="scroll-area">
                        <div className={"scroll-layout"}>
                            {matrix.map((gameResults, index) => {
                                const currentGameType = gameResults[0][statsType];
                                const bar_height = gameResults.length * 30
                                return (
                                    <div key={index} className={"col " + `${currentGameType}`}>
                                        <span className={"head"}>{LABELS[currentGameType]}</span>
                                        <div className={"in-layout"}>
                                            {[...Array(stats.maxLength - gameResults.length + 1)].map((_, index) =>
                                                <span key={index}></span>
                                            )}
                                            { viewType == "reel" ?  <div className={"down-bar"} style={{ height: bar_height }}></div> : <></> }
                                            {gameResults.map((gameResult, index) => {
                                                    if( viewType == 'reel' ){
                                                        return (<span key={index}className={"in"}>{gameResult.round_daily}</span>)
                                                    }else if(viewType == 'ball'){
                                                        return (<span key={index}className={"in"}>
                                                            <span className="round">{gameResult.round_daily}</span>
                                                        </span>)
                                                    }
                                                }
                                            )}
                                        </div>
                                        <span className={"length"}>{gameResults.length}</span>
                                        <span className={"round"}>{matrix.length - index}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export function DhBallGameStats({ gameResults, viewType }: { gameResults: GameResult[]; viewType: VIEW_TYPE }) {
    const [statsType, setStatsType] = useState<GameType>(DEFAULT_STATS_TYPE_DH)
    const [tabProps, setSelectTab] = useState(6)
    const matrix = makeGameResultsMatrix(gameResults, statsType);
    const stats = makeGameResultsStats(gameResults, statsType);
    return (
        <div id={"public-powerball-cell"}>
            <div className={"powerball-cell-tab"}>
                <TabUnderBar left={tabProps} />
                {GAME_TYPES_DH.map((type, index) =>
                    <button key={index} className={statsType == type ? "select" : ""}
                            onClick={
                                (e) => {
                                    setStatsType(type)
                                    setSelectTab(e.currentTarget.offsetLeft)
                                }
                            }>{LABELS[type]}</button>
                )}
            </div>
            <div className={`${statsType}`}>
                <div className="additional">
                    <div className="summary">
                        {GAME_TYPE_OPTIONS[statsType].map((option, key_) =>
                            <div key={key_}>
                                <div>
                                    <span className={"round " + option as keyof typeof LABELS}>{LABELS[option as keyof typeof LABELS]}</span>
                                    {stats.countMap.get(option)}번
                                    <span className={"--font-10"}>({(stats.countMap.get(option) / [...stats.countMap.values()].reduce((a, b) => a + b, 0) * 100).toFixed(2)}%)</span>
                                    {" "}{stats.maxLengthMap.get(option)}연속
                                </div>
                                {GAME_TYPE_OPTIONS[statsType].length != (key_ + 1) ? <span className={"bar"}></span> : ""}
                            </div>
                        )}
                    </div>
                    <div>
                        <div><span className={"round Rjrdla"}>꺽임</span>{stats.numOfTurn}번</div>
                        {
                            statsType != 'balls_size'
                                ? <>
                                    <span className={"bar"}></span>
                                    <div><span className={"round vhdekd"}>퐁당</span>{stats.numOfSingle}번({(stats.numOfSingle / stats.numOfTurn * 100).toFixed(2) + '%'}) {stats.maxSingleInRow}연속</div>
                                    <span className={"bar"}></span>
                                    <div><span className={"round dusthr"}>연속</span>{stats.numOfMultiple}번({(stats.numOfMultiple / stats.numOfTurn * 100).toFixed(2) + '%'}) {Math.max(...stats.maxLengthMap.values())}연속</div>
                                </>
                                : ""
                        }
                    </div>
                </div>
                <div className={`powerball-cell-table ${viewType}`}>
                    <div className="scroll-area">
                        <div className={"scroll-layout"}>
                            {matrix.map((gameResults, index) => {
                                const currentGameType = gameResults[0][statsType];
                                const bar_height = gameResults.length * 30
                                return (
                                    <div key={index} className={"col " + `${currentGameType}`}>
                                        <span className={"head"}>{LABELS[currentGameType]}</span>
                                        <div className={"in-layout"}>
                                            {[...Array(stats.maxLength - gameResults.length + 1)].map((_, index) =>
                                                <span key={index}></span>
                                            )}
                                            { viewType == "reel" ?  <div className={"down-bar"} style={{ height: bar_height }}></div> : <></> }
                                            {gameResults.map((gameResult, index) => {
                                                    if( viewType == 'reel' ){
                                                        return (<span key={index}className={"in"}>{gameResult.round_daily}</span>)
                                                    }else if(viewType == 'ball'){
                                                        return (<span key={index}className={"in"}>
                                                            <span className="round">{gameResult.round_daily}</span>
                                                        </span>)
                                                    }
                                                }
                                            )}
                                        </div>
                                        <span className={"length"}>{gameResults.length}</span>
                                        <span className={"round"}>{matrix.length - index}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export function LadderGameStats({gameResults}: {gameResults: GameResult[]}){
    const [statsType, setStatsType] = useState<LadderTabType>(DEFAULT_LADDER_TAB_TYPE[0])
    const LadderResults = addLadderResult(gameResults)
    const [tabProps, setSelectTab] = useState(4)
    const matrix = makeLadderResultMatrix(LadderResults, statsType)
    const stats = makeLadderResultsStats(LadderResults, statsType);

    return (
        <div id={"public-powerball-cell"}>
            <div className={"powerball-cell-tab"}>
                <TabUnderBar left={tabProps} />
                {DEFAULT_LADDER_TAB_TYPE.map((type, index) =>
                    <button key={index} className={statsType == type ? "select" : ""}
                            onClick={
                                (e) => {
                                    setStatsType(type)
                                    setSelectTab(e.currentTarget.offsetLeft)
                                }
                            }>{LADDER_LABELS[type]}</button>
                )}
            </div>
            <div className={`${statsType}`}>
                <div className="additional">
                    <div className="summary">
                        {LADDER_OPTIONS[statsType].map((option, key_) =>
                            <div key={key_}>
                                <div>
                                    <span className={"round " + option as keyof typeof LADDER_LABELS}>{LADDER_LABELS[option as keyof typeof LADDER_LABELS]}</span>
                                    {stats.countMap.get(option)}번
                                    <span className={"--font-10"}>({(stats.countMap.get(option) / [...stats.countMap.values()].reduce((a, b) => a + b, 0) * 100).toFixed(2)}%)</span>
                                    {" "}{stats.maxLengthMap.get(option)}연속
                                </div>
                                {LADDER_OPTIONS[statsType].length != (key_ + 1) ? <span className={"bar"}></span> : ""}
                            </div>
                        )}
                    </div>
                    <div>
                        <div><span className={"round Rjrdla"}>꺽임</span>{stats.numOfTurn}번</div>

                        <span className={"bar"}></span>
                        <div><span className={"round vhdekd"}>퐁당</span>{stats.numOfSingle}번({(stats.numOfSingle / stats.numOfTurn * 100).toFixed(2) + '%'}) {stats.maxSingleInRow}연속</div>
                        <span className={"bar"}></span>
                        <div><span className={"round dusthr"}>연속</span>{stats.numOfMultiple}번({(stats.numOfMultiple / stats.numOfTurn * 100).toFixed(2) + '%'}) {Math.max(...stats.maxLengthMap.values())}연속</div>
                    </div>
                </div>
                <div className={`powerball-cell-table ladder`}>
                    <div className="scroll-area">
                        <div className={"scroll-layout"}>
                            {matrix.map((gameResults, index) => {
                                const currentGameType = gameResults[0][statsType];
                                const bar_height = gameResults.length * 30
                                return (
                                    <div key={index} className={"col " + `${currentGameType}`}>
                                        <span className={"head"}>{LADDER_LABELS[currentGameType]}</span>
                                        <div className={"in-layout"}>
                                            {[...Array(stats.maxLength - gameResults.length + 1)].map((_, index) =>
                                                <span key={index}></span>
                                            )}
                                            {gameResults.map((gameResult, index) =>
                                                <span key={index}className={"in"}>
                                                    <span className="round">{gameResult.round_daily}</span>
                                                </span>
                                            )}
                                        </div>
                                        <span className={"length"}>{gameResults.length}</span>
                                        <span className={"round"}>{matrix.length - index}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function makeLadderResultMatrix(LadderResults: LadderResults[], statsType: LadderTabType){
    let index = 0;
    let lastGameTypeOption;
    const LadderResultsMatrix: LadderResults[][] = []

    while (index < LadderResults.length) {
        const currentResult = LadderResults[index];
        const currentGameTypeOption = currentResult[statsType];

        if (currentGameTypeOption === lastGameTypeOption) {
            // 같은 타입이라면 마지막 배열에 추가
            LadderResultsMatrix.last().push(currentResult)
        } else {
            // 다른 타입이라면 새로운 배열 추가
            LadderResultsMatrix.push([currentResult])
        }
        lastGameTypeOption = currentGameTypeOption
        index += 1
    }
    return LadderResultsMatrix
}
function addLadderResult(results: GameResult[]){
    let index = 0
    let ladderResults: LadderResults[] = []
    while(index < results.length){
        const currentResult = results[index]
        const firstNumber =  currentResult.balls[0]
        const ladder_game_result = getLadderResult(firstNumber)
        const START = ladder_game_result.split('_')[0]
        const END = ladder_game_result.split('_')[1]
        const LINE = getLadderLine(firstNumber)

        const NewLadderResult: LadderResults = {
            kind: currentResult.kind,
            round: currentResult.round,
            game_draw_dt: currentResult.game_draw_dt,
            power_ball: currentResult.power_ball,
            balls: currentResult.balls,
            round_daily: currentResult.round_daily,
            game_date: currentResult.game_date,
            game_time: currentResult.game_time,
            balls_sum: currentResult.balls_sum,

            LEFT_RIGHT: START as 'LEFT' | 'RIGHT',
            ODD_EVEN: END as 'ODD' | 'EVEN',
            LINE: `LINE${LINE}`,
            COMB: `${START}_${LINE}_${END}` as COMB_TYPE,
        }

        ladderResults.push(NewLadderResult as LadderResults)

        index += 1
    }
    return ladderResults
}


// 타입에 맞게 연속되는 게임 결과를 묶어 Matrix로 만든다
function makeGameResultsMatrix(results: GameResult[], statsType: GameType): GameResult[][] {
    let index = 0;
    let lastGameTypeOption;
    const gameResultsMatrix: GameResult[][] = []

    while (index < results.length) {
        const currentResult = results[index];
        const currentGameTypeOption = currentResult[statsType];

        if(currentGameTypeOption){
            if (currentGameTypeOption === lastGameTypeOption) {
                // 같은 타입이라면 마지막 배열에 추가
                gameResultsMatrix.last().push(currentResult)
            } else {
                // 다른 타입이라면 새로운 배열 추가
                gameResultsMatrix.push([currentResult])
            }
            lastGameTypeOption = currentGameTypeOption
        }
        index += 1
    }
    return gameResultsMatrix
}

// 통계 자료를 뽑는다
function makeLadderResultsStats(gameResults: LadderResults[], statsType: LadderTabType,): GameResultStats {
    // 옵션별 빈도
    let countMap = new Map();
    // 옵션별 max length
    let maxLengthMap = new Map();

    for (const option of LADDER_OPTIONS[statsType]) {
        countMap.set(option, 0);
        maxLengthMap.set(option, 0);
    }
    // 꺽임
    let numOfTurn = 0;
    // 퐁당
    let countSingle = 0
    // 연속 퐁당 횟수
    let currentSingleInRow = 0;
    // 최대 연속 퐁당 횟수
    let maxSingleInRow = 0;
    // 현재 연속 횟수
    let numOfCurrentTurn = 0

    let lastType;
    for (const gameResult of gameResults) {
        const currentTypeOption = gameResult[statsType];

        countMap.set(currentTypeOption, countMap.get(currentTypeOption) + 1);
        // 처음인 경우
        if (!lastType) {
            numOfCurrentTurn = 1;
            numOfTurn++;
            currentSingleInRow = 1;
        }
        // 연속인 경우
        else if (currentTypeOption === lastType) {
            numOfCurrentTurn++;
            maxSingleInRow = Math.max(maxSingleInRow, currentSingleInRow);
            currentSingleInRow = 0;
        }
        // 꺽이는 경우
        else {
            if (numOfCurrentTurn === 1) {
                countSingle++;
            }
            numOfCurrentTurn = 1;
            numOfTurn++;
            currentSingleInRow++;
        }

        // 현재 연속횟수가 타입별 최고횟수를 넘는 경우
        if (numOfCurrentTurn > maxLengthMap.get(currentTypeOption)) {
            maxLengthMap.set(currentTypeOption, numOfCurrentTurn);
        }

        lastType = currentTypeOption;
    }

    if (numOfCurrentTurn === 1) {
        countSingle++;
    }


    return {
        numOfTurn,
        numOfSingle: countSingle,
        maxSingleInRow,
        numOfMultiple: numOfTurn - countSingle,
        countMap,
        maxLengthMap,
        maxLength: Math.max(...Array.from(maxLengthMap.values())),
    }
}
function makeGameResultsStats(gameResults: GameResult[], statsType: GameType): GameResultStats {
    // 옵션별 빈도
    let countMap = new Map();
    // 옵션별 max length
    let maxLengthMap = new Map();

    for (const option of GAME_TYPE_OPTIONS[statsType]) {
        countMap.set(option, 0);
        maxLengthMap.set(option, 0);
    }
    // 꺽임
    let numOfTurn = 0;
    // 퐁당
    let countSingle = 0
    // 연속 퐁당 횟수
    let currentSingleInRow = 0;
    // 최대 연속 퐁당 횟수
    let maxSingleInRow = 0;
    // 현재 연속 횟수
    let numOfCurrentTurn = 0

    let lastType;
    for (const gameResult of gameResults) {
        const currentTypeOption = gameResult[statsType];

        countMap.set(currentTypeOption, countMap.get(currentTypeOption) + 1);
        // 처음인 경우
        if (!lastType) {
            numOfCurrentTurn = 1;
            numOfTurn++;
            currentSingleInRow = 1;
        }
        // 연속인 경우
        else if (currentTypeOption === lastType) {
            numOfCurrentTurn++;
            maxSingleInRow = Math.max(maxSingleInRow, currentSingleInRow);
            currentSingleInRow = 0;
        }
        // 꺽이는 경우
        else {
            if (numOfCurrentTurn === 1) {
                countSingle++;
            }
            numOfCurrentTurn = 1;
            numOfTurn++;
            currentSingleInRow++;
        }

        // 현재 연속횟수가 타입별 최고횟수를 넘는 경우
        if (numOfCurrentTurn > maxLengthMap.get(currentTypeOption)) {
            maxLengthMap.set(currentTypeOption, numOfCurrentTurn);
        }

        lastType = currentTypeOption;
    }

    if (numOfCurrentTurn === 1) {
        countSingle++;
    }


    return {
        numOfTurn,
        numOfSingle: countSingle,
        maxSingleInRow,
        numOfMultiple: numOfTurn - countSingle,
        countMap,
        maxLengthMap,
        maxLength: Math.max(...Array.from(maxLengthMap.values())),
    }
}

// 사다리 그래프 오브젝트
export function getLadderGraphDataObject(gameResults: GameResult[]){
    const graphData = makeLadderGraphData(gameResults)
    // console.log(graphData)
    const obj = {
        start: {
            perCount: {
                left: graphData.get('START').get('LEFT'),
                right: graphData.get('START').get('RIGHT')
            },
            percent: {
                left: Number((graphData.get('START').get('LEFT') / gameResults.length * 100).toFixed(2)),
                right: Number((graphData.get('START').get('RIGHT') / gameResults.length * 100).toFixed(2)),
            },
        },
        line: {
            perCount: {
                line3: graphData.get('LINE').get(3),
                line4: graphData.get('LINE').get(4)
            },
            percent: {
                line3: Number((graphData.get('LINE').get(3) / gameResults.length * 100).toFixed(2)),
                line4: Number((graphData.get('LINE').get(4) / gameResults.length * 100).toFixed(2)),
            },
        },
        end: {
            perCount: {
                odd: graphData.get('END').get('ODD'),
                even: graphData.get('END').get('EVEN')
            },
            percent: {
                odd: Number((graphData.get('END').get('ODD') / gameResults.length * 100).toFixed(2)),
                even: Number((graphData.get('END').get('EVEN') / gameResults.length * 100).toFixed(2)),
            },
        },
        comb: {
            perCount: {
                LEFT_3_EVEN: graphData.get('COMB').get('LEFT_3_EVEN'),
                LEFT_4_ODD: graphData.get('COMB').get('LEFT_4_ODD'),
                RIGHT_3_ODD: graphData.get('COMB').get('RIGHT_3_ODD'),
                RIGHT_4_EVEN: graphData.get('COMB').get('RIGHT_4_EVEN')
            },
            percent: {
                LEFT_3_EVEN: Number((graphData.get('COMB').get('LEFT_3_EVEN') / gameResults.length * 100).toFixed(2)),
                LEFT_4_ODD: Number((graphData.get('COMB').get('LEFT_4_ODD') / gameResults.length * 100).toFixed(2)),
                RIGHT_3_ODD: Number((graphData.get('COMB').get('RIGHT_3_ODD') / gameResults.length * 100).toFixed(2)),
                RIGHT_4_EVEN: Number((graphData.get('COMB').get('RIGHT_4_EVEN') / gameResults.length * 100).toFixed(2)),
            },
        },
    }

    return obj
}
// 파워볼 그래프 오브젝트
export function getGraphDataObject(gameResults: GameResult[] ) {
    const graphData = makeGraphData(gameResults)
    const Obj = {
        powerBall: {
            perCount: {
                odd: graphData.get('power_ball_odd_even').get('ODD'),
                even: graphData.get('power_ball_odd_even').get('EVEN'),
                under: graphData.get('power_ball_under_over').get('UNDER'),
                over: graphData.get('power_ball_under_over').get('OVER'),
            },
            percent: {
                odd: Number((graphData.get('power_ball_odd_even').get('ODD') / gameResults.length * 100).toFixed(2)),
                even: Number((graphData.get('power_ball_odd_even').get('EVEN') / gameResults.length * 100).toFixed(2)),
                under: Number((graphData.get('power_ball_under_over').get('UNDER') / gameResults.length * 100).toFixed(2)),
                over: Number((graphData.get('power_ball_under_over').get('OVER') / gameResults.length * 100).toFixed(2)),
            }
        },
        normalBall: {
            perCount: {
                odd: graphData.get('balls_odd_even').get('ODD'),
                even: graphData.get('balls_odd_even').get('EVEN'),
                under: graphData.get('balls_under_over').get('UNDER'),
                over: graphData.get('balls_under_over').get('OVER'),
            },
            percent: {
                odd: Number((graphData.get('balls_odd_even').get('ODD') / gameResults.length * 100).toFixed(2)),
                even: Number((graphData.get('balls_odd_even').get('EVEN') / gameResults.length * 100).toFixed(2)),
                under: Number((graphData.get('balls_under_over').get('UNDER') / gameResults.length * 100).toFixed(2)),
                over: Number((graphData.get('balls_under_over').get('OVER') / gameResults.length * 100).toFixed(2)),
            }
        },
        sml: {
            perCount: {
                s: Number(graphData.get('balls_size').get('SMALL')),
                m: Number(graphData.get('balls_size').get('MEDIUM')),
                l: Number(graphData.get('balls_size').get('LARGE'))
            },
            percent: {
                s: Number((graphData.get('balls_size').get('SMALL') / gameResults.length * 100).toFixed(2)),
                m: Number((graphData.get('balls_size').get('MEDIUM') / gameResults.length * 100).toFixed(2)),
                l: Number((graphData.get('balls_size').get('LARGE') / gameResults.length * 100).toFixed(2))
            }
        }
    }
    return Obj
}

export type LadderTypeGraph = "START" | "LINE" | "END" | "COMB"
export const LADDER_RESULT_GRAPH_TYPES: LadderTypeGraph[] = ["START", "LINE", "END", "COMB"]
export const LADDER_RESULT_GRAPH_TYPE_OPTIONS = {
    START: ["LEFT", "RIGHT"],
    LINE: [3, 4],
    END: ["ODD", "EVEN"],
    COMB: ["LEFT_3_EVEN", "LEFT_4_ODD", "RIGHT_3_ODD", "RIGHT_4_EVEN"],
}
export function makeLadderGraphData(gameResults: GameResult[]){
    const map = new Map()
    for (const ladderType of LADDER_RESULT_GRAPH_TYPES) {
        const optionMap = new Map()
        const options = LADDER_RESULT_GRAPH_TYPE_OPTIONS[ladderType]
        for (const option of options) {
            optionMap.set(option, 0)
        }
        map.set(ladderType, optionMap)
    }

    for (const gameResult of gameResults) {
        const firstNumber = gameResult.balls[0]
        const ladder_result = getLadderResult(firstNumber)

        const ladder_start = ladder_result.split("_")[0]
        const ladder_end = ladder_result.split("_")[1]
        const ladder_line = getLadderLine(firstNumber)
        const ladder_comb = `${ladder_start}_${ladder_line}_${ladder_end}`
        // console.log(ladder_start, ladder_line, ladder_end)
        map.get('START').set(ladder_start, map.get('START').get(ladder_start) + 1)
        map.get('LINE').set(ladder_line, map.get('LINE').get(ladder_line) + 1)
        map.get('END').set(ladder_end, map.get('END').get(ladder_end) + 1)
        map.get('COMB').set(ladder_comb, map.get('COMB').get(ladder_comb) + 1)
    }

    return map;
}
export function makeGraphData(gameResults: GameResult[]) {
    const map = new Map()
    for (const gameType of GAME_TYPES) {
        const optionMap = new Map()
        const options = GAME_TYPE_OPTIONS[gameType]
        for (const option of options) {
            optionMap.set(option, 0)
        }
        map.set(gameType, optionMap)
    }

    for (const gameResult of gameResults) {
        for (const type of Array.from(map.keys()) as GameType[]) {
            const option = gameResult[type]
            map.get(type).set(option, map.get(type).get(option) + 1)
        }
    }
    return map
}

export function DhBallResultTable({ gameResults }: { gameResults: GameResult[] }) {
    const [hiddenCount, setHiddenCount] = useState<number>(20)
    const [maxCount, setMaxCount] = useState<number>(gameResults.length)

    return (
        <>
            <div className={"dh-result-table"}>
                <table>
                    <thead>
                    <tr>
                        <th rowSpan={2}>회차</th>
                        <th rowSpan={2}>시간</th>

                        <th colSpan={2}>일반볼합</th>
                        <th>파워볼</th>
                    </tr>
                    <tr>
                        <th>홀짝</th>
                        <th>대중소</th>
                        <th>언오버</th>
                    </tr>
                    </thead>
                    <tbody>
                    {gameResults.map((gameResult, index) => {
                        // console.log(gameResult.balls)
                        let gameDate = (gameResult.game_draw_dt).split('T')[0]
                        let gameTime = (gameResult.game_draw_dt).split('T')[1].split('+')[0]
                        if(gameTime == "00:00:00"){
                            gameDate = moment(gameDate).subtract(1, 'd').format('YYYY-MM-DD')
                            gameTime = "24:00:00"
                        }
                        if(hiddenCount > index){
                            return (
                                <tr key={index}>
                                    <td>
                                        {`${gameDate}`} &nbsp;&nbsp; <strong>{gameResult.round_daily}</strong>
                                        <br />
                                        ({gameResult.round})
                                    </td>
                                    <td>{gameTime}</td>

                                    <td>{/* 홀짝 */}
                                        <span className={`${"round " + gameResult.balls_odd_even}`}>{LABELS[gameResult.balls_odd_even]}</span>
                                    </td>
                                    <td>{/* 대중소 */}
                                        <span className={`${"round " + gameResult.balls_size}`}>{LABELS[gameResult.balls_size]}</span>
                                    </td>
                                    <td>{/* 언오버 */}
                                        <span className={`${"round " + gameResult.power_ball_under_over}`}>{LABELS[gameResult.power_ball_under_over]}</span>
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    {
                        hiddenCount < maxCount
                            ? <tr>
                                <td colSpan={40}>
                                    <div className="next-fetch-round-button" onClick={e=> setHiddenCount(hiddenCount + 20)}>더보기</div>
                                </td>
                            </tr>
                            : <></>
                    }
                    </tbody>
                </table>
            </div>

            <div className={"dh-result-table-mo"}>
                <table>
                    <thead>
                    <tr>
                        <th rowSpan={2}>회차</th>
                        <th rowSpan={2}>시간</th>

                        <th colSpan={2}>일반볼합</th>
                        <th>파워볼</th>
                    </tr>
                    <tr>
                        <th>홀짝</th>
                        <th>대중소</th>
                        <th>언오버</th>
                    </tr>
                    </thead>
                    <tbody>

                    {gameResults.map((gameResult, index) => {
                        let gameDate = (gameResult.game_draw_dt).split('T')[0]
                        let gameTime = (gameResult.game_draw_dt).split('T')[1].split('+')[0]
                        if(gameTime == "00:00:00"){
                            gameDate = moment(gameDate).subtract(1, 'd').format('YYYY-MM-DD')
                            gameTime = "24:00:00"
                        }
                        if(hiddenCount > index) {
                            return (
                                <tr key={index}>
                                    <td>
                                        {`${gameDate}`} &nbsp;&nbsp; <strong>{gameResult.round_daily}</strong>
                                        <br />
                                        ({gameResult.round})
                                    </td>
                                    <td>{gameTime}</td>

                                    <td>{/* 홀짝 */}
                                        <span className={`${"round " + gameResult.balls_odd_even}`}>{LABELS[gameResult.balls_odd_even]}</span>
                                    </td>
                                    <td>{/* 대중소 */}
                                        <span className={`${"round " + gameResult.balls_size}`}>{LABELS[gameResult.balls_size]}</span>
                                    </td>
                                    <td>{/* 언오버 */}
                                        <span className={`${"round " + gameResult.power_ball_under_over}`}>{LABELS[gameResult.power_ball_under_over]}</span>
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    {
                        hiddenCount < maxCount
                            ? <tr>
                                <td colSpan={40}>
                                    <div className="next-fetch-round-button" onClick={e=> setHiddenCount(hiddenCount + 20)}>더보기</div>
                                </td>
                            </tr>
                            : <></>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}
export function PowerballResultTable({ gameResults }: { gameResults: GameResult[] }) {
    const [hiddenCount, setHiddenCount] = useState<number>(20)
    const [maxCount, setMaxCount] = useState<number>(gameResults.length)

    return (
        <>
            <div className={"powerball-result-table"}>
                <table>
                    <thead>
                    <tr>
                        <th rowSpan={2}>회차</th>
                        <th rowSpan={2}>시간</th>

                        <th colSpan={4}>파워볼</th>
                        <th colSpan={6}>일반볼</th>
                    </tr>
                    <tr>
                        <th>결과</th>
                        <th>구간</th>
                        <th>홀짝</th>
                        <th>언오버</th>

                        <th>결과</th>
                        <th>합</th>
                        <th>구간</th>
                        <th>홀짝</th>
                        <th>언오버</th>
                        <th>대중소</th>
                    </tr>
                    </thead>
                    <tbody>
                    {gameResults.map((gameResult, index) => {
                        // console.log(gameResult.balls)
                        let gameDate = (gameResult.game_draw_dt).split('T')[0]
                        let gameTime = (gameResult.game_draw_dt).split('T')[1].split('+')[0]
                        if(gameTime == "00:00:00"){
                            gameDate = moment(gameDate).subtract(1, 'd').format('YYYY-MM-DD')
                            gameTime = "24:00:00"
                        }
                        if(hiddenCount > index){
                            return (
                                <tr key={index}>
                                    <td>
                                        {`${gameDate}`} &nbsp;&nbsp; <strong>{gameResult.round_daily}</strong>
                                        <br />
                                        ({gameResult.round})
                                    </td>
                                    <td>{gameTime}</td>

                                    <td className={"result"}>{/* 결과 */}{gameResult.power_ball}</td>
                                    <td>{/* 구간 */}
                                        <span className={`${"round section"}`}>{LABELS[gameResult.power_ball_section]}</span>
                                    </td>
                                    <td>{/* 홀짝 */}
                                        <span className={`${"round " + gameResult.power_ball_odd_even}`}>{LABELS[gameResult.power_ball_odd_even]}</span>
                                    </td>
                                    <td>{/* 언오버 */}
                                        <span className={`${"round " + gameResult.power_ball_under_over}`}>{LABELS[gameResult.power_ball_under_over]}</span>
                                    </td>
                                    <td className={"result"}>{/* 결과 */}
                                        <Flex justifyContent={"center"} gap={5}>
                                            {
                                                gameResult.balls.map((ball,index)=>(
                                                    <Flex key={index} gap={5}>
                                                        <span>{ (index > 0 ? " . " : "") }</span>
                                                        <span>{ball}</span>
                                                    </Flex>
                                                ))
                                            }
                                        </Flex>
                                    </td>
                                    <td className={"result"}>{/* 합 */}{gameResult.balls_sum}</td>
                                    <td>{/* 구간 */}
                                        <span className={`${"round section"}`}>{LABELS[gameResult.balls_section]}</span>
                                    </td>
                                    <td>{/* 홀짝 */}
                                        <span className={`${"round " + gameResult.balls_odd_even}`}>{LABELS[gameResult.balls_odd_even]}</span>
                                    </td>
                                    <td>{/* 언오버 */}
                                        <span className={`${"round " + gameResult.balls_under_over}`}>{LABELS[gameResult.balls_under_over]}</span>
                                    </td>
                                    <td>{/* 대중소 */}
                                        <span className={`${"round " + gameResult.balls_size}`}>{LABELS[gameResult.balls_size]}</span>
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    {
                        hiddenCount < maxCount
                            ? <tr>
                                  <td colSpan={40}>
                                      <div className="next-fetch-round-button" onClick={e=> setHiddenCount(hiddenCount + 20)}>더보기</div>
                                  </td>
                              </tr>
                            : <></>
                    }
                    </tbody>
                </table>
            </div>

            <div className={"powerball-result-table-mo"}>
                <table>
                    <thead>
                    <tr>
                        <th rowSpan={2}>회차</th>
                        <th colSpan={3}>파워볼</th>
                        <th colSpan={3}>일반볼</th>

                        <th rowSpan={2}>추첨결과</th>
                        <th rowSpan={2}>파워볼구간</th>
                        <th rowSpan={2}>일반볼구간</th>
                        <th rowSpan={2}>일반볼대중소</th>
                    </tr>
                    <tr>
                        <th>결과</th>
                        <th>홀짝</th>
                        <th>언오</th>

                        <th>합</th>
                        <th>홀짝</th>
                        <th>언오</th>
                    </tr>
                    </thead>
                    <tbody>

                    {gameResults.map((gameResult, index) => {
                        let gameDate = (gameResult.game_draw_dt).split('T')[0]
                        let gameTime = (gameResult.game_draw_dt).split('T')[1].split('+')[0]
                        if(gameTime == "00:00:00"){
                            gameDate = moment(gameDate).subtract(1, 'd').format('YYYY-MM-DD')
                            gameTime = "24:00:00"
                        }
                        if(hiddenCount > index){
                            return (
                                <tr key={index}>
                                    <td>
                                        {`${ (gameDate).substring(5) }`} &nbsp; <span>{gameResult.round_daily}</span>
                                    </td>
                                    <td className={"result"}>{/* 결과 */}{gameResult.power_ball}</td>
                                    <td>{/* 홀짝 */}
                                        <span className={`${"round " + gameResult.power_ball_odd_even}`}>{LABELS[gameResult.power_ball_odd_even]}</span>
                                    </td>
                                    <td>{/* 언오버 */}
                                        <span className={`${"round " + gameResult.power_ball_under_over}`}>{LABELS[gameResult.power_ball_under_over]}</span>
                                    </td>
                                    <td className={"result"}>{/* 합 */}{gameResult.balls_sum}</td>
                                    <td>{/* 홀짝 */}
                                        <span className={`${"round " + gameResult.balls_odd_even}`}>{LABELS[gameResult.balls_odd_even]}</span>
                                    </td>
                                    <td>{/* 언오버 */}
                                        <span className={`${"round " + gameResult.balls_under_over}`}>{LABELS[gameResult.balls_under_over]}</span>
                                    </td>
                                    <td className={"result"}>{/* 결과 */}
                                        <Flex justifyContent={"center"}>
                                            {
                                                gameResult.balls.map((ball,index)=>(
                                                    <Flex key={index}>
                                                        <span>{ (index > 0 ? "." : "") }</span>
                                                        <span>{ball}</span>
                                                    </Flex>
                                                ))
                                            }
                                        </Flex>
                                    </td>
                                    <td>{/* 파워볼구간 */}
                                        <span className={`${"round section"}`}>{LABELS[gameResult.power_ball_section]}</span>
                                    </td>
                                    <td>{/* 구간 */}
                                        <span className={`${"round section"}`}>{LABELS[gameResult.balls_section]}</span>
                                    </td>
                                    <td>{/* 대중소 */}
                                        <span className={`${"round " + gameResult.balls_size}`}>{LABELS[gameResult.balls_size]}</span>
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    {
                        hiddenCount < maxCount
                            ? <tr>
                                <td colSpan={40}>
                                    <div className="next-fetch-round-button" onClick={e=> setHiddenCount(hiddenCount + 20)}>더보기</div>
                                </td>
                            </tr>
                            : <></>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}
export function LadderResultTable({ gameResults }: { gameResults: GameResult[] }) {
    const [hiddenCount, setHiddenCount] = useState<number>(20)
    const [maxCount, setMaxCount] = useState<number>(gameResults.length)

    const LadderResults = addLadderResult(gameResults)
    // console.log(LadderResults)
    return (
        <>
            <div className={"ladder-result-table"}>
                <table>
                    <thead>
                    <tr>
                        <th style={{width: "20%"}}>날짜/회차</th>
                        <th style={{width: "20%"}}>시간</th>
                        <th>시작</th>
                        <th>줄수</th>
                        <th>결과</th>
                    </tr>
                    </thead>
                    <tbody>
                    {LadderResults.map((gameResult, index) => {
                        // console.log(gameResult.balls)
                        let gameDate = (gameResult.game_draw_dt).split('T')[0]
                        let gameTime = (gameResult.game_draw_dt).split('T')[1].split('+')[0]
                        if(gameTime == "00:00:00"){
                            gameDate = moment(gameDate).subtract(1, 'd').format('YYYY-MM-DD')
                            gameTime = "24:00:00"
                        }
                        if(hiddenCount > index){
                            return (
                                <tr key={index}>
                                    <td>
                                        {`${gameDate}`} &nbsp;&nbsp; <strong>{gameResult.round_daily}</strong>
                                        <br />
                                        ({gameResult.round})
                                    </td>
                                    <td>{gameTime}</td>
                                    <td>
                                    <span className={`round ${gameResult.LEFT_RIGHT}`}>
                                        {LADDER_LABELS[gameResult.LEFT_RIGHT]}
                                    </span>
                                    </td>
                                    <td>
                                    <span className={`round ${gameResult.LINE}`}>
                                        {LADDER_LABELS[gameResult.LINE]}
                                    </span>
                                    </td>
                                    <td>
                                    <span className={`round ${gameResult.ODD_EVEN}`}>
                                        {LADDER_LABELS[gameResult.ODD_EVEN]}
                                    </span>
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    {
                        hiddenCount < maxCount
                            ? <tr>
                                <td colSpan={40}>
                                    <div className="next-fetch-round-button" onClick={e=> setHiddenCount(hiddenCount + 20)}>더보기</div>
                                </td>
                            </tr>
                            : <></>
                    }
                    </tbody>
                </table>
            </div>

            <div className={"ladder-result-table-mo"}>
                <table>
                    <thead>
                    <tr>
                        <th>날짜/회차</th>
                        <th>시간</th>
                        <th>시작</th>
                        <th>줄수</th>
                        <th>결과</th>
                    </tr>
                    </thead>
                    <tbody>

                    {LadderResults.map((gameResult, index) => {
                        // console.log(gameResult.balls)
                        let gameDate = (gameResult.game_draw_dt).split('T')[0]
                        let gameTime = (gameResult.game_draw_dt).split('T')[1].split('+')[0]
                        if(gameTime == "00:00:00"){
                            gameDate = moment(gameDate).subtract(1, 'd').format('YYYY-MM-DD')
                            gameTime = "24:00:00"
                        }
                        if(hiddenCount > index){
                            return (
                                <tr key={index}>
                                    <td>
                                        {`${gameDate}`} &nbsp;&nbsp; <strong>{gameResult.round_daily}</strong>
                                        <br />
                                        ({gameResult.round})
                                    </td>
                                    <td>{gameTime}</td>
                                    <td>
                                    <span className={`round ${gameResult.LEFT_RIGHT}`}>
                                        {LADDER_LABELS[gameResult.LEFT_RIGHT]}
                                    </span>
                                    </td>
                                    <td>
                                    <span className={`round ${gameResult.LINE}`}>
                                        {LADDER_LABELS[gameResult.LINE]}
                                    </span>
                                    </td>
                                    <td>
                                    <span className={`round ${gameResult.ODD_EVEN}`}>
                                        {LADDER_LABELS[gameResult.ODD_EVEN]}
                                    </span>
                                    </td>
                                </tr>
                            )
                        }
                    })}
                    {
                        hiddenCount < maxCount
                            ? <tr>
                                <td colSpan={40}>
                                    <div className="next-fetch-round-button" onClick={e=> setHiddenCount(hiddenCount + 20)}>더보기</div>
                                </td>
                            </tr>
                            : <></>
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}

// 탭 언더 라인 이동
function TabUnderBar(props: { left: number }) {
    const [left, setLeft] = useState(6)
    useEffect(() => {
        setLeft(props.left)
    })
    return (
        <span className="over-select-tab" style={{ left: left }}></span>
    )
}
