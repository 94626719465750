import { RouteObject } from "react-router-dom";
import Layout from "./_layout"
import Home from "./_page"

const policyRoute: RouteObject = {
    path: "policy",
    element: <Layout />,
    children: [
        { path: ":type", element: <Home /> }
    ]
}

export default policyRoute