import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import Icon from "../../components/icon/icon";
import UserLevelNamecard from "../../components/user/user_level_namecard";
import { RankerType } from "src/types/ranking";
import Flex from "../../components/layout/flex";
import {PickWarning} from "./header/rank_warning";
import { Nav } from "react-bootstrap";
import {minigameTabList} from "../minigame/tab";
import {MinigameType} from "../../types/minigame";

export default function PickRanking() {
    const [rankers, setRankers] = useState<RankerType[]>()
    const [game, setGame] = useState<MinigameType>('happyball')
    const { type } = useParams();

    useEffect(() => {
        fetch(`/api/gamepick/${game}/ranking/`)
            .then(res => res.json())
            .then(data => setRankers(data))
    }, [game])

    return (
        <>
            <PickWarning/>
            <div className={"category-tab"}>
                {
                    minigameTabList.map((tab, index)=> (
                        <Nav.Link key={index}
                                  className={game == tab.eventKey ? "select" : ""}
                                  onClick={e=> { setGame(tab.eventKey as MinigameType)
                        }}>{tab.text}</Nav.Link>
                    ))
                }
            </div>
            <div className={"board-table"}>
                <table className={"rank " + type as string}>
                    <thead>
                    <tr>
                        <th>순위</th>
                        <th>닉네임</th>
                        <th>계급</th>
                        <th>승률</th>
                        <th>연승</th>
                        <th>채팅방</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rankers?.map((ranker, index) => {
                            return (
                                (

                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <UserLevelNamecard user={ranker} />
                                        </td>
                                        <td>{ranker.rank}</td>
                                        <td>
                                            <Flex justifyContent={"center"} gap={2}>
                                                <span className={"--color-even"}>{`${ranker.stats.win}승`}</span>
                                                <span className={"--color-small"}>{`${ranker.stats.lose}패`}</span>
                                                <span>{`(${(ranker.stats.win / (ranker.stats.lose + ranker.stats.win) * 100).toFixed()}%)`}</span>
                                            </Flex>
                                        </td>
                                        <td>
                                            {
                                                ranker.stats.streak > 0
                                                    ? <span className={"--color-even"}>{`${ranker.stats.streak}연승`}</span>
                                                    : <span className={"--color-small"}>{`${-ranker.stats.streak}연패`}</span>
                                            }
                                        </td>
                                        <td>
                                            <Icon icon={"lo chat-bubble"} size={24} />
                                        </td>
                                    </tr>
                                )
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </>
    )
}
