import React from 'react';
import {Link} from "react-router-dom";
import {with_comma} from "../../utils/number_comma";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons";
import useUser from "../../hooks/useUser";
import UserLevelNamecard from "../../components/user/user_level_namecard";
import Flex from "../../components/layout/flex";

function User() {
    const { user, mutateUser } = useUser()
    return (
        <div id={"gl__user"}>
            {
                user?.is_authenticated
                    ? <>
                        <div className={"user"}>
                            <Link to={'/user/message-box/received/'} style={{lineHeight: 1.2}}>
                                <Flex inline={true} gap={5}>
                                    <i className={"--icon message --font-16"} style={{width: "1.2em", marginTop: "0.2rem"}}></i>
                                    <p>({user.new_message || 0})</p>
                                </Flex>
                            </Link>
                            <UserLevelNamecard user={user}/>
                            <Link to="/user/logout">로그아웃</Link>
                        </div>
                        <div className={"user-goods"}>
                            <div>
                                <i className={"--icon point-gold"}></i>
                                <Link to={"/user/history/point-history/"}>{with_comma(user.point)}</Link>
                            </div>
                            <div>
                                <i className={"--icon cash"}></i>
                                <Link to={"/user/history/cash-history/"}>{with_comma(user.cash)}</Link>
                            </div>
                            <div>
                                <i className={"--icon star-gold"}></i>
                                <Link to={"/user/history/star-history/"}>0</Link>
                            </div>
                            <div>
                                <i className={"--icon star-silver"}></i>
                                <Link to={"/user/history/star-received/"}>0</Link>
                            </div>
                        </div>
                    </>
                    : <div className={"login"}>
                        <div>
                            <FontAwesomeIcon icon={faPowerOff}/>
                            <Link to="/user/login">로그인</Link>
                        </div>
                        <Link to="/user/register">회원가입</Link>
                    </div>
            }
        </div>
    );
}

export default User;