import React from 'react';

export default function Policy_4()
{
    return (
        <>
            <p>본 웹 사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반시 정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.</p>
            <p>정보통신망 이용촉진 및 정보보호 등에 관한 법률 [일부개정 2008.6.13 법률 제 9119호]</p>

            <strong>제50조의2 (전자우편주소의 무단 수집행위 등 금지)</strong>
            <dl>
                <dt>①</dt>
                <dd>누구든지 전자우편주소의 수집을 거부하는 의가사 명시된 인터넷 홈페이지에서 자동으로 전자우편주소를 수집 하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니된다.</dd>
            </dl>
            <dl>
                <dt>②</dt>
                <dd>누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 판매·유통하여서는 아니된다.</dd>
            </dl>
            <dl>
                <dt>③</dt>
                <dd>누구든지 제1항 및 제2항의 규정에 의하여 수집·판매 및 유통이 금지된 전자우편주소임을 알고 이를 정보 전송에 이용하여서는 아니된다.</dd>
            </dl>

            <strong>제65조의2 (벌칙) 다음 각호의 1에 해당하는 자는 1천만원 이하의 벌금에 처한다.</strong>
            <dl>
                <dt>①</dt>
                <dd>제50조제4항의 규정을 위반하여 기술적 조치를 한 자</dd>
            </dl>
            <dl>
                <dt>②</dt>
                <dd>제50조제6항의 규정을 위반하여 영리목적의 광고성 정보를 전송한 자</dd>
            </dl>
            <dl>
                <dt>③</dt>
                <dd>제50조의2의 규정을 위반하여 전자우편주소를 수집ㆍ판매ㆍ유통 또는 정보전송에 이용한 자.</dd>
            </dl>
        </>
    );
}
