import { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import useUser from "src/hooks/useUser";
import api from "src/utils/api";
import JoinTemplate from "../../member/join_template";


export default function ChangeProfileImage({ show, setShow, originalImage }: { show: boolean, setShow: any, originalImage: string }) {
    const { user, mutateUser } = useUser()
    const [submitting, setSubmitting] = useState<boolean>(false);
    const handleClose = () => {
        setShow(false);
    }

    const imgRef = useRef(null);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <JoinTemplate>
                    <form onSubmit={async e => {
                        e.preventDefault();
                        setSubmitting(true);
                        const formData = new FormData(e.target as HTMLFormElement);
                        const response = await api('/api/user/change-profile-image', {
                            method: "POST",
                            body: formData,
                        })
                            .then(res => res.json())
                            .then(data => {
                                mutateUser();
                                alert('프로필 이미지 변경 완료');
                                handleClose();
                            })
                            .catch(err => alert(JSON.stringify(err)))
                            .finally(() => setSubmitting(false))
                    }}>
                        <div>
                            <strong>프로필 이미지 변경</strong>
                            <div className={"edit-image-area"}>
                                <img ref={imgRef} src={originalImage} />
                            </div>
                            <input type="file" accept="image/*" name="profile_image" onChange={e => {
                                if (e.currentTarget.files) {
                                    const file = e.currentTarget.files[0];
                                    (imgRef.current! as HTMLImageElement).src = URL.createObjectURL(file);
                                }
                            }} />
                        </div>

                        <button className={"btn btn-theme2 " + (submitting ? "--disabled" : "")} type={"submit"}>프로필 이미지 변경</button>
                    </form>
                </JoinTemplate>
            </Modal.Body>
        </Modal>
    );
}