import Layout from "./_layout"
import LoginPage from "./member/login"
import LogoutPage from "./member/logout"
import ProfilePage from "./info/profile"
import RegisterPage from "./member/register"
import ResetPasswordPage from "./info/reset-password"
import MessageLayout from "./message/message_layout"
import InventoryPage from "./info/history/inventory"
import StarHistoryPage from "./info/history/star-history"
import StarReceivedPage from "./info/history/star-received"
import PointHistoryPage from "./info/history/point-history"
import XpHistoryPage from "./info/xp-history"
import CashHistoryPage from "./info/history/cash-history"
import FriendsPage from "./info/friends"
import DeletePage from "./info/delete"
import LoginRequired from "src/components/loginRequired"
import HistoryPage from "./info/history/_page";
import ItemHistoryPage from "./info/history/item_history";
import Exp from "./info/exp/exp";
import ExpHistory from "./info/exp/exp_history";
import ExpPage from "./info/exp/_page";
import MessageWrite from "./message/message-write";
import MessageList from "./message/message-list";

const userRoute = {
    path: "user",
    children: [
        { path: "login", element: < LoginPage /> },
        { path: "logout", element: <LogoutPage /> },
        { path: "register", element: <RegisterPage /> },
        {
            path: "", element: <LoginRequired><Layout /></LoginRequired>, children: [
                { path: "profile", element: <ProfilePage /> },
                // { path: "change-password", element: <ChangePasswordPage /> }, popup으로 대체
                { path: "reset-password/:uid/:token", element: <ResetPasswordPage /> },
                { path: "delete", element: <DeletePage /> },
                { path: "xp-history", element: <XpHistoryPage /> },
                { path: "friends", element: <FriendsPage /> },
                {
                    path: "Exp", element: <ExpPage />, children: [
                        { path: "", element: <ExpHistory /> },
                        { path: "history", element: <ExpHistory /> },
                        { path: "info", element: <Exp /> },
                    ]
                },
                {
                    path: "history", element: <HistoryPage />, children: [
                        { path: "", element: <InventoryPage /> },
                        { path: "inventory", element: <InventoryPage /> },
                        { path: "item-history", element: <ItemHistoryPage /> },
                        { path: "star-history", element: <StarHistoryPage /> },
                        { path: "star-received", element: <StarReceivedPage /> },
                        { path: "point-history", element: <PointHistoryPage /> },
                        { path: "cash-history", element: <CashHistoryPage /> },
                    ]
                },
                {
                    path: "message-box", element: <MessageLayout />, children: [
                        { path: "", element: <MessageWrite />},
                        { path: "write", element: <MessageWrite />},
                        { path: "received", element: <MessageList tab="received" /> },
                        { path: "sent", element: <MessageList tab="sent" /> },
                        { path: "stored", element: <MessageList tab="stored" /> },
                    ]
                },
            ]
        },
    ],
}

export default userRoute