import React, { useState, useEffect, useRef } from "react"
import api from "src/utils/api"
import { Form, Modal } from "react-bootstrap"
import useUser from "src/hooks/useUser"
import Flex from "../../components/layout/flex";
import Btn from "../../components/form/btn";
import UserLevelNamecard from "../../components/user/user_level_namecard";
import PopupTemplate from "./popup_template";
import LayoutHeader from "../../components/layout/layout_header";
import {userInterface} from "../../components/user/interface";
import {isMobile} from "react-device-detect";
import {chat_room, create_room} from "./room_size";
import {MinigameType, MinigameTypeNames, MinigameTypes} from "../../types/minigame";

export interface RoomsApiData {
    count: number
    results: Room[]
}

export interface Room {
    id: string // UUID 형식
    name: string
    attendant_count: number
    host: userInterface
    auth_required: boolean
    joinable: boolean
    created_at: string
}


export default function PickRoomList() {
    const { user } = useUser()
    const [rooms, setRooms] = useState<Room[]>()
    const [showCreateRoomModal, setShowCreateRoomModal] = useState<boolean>(false)

    useEffect(() => {
        if (showCreateRoomModal) {
            return
        }

        fetchRoomsInfo()
        // console.log(rooms?.first())
    }, [showCreateRoomModal])

    function fetchRoomsInfo() {
        api('/api/chat/pick-rooms/')
            .then(response => response.json())
            .then((data: RoomsApiData) => setRooms(data.results))
    }

    function deleteRoom(targetRoom: Room) {
        api(`/api/chat/pick-rooms/${targetRoom.id}/`, { method: "DELETE" })
            .then(res => setRooms(rooms!.filter(room => room.id !== targetRoom.id)))
            .then(() => window.location.reload())
            .catch(err => alert(JSON.stringify(err)))
    }

    return (
        <>
            <LayoutHeader tab={"chat"}/>
            <div className="chat-room-list board-table">
                <header>
                    <strong>채팅방 리스트</strong>
                    <Flex inline gap={10}>
                        <Btn onClick={() => fetchRoomsInfo()}>새로고침</Btn>
                        {user?.is_authenticated && <Btn onClick={e => setShowCreateRoomModal(true)}>채팅방개설</Btn>}
                    </Flex>
                </header>

                {
                    isMobile ? <TableTemplateMobile rooms={rooms} deleteRoom={deleteRoom}/>
                             : <TableTemplateDesktop rooms={rooms} deleteRoom={deleteRoom}/>
                }

                {showCreateRoomModal && <CreateRoomModal show={showCreateRoomModal} setShow={setShowCreateRoomModal} />}
            </div>
        </>
    )
}
function TableTemplateMobile({rooms, deleteRoom}: {rooms: Room[] | undefined, deleteRoom: Function}){
    const {user} = useUser()
    return (
        <div className={"rooms-mo"}>
            <ul>
                {rooms?.map((room, index) => (
                    <li key={index}>
                        <strong>{index + 1}</strong>
                        <div onClick={e => {
                            if (room.joinable) {
                                window.open(
                                    `/chat-room/${room.id}/`,
                                    `/chat-room/${room.id}/`,
                                    `popup,width=${create_room.width},height=${create_room.height}`)
                            } else {
                                alert('회원만 입장이 가능합니다')
                            }
                        }}>
                            <p>{room.name}</p>
                            <UserLevelNamecard user={room.host} />
                        </div>
                        <div>
                            {user && user.uuid === room.host.uuid && <Btn type={"danger"} onClick={() => window.confirm('삭제하시겠습니까?') && deleteRoom(room)}>삭제</Btn> }
                        </div>
                        <div>
                            <p className={room.auth_required ? "anywhere" : "member"}>{room.auth_required ? "회원만" : "누구나"}</p>
                            <span>{room.attendant_count}/100</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}
function TableTemplateDesktop({rooms, deleteRoom}: {rooms: Room[] | undefined, deleteRoom: Function}){
    const {user} = useUser()
    return (
        <div className={"rooms-list-layout"}>
            <ul>
                {rooms?.map((room, index) =>
                    {
                        return(
                            <li key={room.id}
                                onClick={e => {
                                    if (room.joinable) {
                                        window.open(
                                            `/chat-room/${room.id}/`,
                                            `/chat-room/${room.id}/`,
                                            `popup,width=${chat_room.width},height=${chat_room.height}`)
                                    } else {
                                        alert('회원만 입장이 가능합니다')
                                    }
                                }}>
                                <div className={`room-rank-${index+1}`}>
                                    <span>{index+1}</span>
                                </div>
                                <div>
                                    <div className="room-profile" style={{backgroundImage: `url(${room.host.profile_image})`}}></div>
                                </div>
                                <div>
                                    <div className={"room-title"}>{room.name}</div>
                                    <div className={"room-info"}>
                                        <UserLevelNamecard user={room.host} />
                                        <div className={"auth-required-rect"}>{room.auth_required ? "회원만" : "아무나"}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="attendant-count">{room.attendant_count} / 100</div>
                                </div>
                                <div className={"room-created-at"}>
                                    {getCreateAtFormat(room.created_at)}
                                </div>
                                {
                                    user && user.uuid === room.host.uuid &&
                                        <Btn type={"danger"} className={"delete-btn"} onClick={e => {
                                            e.preventDefault()
                                            window.confirm('삭제하시겠습니까?') && deleteRoom(room)
                                        }}>삭제</Btn>
                                }
                            </li>
                        )
                    }
                )}
            </ul>
        </div>
    )
}

function getCreateAtFormat(create_at: string){
    const now = new Date;
    const at = new Date(create_at)
    const subSecond = Math.floor((now.getTime() - at.getTime()) * 0.001)

    if(subSecond < 60){
        return `${subSecond}초 전`
    }else if(subSecond < (60 * 60)){
        return `${ Math.floor(subSecond / 60) }분 전`
    }else if(subSecond < (60 * 60 * 24)){
        return `${ Math.floor(subSecond / (60 * 60)) }시간 전`
    }else{
        return `${ Math.floor(subSecond / (60 * 60 * 24)) }일 전`
    }
}

// 방 개설 모달
function CreateRoomModal({ show, setShow }: { show: boolean, setShow: any }) {
    function handleClose() {
        setShow(false)
    }

    const formRef = useRef(null)

    function createRoom() {
        if (!formRef.current) {
            return
        }

        const formData = new FormData(formRef.current)
        api('/api/chat/pick-rooms/', { method: 'POST', body: formData })
            .then(response => response.json())
            .then(response => {
                handleClose()
                window.open(
                    `/chat-room/${response.id}/`,
                    `/chat-room/${response.id}/`,
                    `popup,width=${chat_room.width},height=${chat_room.height}`)
                window.location.reload()
            })
            .catch(err => alert(err))
    }

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="create-room-modal">
            <PopupTemplate title={"채팅방 개설"} popupType={'create'}>
                <Form ref={formRef} onSubmit={e => {
                    e.preventDefault()
                    createRoom()
                }}>
                    <div className={"create-room-modal-body"} >
                        <RowUserLimit/>
                        <RowSelectGames/>
                        <RowLimitCount/>
                        <div className={"d-row"}>
                            <strong>방 제목 입력</strong>
                            <div className={"input"}>
                                <input type={"text"} name="name" required placeholder={"방 제목을 입력하세요 (최대 20자)"} maxLength={20} />
                            </div>
                        </div>
                        <footer>
                            <Btn type={"theme2"}>채팅방 개설</Btn>
                            <Btn type={"default"} onClick={e => {
                                e.preventDefault()
                                handleClose()
                            }}>취소</Btn>
                        </footer>
                    </div>
                </Form>
            </PopupTemplate>
        </Modal>
    )

}

function RowUserLimit(){
    return (
        <div className={"d-row"}>
            <strong>입장제한설정</strong>
            <div className={"radio col2"}>
                <label>
                    <input type="radio" name={"login_required"} value="off" defaultChecked={true} />
                    <a className={"btn"}>누구나</a>
                </label>
                <label>
                    <input type="radio" name={"login_required"} />
                    <a className={"btn"}>회원만</a>
                </label>
            </div>
        </div>
    )
}
function RowSelectGames(){
    const game_list: MinigameType[] = MinigameTypes

    const gameReset = (e: any)=> {
        if(e.value){

        }
    }
    return (
        <div className={"d-row"}>
            <strong>게임선택</strong>
            <div className={"radio col3"}>
                <label>
                    <select name="game_1" onChange={e=> gameReset(e.target)}>
                        <option value="">게임1</option>
                        {
                            game_list.map((game,index)=> (
                                <option key={index} value={game}>{MinigameTypeNames[game]}</option>
                            ))
                        }
                    </select>
                </label>
                <label>
                    <select name="game_3" onChange={e=> gameReset(e.target)}>
                        <option value="">게임2</option>
                        {
                            game_list.map((game,index)=> (
                                <option key={index} value={game}>{MinigameTypeNames[game]}</option>
                            ))
                        }
                    </select>
                </label>
                <label>
                    <select name="game_2" onChange={e=> gameReset(e.target)}>
                        <option value="">게임3</option>
                        {
                            game_list.map((game,index)=> (
                                <option key={index} value={game}>{MinigameTypeNames[game]}</option>
                            ))
                        }
                    </select>
                </label>
            </div>
        </div>
    )
}
function RowLimitCount(){
    const limit_count_obj = [10, 30, 50, 100, 300, 500, 1000]
    return (
        <div className="d-row">
            <strong>최대인원</strong>
            <div className={`radio col4`}>
                {
                    limit_count_obj.map((count, index)=> (
                        <label key={index} >
                            <input type="radio" name={"user_count"} defaultChecked={ index == 0 ? true : false } />
                            <a className={"btn"}>{count}명</a>
                        </label>
                    ))
                }
            </div>
        </div>
    )
}