import { RouteObject } from "react-router-dom";
import HomePage from "./home";
import React from "react";
import MinigameLayout from "./_layout";

const minigameRoute: RouteObject = {
    path: "minigame",
    element: <MinigameLayout />,
    children: [
        { path: "", element: <HomePage /> },
        { path: ":type", element: <HomePage /> },
    ]
}

export default minigameRoute