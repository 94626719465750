import { useEffect } from 'react';
import { redirect, useNavigate } from "react-router-dom";
import useUser from 'src/hooks/useUser';

export default function LogoutPage() {
    const { user, mutateUser } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        fetch('/api/user/logout')
            .then(res => mutateUser()) // FIXME User가 undefined인 상태
            .then(() => navigate("/user/login"))
    }, [])


    return (
        <>
        </>
    );
}