import React from 'react';
import {Outlet} from "react-router-dom";

function MainContainer(props:{}) {
    return (
        <main id={"main__container"}>
            <Outlet />
        </main>
    );
}

export default MainContainer;