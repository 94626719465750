import { Nav } from "react-bootstrap";
import { useContext } from "react";
import { MinigameContext } from "src/contexts/minigameProvider";
import { useNavigate } from "react-router-dom";
import {tab_interface} from "../../components/board/board_tab";
import {MinigameType} from "../../types/minigame";

export const minigameTabList: tab_interface[] = [
    { eventKey: "happyball", text: "해피545 5분", default: true },
    { eventKey: "happyball3min", text: "해피545 3분" },
    { eventKey: "happyball_ladder", text: "해피545사다리 5분" },
    { eventKey: "happyball3min_ladder", text: "해피545사다리 3분" },
    { eventKey: "eos5", text: "EOS파워볼 5분" },
    { eventKey: "eos3", text: "EOS파워볼 3분" },
    { eventKey: "pbg5", text: "PBG파워볼 5분" },
    { eventKey: "ntry_ladder", text: "엔트리 사다리" },
    { eventKey: "dh", text: "동행파워볼 5분" },
]

export default function MinigameTab() {
    // FIXME url로 들어오는 경우 game type을 맞게 설정해줘야한다.
    const { gameType, setGameType } = useContext(MinigameContext)
    const navigate = useNavigate();
    return (
        <>
        <div className="board-tab m-unset">
            {
                minigameTabList.filter((_, index) => index < 4).map((tab, index)=> (
                    <Nav.Link key={index}
                              className={gameType === tab.eventKey ? 'select' : ''}
                              onClick={e => {
                                  setGameType(tab.eventKey as MinigameType)
                                  navigate(`/minigame/${tab.eventKey}/`)
                    }}>{tab.text}</Nav.Link>
                ))
            }
        </div>
        <div className="board-tab">
            {
                minigameTabList.filter((_, index) => index > 3).map((tab, index)=> (
                    <Nav.Link key={index}
                              className={gameType === tab.eventKey ? 'select' : ''}
                              onClick={e => {
                                  setGameType(tab.eventKey as MinigameType)
                                  navigate(`/minigame/${tab.eventKey}/`)
                    }}>{tab.text}</Nav.Link>
                ))
            }
        </div>
        </>
    );
}
