import React, {useEffect} from 'react';
import PerCount from "../../per_count";
import PickBar from "../../pick_bar";
import {GameResult, VIEW_TYPE} from "../interface";
import {getGraphDataObject, getLadderGraphDataObject} from "../graph/game_state";
import {MinigameType} from "../../../../types/minigame";


export default function GameBoardStatistic({gameType, gameResults, viewType}: { gameType: MinigameType; gameResults: GameResult[]; viewType: VIEW_TYPE }) {
    return (
        <>
            {
                viewType == 'ladder'
                    ? <LadderStatistic gameType={gameType} gameResults={gameResults}/>
                    : <></>
            }
            {
                viewType == 'ball' || viewType == 'reel'
                    ? <PowerballStatistic gameResults={gameResults}/>
                    : <></>
            }
            {
                viewType == 'dh'
                    ? <DhballStatistic gameResults={gameResults}/>
                    : <></>
            }
        </>
    )
}
function LadderStatistic({gameType, gameResults}: {gameType: MinigameType; gameResults: GameResult[]}){
    const graphData = getLadderGraphDataObject(gameResults)
    const start = graphData.start
    const line  = graphData.line
    const end   = graphData.end
    // console.log(graphData)
    const PICK_data = [
        { pick_name: "출발",   o: start.percent.left,    l: start.percent.right , o_name: "좌",   l_name: "우" },
        { pick_name: "줄수",   o: line.percent.line3,    l: line.percent.line4 , o_name: "3",   l_name: "4" },
        { pick_name: "홀짝",   o: end.percent.odd,    l: end.percent.even , o_name: "홀",   l_name: "짝" }
    ]
    const PICK_win = [
        (PICK_data[0].o > PICK_data[0].l ? "odd" : "even"),
        (PICK_data[1].o > PICK_data[1].l ? "under" : "over"),
        (PICK_data[2].o > PICK_data[2].l ? "odd" : "even"),
    ]
    return (
        <div className={"board-statistic"}>
            <div className={`rotate-board ${gameType}`}>
                <div className={`inner-bg ladder`}>
                    { PICK_data.map( (items, key)=>(
                        <div className={"pick-bar"} key={key}>
                            <div className={"pick-item " + "--bg-gb-" + PICK_win[key]}>{ items.pick_name }</div>
                            <div className={"pick-count " + PICK_win[key]}>
                                <div>
                                    <span>{items.o_name}</span>
                                    <PerCount count={items.o}/>
                                </div>
                                <div>
                                    <span>{items.l_name}</span>
                                    <PerCount count={items.l}/>
                                </div>
                            </div>
                            <div className={"absolute-bar"}>
                                <div>
                                    <PickBar className={"odd-under"} count={items.o}/>
                                </div>
                                <div>
                                    <PickBar className={"even-over"} count={items.l}/>
                                </div>
                            </div>
                        </div>
                    ) ) }
                </div>
            </div>
        </div>
    )
}
function PowerballStatistic({gameResults}: {gameResults: GameResult[]}){
    const data = getGraphDataObject(gameResults)
    const powerBall  = data.powerBall
    const normalBall = data.normalBall
    const sml        = data.sml

    const PICK_data = [
        { pick_name: "파워볼 홀짝",   o: powerBall.percent.odd,    l: powerBall.percent.even , o_name: "홀",   l_name: "짝" },
        { pick_name: "파워볼 언오",   o: powerBall.percent.under,  l: powerBall.percent.over , o_name: "언더", l_name: "오버" },
        { pick_name: "일반볼 홀짝",   o: normalBall.percent.odd,   l: normalBall.percent.even, o_name: "홀",   l_name: "짝" },
        { pick_name: "일반볼 언오",   o: normalBall.percent.under, l: normalBall.percent.over, o_name: "언더", l_name: "오버" },
    ]
    const PICK_sum = [
        { "name": "대", "per": sml.percent.s },
        { "name": "중", "per": sml.percent.m },
        { "name": "소", "per": sml.percent.l }
    ]
    const PICK_win = [
        (PICK_data[0].o > PICK_data[0].l ? "odd" : "even"),
        (PICK_data[1].o > PICK_data[1].l ? "under" : "over"),
        (PICK_data[2].o > PICK_data[2].l ? "odd" : "even"),
        (PICK_data[3].o > PICK_data[3].l ? "under" : "over")
    ]
    return (
        <div className={"board-statistic"}>
            <div className={"rotate-board"}>
                <div className="inner-bg">
                    { PICK_data.map( (items, key)=>(
                        <div className={"pick-bar"} key={key}>
                            <div className={"pick-item " + "--bg-gb-" + PICK_win[key]}>{ items.pick_name }</div>
                            <div className={"pick-count " + PICK_win[key]}>
                                <div>
                                    <span>{items.o_name}</span>
                                    <PerCount count={items.o}/>
                                </div>
                                <div>
                                    <span>{items.l_name}</span>
                                    <PerCount count={items.l}/>
                                </div>
                            </div>
                            <div className={"absolute-bar"}>
                                <div>
                                    <PickBar className={"odd-under"} count={items.o}/>
                                </div>
                                <div>
                                    <PickBar className={"even-over"} count={items.l}/>
                                </div>
                            </div>
                        </div>
                    ) ) }
                    <div className={"pick-sum"}>
                        { PICK_sum.map( (items, key)=>(
                            <div key={key}>
                                <div>{items.name}</div>
                                <PerCount count={items.per}/>
                            </div>
                        ) ) }
                    </div>
                </div>
            </div>
        </div>
    );
}
function DhballStatistic({gameResults}: {gameResults: GameResult[]}){
    const data = getGraphDataObject(gameResults)
    const powerBall  = data.powerBall
    const normalBall = data.normalBall
    const sml        = data.sml

    const PICK_data = [
        { pick_name: "일반볼 홀짝",   o: normalBall.percent.odd,   l: normalBall.percent.even, o_name: "홀",   l_name: "짝" },
        { pick_name: "파워볼 언오",   o: powerBall.percent.under,  l: powerBall.percent.over , o_name: "언",   l_name: "오" },
    ]
    const PICK_sum = [
        { "name": "대", "per": sml.percent.s },
        { "name": "중", "per": sml.percent.m },
        { "name": "소", "per": sml.percent.l }
    ]
    const PICK_win = [
        (PICK_data[0].o > PICK_data[0].l ? "odd" : "even"),
        (PICK_data[1].o > PICK_data[1].l ? "under" : "over"),
    ]
    return (
        <div className={"board-statistic"}>
            <div className={"rotate-board dh"}>
                <div className="inner-bg dh">
                    { PICK_data.map( (items, key)=>(
                        <div className={"pick-bar"} key={key}>
                            <div className={"pick-item " + "--bg-gb-" + PICK_win[key]}>{ items.pick_name }</div>
                            <div className={"pick-count " + PICK_win[key]}>
                                <div>
                                    <span>{items.o_name}</span>
                                    <PerCount count={items.o}/>
                                </div>
                                <div>
                                    <span>{items.l_name}</span>
                                    <PerCount count={items.l}/>
                                </div>
                            </div>
                            <div className={"absolute-bar"}>
                                <div>
                                    <PickBar className={"odd-under"} count={items.o}/>
                                </div>
                                <div>
                                    <PickBar className={"even-over"} count={items.l}/>
                                </div>
                            </div>
                        </div>
                    ) ) }
                    <div className={"pick-sum"}>
                        { PICK_sum.map( (items, key)=>(
                            <div key={key}>
                                <div>{items.name}</div>
                                <PerCount count={items.per}/>
                            </div>
                        ) ) }
                    </div>
                </div>
            </div>
        </div>
    );
}