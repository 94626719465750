import { useEffect, useRef, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

import test_1 from "src/asset/images/dummy/default_profile.png";
import UserLevelNamecard from "src/components/user/user_level_namecard";
import useUser from "src/hooks/useUser";
import { chatRoomKind } from "src/types/chat";
import { GamePickType, GAME_KIND_LABELS, GAME_PICK_TEXT } from "src/types/gamepick";
import { PublicUserType } from "src/types/user";
import getSocket from "src/utils/socket";
import ChatFooter from "./chat_footer";
import ChatTab from "./chat_tab";
import api from "../../utils/api";
import {Room, RoomsApiData} from "../room/pickRoom_list";
import {chat_room} from "../room/room_size";

type Message = ChatMessage | InfoMessage | RestoreMessage | AlertMessage | PickMessage;

interface ChatMessage {
    type: "message";
    sender: PublicUserType;
    content: string;
}

interface InfoMessage {
    type: "info";
    content: string;
}

interface RestoreMessage {
    type: "restore";
    messages: ChatMessage[];
}

interface AlertMessage {
    type: "alert";
    content: string;
}

interface PickMessage {
    type: 'pick';
    pick: GamePickType;
}

Chat.defaultProps = {
    show: false,
};

export default function Chat({ show }: { show: boolean }) {
    const { user, isLoading: isUserLoading } = useUser();
    const [chatRoom, setChatRoom] = useState<chatRoomKind>("GAME");
    let chatRoomLabel: string
    switch (chatRoom) {
        case "FREE":
            chatRoomLabel = "자유 채널"
            break;
        case "GAME":
            chatRoomLabel = "게임 채널"
            break;
    }
    const [pickRooms, setPickRooms] = useState<Room[]>([]);
    const [messages, setMessages] = useState<(InfoMessage | ChatMessage | PickMessage)[]>([]);
    const [isOnline, setIsOnline] = useState<Boolean>(false);
    const MainChatArea = useRef<HTMLElement>(null);

    const chatSocketRef = useRef<WebSocket>();
    const chatAreaRef = useRef<HTMLUListElement>(null);

    // 채팅 소캣 연결
    function initialSocket() {
        chatSocketRef.current = getSocket(`/ws/chat/${chatRoom}/`);
        chatSocketRef.current.onopen = function (e) {
            setIsOnline(true);
            setMessages([
                {
                    type: "info",
                    content: `${chatRoomLabel}에 참여하셨습니다.`,
                },
            ]);
        };
        chatSocketRef.current.onmessage = function (e: any) {
            const receivedMessage: Message = JSON.parse(e.data);
            switch (receivedMessage.type) {
                case "message":
                    setMessages((messages) => [...messages, receivedMessage]);
                    break;
                case "restore":
                    setMessages((messages) => [...receivedMessage.messages, ...messages]);
                    break;
                case "alert":
                    alert(receivedMessage.content)
                    break;
                case "pick":
                    const message = { ...receivedMessage, pick: JSON.parse(receivedMessage.pick as any) }
                    setTimeout(()=> {
                        setMessages((messages) => [...messages, message])
                    }, 15000)
                    break;
                default:
                    alert(JSON.stringify(receivedMessage));
            }
        };
        chatSocketRef.current.onclose = function (e: any) {
            setIsOnline(false);
        };
    }

    // 채팅 소캣 초기화
    useEffect(() => {
        if (isUserLoading) {
            return;
        }
        setMessages([]);
        initialSocket();

        return () => chatSocketRef.current?.close();
    }, [chatRoom, user?.uuid, user?.is_suspended]);

    // 소캣 연결 재시도
    useEffect(() => {
        // 이미 연결이 되있거나 || 초기화가 안된 상태
        if (isOnline || chatSocketRef.current === undefined) {
            return;
        }

        const intervalId = setInterval(() => {
            // 연결 중인 경우
            if (chatSocketRef.current?.readyState === 0) {
                return;
            }

            initialSocket();
        }, 5000);
        return () => clearInterval(intervalId);
    }, [isOnline]);

    useEffect(() => {
        chatScrollEnd();
        // fetchRoomsInfo(); 채팅방 활성시
    }, [messages, show]);

    function fetchRoomsInfo() {
        api('/api/chat/pick-rooms/')
            .then(response => response.json())
            .then((data: RoomsApiData) => setPickRooms(data.results))
    }

    function chatScrollEnd() {
        if (chatAreaRef.current) {
            const scrollHeight = chatAreaRef.current.scrollHeight;
            chatAreaRef.current.scrollTo(0, scrollHeight);
        }
    }

    // 채팅 입력 처리
    function handleSend(content: string) {
        if (!content) {
            return;
        }
        // 회원만 채팅 가능
        if (user?.is_authenticated) {
            isOnline && chatSocketRef.current?.send(JSON.stringify({ content }));
        } else {
            alert("로그인 후 이용가능합니다.");
        }
    }

    const messageListItems: JSX.Element[] = []

    for (const [index, message] of messages.entries()) {
        let messageListItem
        switch (message.type) {
            case "message":
                messageListItem = (
                    <li key={index} className={"chat"}>
                        <MessageOverlayTrigger sender={message.sender} />
                        <span>:&nbsp;{message.content}</span>
                    </li>
                )
                break;
            case "info":
                messageListItem = (
                    <li key={index} className={"info"}>
                        {message.content}
                    </li>
                );
                break;
            case "pick":
                const game_label = GAME_KIND_LABELS[message.pick.kind]
                const pick_label = GAME_PICK_TEXT[message.pick.game_pick]
                messageListItem = (
                    <li key={index} className="pick">
                        <div style={{ border: "1px solid var(--gray)", borderRadius: ".4rem" }} className={"--padding-5"}>
                            <span className={"--font-9"}>▶</span> {message.pick.user.nickname} 님 <span className={"--color-blue"}>{game_label}</span> <strong className={"--color-red"}>{message.pick.user.stats.streak + 1}연승</strong> <br />
                            <span className={"--font-9"}>▶</span> {message.pick.round_daily}회차 - {pick_label}
                        </div>
                    </li>
                )
        }
        messageListItems.push(messageListItem)
    }


    return (
        <>
            <div id={"chat__layout"} className={isMobile ? "mobile" : ""}>
                <div className="super-chat-room">
                    {
                        pickRooms?.map((room, idx) => {
                            if(idx < 13){
                                return <ChatRoomRect key={idx} room={room} />;
                            }else{
                                return <></>
                            }
                        })
                    }
                </div>
                <div id="chat-layout">
                    <ChatTab chatRoom={chatRoom} setChatRoom={setChatRoom} />
                    <main id={"chat-area"} className={"game"} ref={MainChatArea}>
                        {isOnline ? (
                            <ul ref={chatAreaRef}>
                                {messageListItems}
                            </ul>
                        ) : (
                            <ul>
                                <li>연결중</li>
                            </ul>
                        )}
                    </main>
                    {isMobile ? <></> : <ChatFooter onSend={handleSend} />}
                </div>
            </div>
            {isMobile ? <ChatFooter onSend={handleSend} /> : <></>}
        </>
    );
}

function ChatRoomRect({ room }: { room: Room }) {
    return (
        <button
            key={room.id}
            className={"chat-room-items"}
            onClick={(e) => {
                if (room.joinable) {
                    window.open(`/chat-room/${room.id}/`, "_blank", `popup,width=${chat_room.width},height=${chat_room.height}`);
                } else if (room.auth_required) {
                    alert("회원만 입장이 가능합니다.");
                } else {
                    alert("입장이 불가능한 방입니다.");
                }
            }}
            style={{
                backgroundImage: `url(${room.host.profile_image ? room.host.profile_image : test_1})`,
            }}
        >
        </button>
    );
}


function MessageOverlayTrigger({ sender }: { sender: PublicUserType }) {
    return (
        <OverlayTrigger
            trigger="click"
            placement={"right-start"}
            rootClose={true}
            overlay={
                <div className={"chat-overlay"}>
                    <main>
                        <header>
                            <UserLevelNamecard user={sender} />
                        </header>
                        <ul>
                            <li>
                                <Link to="/user/message-box/write/" state={{ to: sender.nickname }} style={{ "display": "flex", "alignItems": "center" }}><i className={"--icon message"}></i>쪽지</Link>
                            </li>
                        </ul>
                    </main>
                </div>
            }
        >
            <span>
                {
                    sender.admin_level
                        ? <i className={`--rank admin-${sender.admin_level} --font-20`}></i>
                        : <i className={`--rank level-${sender.level} --font-20`}></i>
                }
                &nbsp;&nbsp;
                {sender.nickname}
            </span>
        </OverlayTrigger>
    )
}