import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { Form, Button, Spinner } from "react-bootstrap";
import api from "src/utils/api";

interface ResetFormError {
    non_field_errors: string[]
    new_password: string[]
    new_password2: string[]
}


export default function ResetPasswordPage() {
    const { uid, token } = useParams()
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState<ResetFormError>();

    useEffect(() => {
        fetch('/api/csrf-token');
    }, [])

    const [submitting, setSubmitting] = useState<boolean>(false);

    return (<>
        <Form onSubmit={async e => {
            e.preventDefault();
            setSubmitting(true);
            const formData = new FormData(e.target as HTMLFormElement);
            const response = await api(`/api/user/reset-password/${uid}/${token}`, {
                method: "POST",
                body: formData,
            })
            setSubmitting(false);
            if (response.ok) {
                navigate('/user/login');
            } else {
                const errorData: ResetFormError = await response.json()
                setFormErrors(errorData);
            }
        }}>
            {formErrors?.non_field_errors && <Form.Text style={{ color: 'red', display: 'block' }}>{formErrors.non_field_errors}</Form.Text>}
            <Form.Group className="mb-3">
                <Form.Label>새 비밀번호</Form.Label>
                <Form.Control name="new_password" type="password" placeholder="비밀번호" />
                {formErrors?.new_password && <Form.Text style={{ color: 'red', display: 'block' }}>{formErrors.new_password}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>새 비밀번호 확인</Form.Label>
                <Form.Control name="new_password2" type="password" placeholder="비밀번호 확인" />
                {formErrors?.new_password2 && <Form.Text style={{ color: 'red', display: 'block' }}>{formErrors.new_password2}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Check me out" />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={submitting}>
                {submitting
                    ? <Spinner as="span" animation="border" size="sm" role="status" />
                    : "제출"
                }
            </Button>
        </Form>
    </>);
}
