import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Modal } from "react-bootstrap";
import useUser from "src/hooks/useUser";
import api from "src/utils/api2";
import JoinTemplate from "../../member/join_template";

export default function ResetMessageModal({ show, setShow }: { show: boolean; setShow: any }) {
  const { user, mutateUser } = useUser();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <JoinTemplate>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setSubmitting(true);
              const response = await api("/api/user/reset-message", { method: "POST" })
                .then((res) => {
                  mutateUser();
                  handleClose();
                  alert("일일 쪽지 초기화 완료");
                })
                .catch(async (res: Response) => {
                  if (res.headers.get('content-type') !== 'application/json') {
                    alert("오류가 발생하였습니다.");
                    handleClose();
                  }
                  const error = await res.json()
                  if (error.detail) {
                    alert()
                  }
                })
                .finally(() => setSubmitting(false));
            }}
          >
            <div className={"form-row --mb-15"}>
              <strong>전송가능 쪽지</strong>
              <p>
                <span className={"--color-red"}>{user?.daily_info.remain_message === null ? '∞' : user?.daily_info.remain_message}</span>/{user?.daily_info.message_limit === null ? '∞' : user?.daily_info.message_limit}
              </p>
            </div>

            <button className={"btn btn-theme2 " + (submitting ? "--disabled" : "")} type={"submit"}>
              일일 쪽지 갯수 초기화
            </button>
          </form>
        </JoinTemplate>
      </Modal.Body>
    </Modal>
  );
}
