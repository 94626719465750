import React, {useEffect, useState} from 'react';
import {GameResult, LABELS, LADDER_LABELS, VIEW_TYPE} from "../interface";
import MobileResultBoard from "./mobile_result_board";
import {getLadderLine, getLadderResult} from "./view_type/Ladder";
import {MinigameType} from "../../../../types/minigame";

type LADDER_TYPE = 'LEFT' | 'RIGHT' | 'ODD' | 'EVEN';
export default function GameBoardResult({gameType, gameResults, viewType}: { gameType: MinigameType; gameResults: GameResult[]; viewType: VIEW_TYPE }) {
    const [state, setState] = useState<string>("")
    const list = gameResults
    useEffect(()=> {
        setState('--hidden')
        let openTimer = 14500
        if(viewType == 'ladder'){
            openTimer = 3500
        }else if(viewType == 'dh'){
            openTimer = 7000
        }
        setTimeout(()=> {
            setState('')
        }, openTimer)
    }, [gameResults])
    useEffect(()=> {
        setState('')
    }, [])
    return (
        <>
            <div className={`result-board right ${gameType}`}>
                <div className="rotate-board">
                    <header className={"title"}>추첨결과</header>
                    <main className={"contents"}>
                        <div className={"scroll"}>
                            <ul>
                                {
                                    list.map((obj, index)=>{
                                        if(viewType == 'ladder') {
                                            return <LadderResultListItem obj={obj} index={index} state={state} key={index}/>
                                        }else if(viewType == 'dh'){
                                            return <DhballsResultListItem obj={obj} index={index} state={state} key={index}/>
                                        }else{
                                            return <PowerballResultListItem obj={obj} index={index} state={state} key={index}/>
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </main>
                </div>
            </div>

            <MobileResultBoard list={list} state={state} viewType={viewType} />
        </>
    );
}
export function DhballsResultListItem({obj, index, state}: {obj : GameResult, index: number, state: string}){

    return (
        <li key={index} className={"items " + ( index == 0 ? state : "" )}>
            <div className="round">{obj.round_daily}회차 {obj.round}</div>
            <div className={"dh-numbers"}>
                <span className={`dh-ball ${obj.balls_odd_even}`}>{LABELS[obj.balls_odd_even]}</span>
                <span className={`dh-ball ${obj.balls_size}`}>{LABELS[obj.balls_size]}</span>
                <span className={`dh-ball ${obj.power_ball_under_over}`}>{LABELS[obj.power_ball_under_over]}</span>
            </div>
        </li>
    )
}
export function LadderResultListItem({obj, index, state}: {obj : GameResult, index: number, state: string}){
    const firstNumber = obj.balls[0]
    const ladderResult = getLadderResult(firstNumber)
    const START = ladderResult.split('_')[0]
    const END = ladderResult.split('_')[1]
    const LINE = getLadderLine(firstNumber)
    return (
        <li key={index} className={"items " + ( index == 0 ? state : "" )}>
            <div className="round">{obj.round_daily}회차 {obj.round}</div>
            <div className={"numbers"}>
                <span className={"num-" + obj.balls[0]}>{ obj.balls[0] }</span>
                <span className={"num-" + obj.balls[1]}>{ obj.balls[1] }</span>
                <span className={"num-" + obj.balls[2]}>{ obj.balls[2] }</span>
                <span className={"num-" + obj.balls[3]}>{ obj.balls[3] }</span>
                <span className={"num-" + obj.balls[4]}>{ obj.balls[4] }</span>
                <span className={"powerball"}>{ obj.power_ball }</span>
            </div>
            <div className={"ladder-ranges"}>
                <span className={`rect ${START}`}>{LADDER_LABELS[START as LADDER_TYPE]}</span>
                <span className={`rect LINE${LINE}`}>{LINE}</span>
                <span className={`rect ${END}`}>{LADDER_LABELS[END as LADDER_TYPE]}</span>
            </div>
        </li>
    )
}
export function PowerballResultListItem({obj, index, state}: {obj : GameResult, index: number, state: string}){
    return (
        <li key={index} className={"items " + ( index == 0 ? state : "" )}>
            <div className="round">{obj.round_daily}회차 {obj.round}</div>
            <div className={"numbers"}>
                <span className={"num-" + obj.balls[0]}>{ obj.balls[0] }</span>
                <span className={"num-" + obj.balls[1]}>{ obj.balls[1] }</span>
                <span className={"num-" + obj.balls[2]}>{ obj.balls[2] }</span>
                <span className={"num-" + obj.balls[3]}>{ obj.balls[3] }</span>
                <span className={"num-" + obj.balls[4]}>{ obj.balls[4] }</span>
                <span className={"powerball"}>{ obj.power_ball }</span>
            </div>
            <div className={"ranges"}>
                {/* happyball 홀짝 */}
                <span className={"rect " + obj.power_ball_odd_even}>
                                                {"P" + LABELS[obj.power_ball_odd_even]}
                                            </span>
                {/* happyball 언오 */}
                <span className={"rect " + obj.power_ball_under_over}>
                                                {"P" + LABELS[obj.power_ball_under_over]}
                                            </span>
                {/* 일반 홀짝 */}
                <span className={"rect " + obj.balls_odd_even}>
                                                {LABELS[obj.balls_odd_even]}
                                            </span>
                {/* 일반 언오 */}
                <span className={"rect " + obj.balls_under_over}>
                                                {LABELS[obj.balls_under_over]}
                                            </span>
                {/* 소, 중, 대 */}
                <span className={"rect " + obj.balls_size}>
                                                {LABELS[obj.balls_size]}
                                            </span>
            </div>
        </li>
    )
}