import React from 'react';
import { Outlet } from 'react-router-dom';
import CategoryTab from "../../../components/category_tab";

const tab_list = [
    { eventKey: "write", text: "쪽지보내기" },
    { eventKey: "received", text: "받은쪽지함" },
    { eventKey: "sent", text: "보낸쪽지함" },
    { eventKey: "stored", text: "쪽지보관함" },
]
export default function MessagePage() {
    return (
        <>
            <CategoryTab tab_list={tab_list} />
            <div className="board-message">
                <Outlet />
            </div>
        </>
    );
}
