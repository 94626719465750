import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Modal } from "react-bootstrap";
import useUser from "src/hooks/useUser";
import api from "src/utils/api";
import JoinTemplate from "../../member/join_template";

interface ErrorType {
  nickname?: string[];
}

export default function ChangeNicknameModal({ show, setShow }: { show: boolean; setShow: any }) {
  const { user, mutateUser } = useUser();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType>();

  const handleClose = () => {
    setShow(false);
  };

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setSubmitting(true);
    const formData = new FormData(e.target as HTMLFormElement);
    api("/api/user/change-nickname", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        mutateUser();
        alert("닉네임 변경 완료");
        setError(undefined);
        handleClose();
      })
      .catch((err) => {
        if (err["detail"]) {
          alert(err["detail"]);
          handleClose();
        } else {
          setError(err);
        }
      })
      .finally(() => setSubmitting(false));
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <JoinTemplate>
          <form onSubmit={handleSubmit}>
            <div className={"form-row --mb-15"}>
              <strong>변경 닉네임</strong>
              <input type="text" name={"nickname"} placeholder={"변경할 닉네임 입력 (최대 8글자)"} maxLength={10} />
              {error?.nickname && error.nickname.map(nickname_error => <Form.Text style={{ color: "red", display: "block" }}>{nickname_error}</Form.Text>)}
            </div>
            <button className={"btn btn-theme2 " + (submitting ? "--disabled" : "")} type={"submit"}>
              닉네임 변경
            </button>
          </form>
        </JoinTemplate>
      </Modal.Body>
    </Modal>
  );
}
