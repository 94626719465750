import React, {useEffect} from 'react';
import MarketHome from "./home";
import BoardHead from "../../components/board/board_head";
import MarketWarning from "./header/market_warning";
import BoardTab, {tab_interface} from "../../components/board/board_tab";
import LayoutHeader from "../../components/layout/layout_header";
import {isMobile} from "react-device-detect";

export const board_tab_list: tab_interface[] = [
    {eventKey:"item", text: "아이템"},
    {eventKey:"charge", text: "캐시충전", accessError: true},
]
function MarketLayout(props: {}) {
    return (
        <>
            <LayoutHeader tab={"market"}/>
            <BoardHead/>
            <BoardTab page={"market"} tab_list={board_tab_list}/>
            {
                isMobile ? <></> : <MarketWarning/>
            }
            <MarketHome />
        </>
    );
}

export default MarketLayout;