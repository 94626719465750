import React from 'react';
import BoardBox from "../../apps/community/header/box";
import BoardTab from "./board_tab";

function BoardHead(props:{ className: string }) {
    return (
        <>
            {/*<div id={"community-board-head"} className={props.className}>*/}
            {/*    <BoardBox type={"analysis"}/>*/}
            {/*    <BoardBox type={"typeBoard"}/>*/}
            {/*</div>*/}
        </>
    );
}

BoardHead.defaultProps = {
    className: ""
}

export default BoardHead;