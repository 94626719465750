import React, {useEffect, useRef, useState} from 'react';
import {
    board_state
    , gap
    , delay } from "../game_board"
import SqsEffect from "../../Effect";
import useInterval from "../../../../../hooks/useInterval";
import AudioPlayer from "../audio_player";
import stop from "../../../asset/sound/Stop.mp3";
import {GameResult} from "../../interface";

type shuffle_ball_type = "stay" | "normal"
export default function DhBalls({board_state, gameResults}: {board_state: board_state; gameResults: GameResult}) {
    const [ball_down_state, setBall_down_state] = useState<boolean>(false)
    const [ball_type, setBall_type] = useState<shuffle_ball_type>("stay")

    const ball_result = [gameResults.balls_odd_even, gameResults.balls_size, gameResults.power_ball_under_over]

    useEffect(()=>{
        if( board_state == "stay" ) {
            setBall_down_state(false)
            setBall_type('stay')
        } else if( board_state == "ready" ){
            setBall_down_state(false)
            setBall_type('normal')
        } else if( board_state == 'spin' ){
            setBall_down_state(true)
        } else if ( board_state == 'end' ){
            setBall_down_state(false)
        }
    }, [board_state])
    return (
        <>
            <div className={`board-logo dh`}></div>
            <div className={"happyball-ball-board-position"}>
                <div className={"tong back"}></div>
                {
                    ball_type == "stay"
                        ? <ShuffleStay/>
                        : <ShuffleDraw ball_type={ball_type}/>
                }
                <BallDown board_state={board_state} dhResults={ball_result} />
                <div className={"tong ball"}></div>
                <div className={"tong front"}></div>
            </div>
        </>
    );
}

function ShuffleStay(){
    const shuffle_effect_name_stay = "shuffle_stay"
    const shuffle_effect_stay = new SqsEffect(shuffle_effect_name_stay)

    useInterval(()=>{
        const stay_st = {
            imageSize : [3400, 2100],
            frame     : 60,
            vector    : [10, 6],
            speed     : 1800,
            element   : ".tong.shuffle.stay",
            loop      : false,
        }
        shuffle_effect_stay.set(stay_st)
    }, 2500)
    return (
        <div className={`tong shuffle stay`}></div>
    )
}
function ShuffleDraw({ball_type}: {ball_type: shuffle_ball_type}){
    const shuffle_effect_name_draw = "shuffle_draw"
    const shuffle_effect_draw = new SqsEffect(shuffle_effect_name_draw)

    useEffect(()=> {
        if(ball_type == 'normal'){
            const draw_st = {
                imageSize : [1700, 1400],
                frame     : 20,
                vector    : [5, 4],
                speed     : 750,
                element   : ".tong.shuffle.normal",
                loop      : true,
            }
            shuffle_effect_draw.set(draw_st)
        }else{
            return
        }
    }, [])
    return (
        <div className={`tong shuffle ${ball_type}`}></div>
    )
}

type DH_RESULTS_TYPE = "" | "ODD" | "EVEN" | "SMALL" | "MEDIUM" | "LARGE" | "UNDER" | "OVER"
function BallDown({board_state, dhResults}: {board_state: board_state; dhResults: DH_RESULTS_TYPE[]}){
    const [ball_down, setBall_down] = useState<DH_RESULTS_TYPE>("")

    useEffect(()=> {
        if(board_state != 'spin' || !dhResults) {
            setBall_down("")
            return
        }
        let count = 1
        let down_delay = 0
        dhResults.forEach((result, index)=> {
            down_delay = (gap * (index + 1)) + (delay * (index + 1))
            // console.log(down_delay)
            setTimeout(()=>{
                setBall_down(result)
            }, down_delay)

            count++;
        })
    }, [dhResults])
    return (
        <>
            <BallDownEffect dhResult={ball_down}/>
            <BallDownResult dhResult={ball_down}/>
        </>
    )
}
function BallDownResult({dhResult}: {dhResult: DH_RESULTS_TYPE}){
    const [resultBall, setResultBall] = useState<DH_RESULTS_TYPE[]>([])
    useEffect(()=>{
        if(!dhResult){
            return
        }

        if(resultBall.length < 3){
            resultBall.push(dhResult)
        }
        if(resultBall.length > 2){
            setTimeout(()=> {
                setResultBall([])
            }, 4700)
        }
    }, [dhResult])
    return (
        <div className={"ball-result dh"}>
            {
                resultBall.map((cl, index)=>(
                    <div key={index}>
                        <AudioPlayer src={stop} play={true}/>
                        <figure className={`result-ball ${cl}`}/>
                    </div>
                ))
            }
        </div>
    )
}
function BallDownEffect({dhResult}: {dhResult: DH_RESULTS_TYPE}){
    const BallDownRef = useRef<HTMLDivElement>(null)

    useEffect(()=>{
        if(!BallDownRef.current){
            return
        }
        const ballDown = BallDownRef.current
        ballDown.style.display = "block"
        ballDown.className = `ball-down ${dhResult}`
        const ballDown_effect_name = ballDown.className
        const ballDown_effect = new SqsEffect(ballDown_effect_name)
        const st = {
            imageSize : [1200, 1830],
            frame     : 24,
            vector    : [4, 6],
            speed     : 900,
            element   : ".ball-down",
            loop      : false,
        }
        ballDown_effect.set(st, ()=>{
            setTimeout(()=>{
                ballDown.style.display = "none"
                ballDown_effect.reset(".ball-down")
            }, 800)
        })
    }, [dhResult])
    return (
        <div className={`ball-down ${dhResult}`} ref={BallDownRef}></div>
    )
}
