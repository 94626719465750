import { createContext, useContext, useEffect, useRef } from "react";
import useUser from "src/hooks/useUser";
import getSocket from "src/utils/socket";
import { ToastContext } from "./toastProvider";

interface MessageType { }

interface UserSocketContextType {
  userSocketRef: React.MutableRefObject<WebSocket | null>,
  sendMessage: (message: MessageType) => void;
}

const UserSocketContext = createContext<UserSocketContextType>({
  userSocketRef: { current: null } as React.MutableRefObject<WebSocket | null>,
  sendMessage: (message) => { },
});

function UserSocketProvider({ children }: { children: any }) {
  const { user, mutateUser } = useUser();
  const userSocketRef = useRef<WebSocket | null>(null);
  const { toastify } = useContext(ToastContext);

  useEffect(() => {
    if (!user?.uuid) {
      return;
    }
    const userSocket = initialSocket();
    userSocketRef.current = userSocket;

    return () => {
      // 정상적으로 소켓이 다시 연결되는 경우
      userSocket.onclose = () => { };
      userSocket.close();
    };
  }, [user?.uuid]);

  function initialSocket() {
    const userSocket = getSocket("/ws/user");
    userSocket.onopen = handleOpen;
    userSocket.onmessage = handleMessage;
    userSocket.onclose = handleClose;
    return userSocket;
  }

  function handleOpen() { }

  // 비정상적으로 소켓이 종료된 경우 다시 연결 시도한다.
  function handleClose(this: any) {
    // 모종의 이유로 좀비 소켓이 발생한 경우 대비
    if (this !== userSocketRef.current) {
      return;
    }

    setTimeout(() => {
      const userSocket = initialSocket();
      userSocketRef.current = userSocket;
    }, 3000);
  }

  function handleMessage(e: MessageEvent) {
    const message = JSON.parse(e.data);
    switch (message.type) {
      case "attend":
        toastify({ title: "채팅방 입장", content: `${message.attendant.nickname}님이 채팅방에 참여하였습니다.`, notifiedAt: new Date(), room: message.room });
        break;
      // 새로운 쪽지를 받은 경우
      case "dm":
        mutateUser();
        break;
    }
  }

  function sendMessage(newMessage: MessageType) {
    userSocketRef.current?.send(JSON.stringify(newMessage));
  }

  return <UserSocketContext.Provider value={{ userSocketRef, sendMessage }}>{children}</UserSocketContext.Provider>;
}

export { UserSocketContext, UserSocketProvider };
