import React from 'react';

export default function Policy_3()
{
    return (
        <>
            <strong>모두의픽 청소년 보호정책</strong>
            <p>모두의픽은 각종 청소년 유해정보로부터 청소년을 보호하고자 관련법률에 따라 19세미만의 청소년들이 유해정보에 접근할 수 없도록 청소년 보호정책을 마련하여 시행하고 있습니다. 또한 모두의픽은 청소년의 건전한 성장을 저해하는 음란·불법 등의 유해정보와 비윤리적·반사회적 행위에 대해서는 엄격하게 제재하기 위하여 다음과 같이 활동하고 있습니다.</p>
            <br/>
            <br/>
            <dl>
                <dt>1.</dt>
                <dd> 유해정보에 대한 청소년접근제한 및 관리조치
                    <p>모두의픽은 청소년이 아무런 제한장치 없이 유해정보에 노출되지 않도록 청소년유해매체물에 대해서 인증장치를 마련해 적용하고 있으며, 유해정보가 노출되지 않도록 사전예방 차원의 조치를 취합니다.</p>
                </dd>
            </dl>
            <br/>
            <dl>
                <dt>2.</dt>
                <dd> 전체 이용자들의 인식제고를 통한 청소년 보호
                    <p>모두의픽은 서비스이용약관 등을 통하여 불건전한 행위를 할 경우 이용제한 또는 민·형사상의 책임을 받을 수 있음을 고지하고 있으며, 신종 유해정보가 발생했을 경우 공지사항을 통하여 이를 신속히 전파함으로써 청소년 및 전체 이용자를 보호하고 있습니다.</p>
                </dd>
            </dl>
            <br/>
            <dl>
                <dt>3.</dt>
                <dd> 유해정보로 인한 피해상담 및 고충처리
                    <p>모두의픽은 청소년 유해정보로 인한 피해상담 및 고충처리를 위한 전문인력을 배치하여 그 피해가 확산되지 않도록 노력하고 있습니다. 하단에 명시한 청소년보호 책임자의 소속과 연락처를 참고하여 메일을 통해 피해상담 및 고충처리를 요청할 수 있습니다.</p>
                </dd>
            </dl>
        </>
    );
}
