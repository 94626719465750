import React, {useState} from 'react';
import Flex from "../layout/flex";

function Switch({isOn}: {isOn: boolean;}) {
    const [on, seton] = useState<boolean>(isOn)
    function set(){
        // seton(!on)
        alert('준비중 입니다.')
    }
    return (
        <div className={"switch-area" + (on ? " on" : "")} onClick={set}>
            <span className={"switch-btn"}></span>
        </div>
    );
}
Switch.defaultProps = {
    isOn: false
}

export default Switch;