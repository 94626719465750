import { Outlet } from 'react-router-dom';
import BoardHead from "../../components/board/board_head";
import React from "react";
import BoardTab, {tab_interface} from "../../components/board/board_tab";
import LayoutHeader from "../../components/layout/layout_header";

export const board_tab_list: tab_interface[] = [
    {eventKey:"all", text: "모아보기"},
    {eventKey:"free", text: "자유 게시판"},
    {eventKey:"humor", text: "유머 게시판"},
    {eventKey:"picture", text: "사진 게시판"},
    // {eventKey:"video", text: "비디오 게시판"},
    {eventKey:"guide", text: "이용 가이드"},
    // {eventKey:"sports", text: "스포츠분석 게시판"},
]
export default function CommunityLayout() {
  return (
      <>
          <LayoutHeader tab={"community"}/>
          <BoardHead/>
          <BoardTab page={"community"} tab_list={board_tab_list}/>
          <Outlet />
      </>
  )
}