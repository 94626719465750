import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Spinner, Modal } from "react-bootstrap";
import useUser from "src/hooks/useUser";
import api from "src/utils/api";
import JoinTemplate from "../../member/join_template";

export default function ChangeIntroModal({ show, setShow }: { show: boolean; setShow: any }) {
  const { user, mutateUser } = useUser();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    fetch("/api/csrf-token");
  }, []);
  useEffect(() => {}, [submitting]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <JoinTemplate>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              setSubmitting(true);
              const formData = new FormData(e.target as HTMLFormElement);
              const response = await api("/api/user/change-intro", {
                method: "POST",
                body: formData,
              })
                .then((res) => {
                  mutateUser();
                  handleClose();
                  alert("오늘의 한 마디 변경완료");
                })
                .catch((err) => {
                  if (err["아이템"]) {
                    alert(err["아이템"]);
                    handleClose();
                  }
                })
                .finally(() => setSubmitting(false));
            }}
          >
            <div className={"form-row --mb-15"}>
              <strong>오늘의 한 마디</strong>
              <input type="text" name={"intro"} placeholder={"변경 할 한 마디"} />
            </div>

            <button className={"btn btn-theme2 " + (submitting ? "--disabled" : "")} type={"submit"}>
              오늘의 한 마디 변경
            </button>
          </form>
        </JoinTemplate>
      </Modal.Body>
    </Modal>
  );
}
